
body {
    margin:0;
    padding:0;
    font-family: 'Roboto', sans-serif;
}
.title-go-left {
    text-align: left!important;
    line-height: 1.5;
}
.add-profile {
    height: 40px;
    width: 40px;
    float: right;
}


input[type=password] {
    -webkit-text-security: disc;
}

[v-cloak] {
    display: none;
}
.search-input {
    background-color: white;
    margin: 10px;
    padding: 10px;
}
.driver-home-btn{
    display: inline!important;
    text-align: left;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
    padding: 13px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px!important;
    background-color: white!important;
    font-size: 18px;
    height: auto;
    border-radius: 7px;
}
.btn__content {
    font-family: 'Roboto', sans-serif;
    -webkit-box-align: left;
        -ms-flex-align: left;
            align-items: left;
    border-radius: inherit;
    color: inherit;
    display: inline!important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: left;
    -webkit-transition: .3s cubic-bezier(.25,.8,.5,1);
    transition: .3s cubic-bezier(.25,.8,.5,1);
    white-space: nowrap;
    padding: 0!important;
    margin: 0;
}
.button-text-overflow {
    width: 295px;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    padding: 0;
    margin: 0;
}









































































[v-cloak] {
  display: none;
}
.stepper:not(.stepper--vertical) .stepper__label{
  display:inherit!important;
}
.palletDisplay{
  width: 33%;
  text-align: center;
}
.image-container{
    position: relative;
    top: 0px;
}
img.rounded {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}


[v-cloak] {
    display: none;
}
.stepper:not(.stepper--vertical) .stepper__label{
    display:inherit!important;
}


.palletDisplay{
  width: 30%;
  text-align: center;
}
.image-container{
    position: relative;
    top: 0px;
}


.palletDisplay{
  width: 33%;
  text-align: center;
}
.image-container{
    position: relative;
    top: 0px;
}
.card .description {
    position: absolute;
    letter-spacing: 0;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 13px;
    padding: 0 10px;
    color: #7b7b7b;
    background: #f3f3f3;
    border-radius: 99px;
    top: 0;
    left: 0;
}

[v-cloak] {
  display: none;
}

.text-overflow {
    width: 295px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
















































































































































































































































































































































































































































































































































































































































































































































































































.black {
  background-color: #000 !important;
  border-color: #000 !important;
}
.black--text {
  color: #000 !important;
}
.black--text input,
.black--text textarea {
  caret-color: #000 !important;
}
.black--after:after {
  background: #000 !important;
}
.white {
  background-color: #fff !important;
  border-color: #fff !important;
}
.white--text {
  color: #fff !important;
}
.white--text input,
.white--text textarea {
  caret-color: #fff !important;
}
.white--after:after {
  background: #fff !important;
}
.transparent {
  background-color: transparent !important;
  border-color: transparent !important;
}
.transparent--text {
  color: transparent !important;
}
.transparent--text input,
.transparent--text textarea {
  caret-color: transparent !important;
}
.transparent--after:after {
  background: transparent !important;
}
.red {
  background-color: #f44336 !important;
  border-color: #f44336 !important;
}
.red--text {
  color: #f44336 !important;
}
.red--text input,
.red--text textarea {
  caret-color: #f44336 !important;
}
.red--after:after {
  background: #f44336 !important;
}
.red.lighten-5 {
  background-color: #ffebee !important;
  border-color: #ffebee !important;
}
.red.lighten-5--after:after {
  background-color: #ffebee !important;
}
.red--text.text--lighten-5 {
  color: #ffebee !important;
}
.red--text.text--lighten-5 input,
.red--text.text--lighten-5 textarea {
  caret-color: #ffebee !important;
}
.red.lighten-4 {
  background-color: #ffcdd2 !important;
  border-color: #ffcdd2 !important;
}
.red.lighten-4--after:after {
  background-color: #ffcdd2 !important;
}
.red--text.text--lighten-4 {
  color: #ffcdd2 !important;
}
.red--text.text--lighten-4 input,
.red--text.text--lighten-4 textarea {
  caret-color: #ffcdd2 !important;
}
.red.lighten-3 {
  background-color: #ef9a9a !important;
  border-color: #ef9a9a !important;
}
.red.lighten-3--after:after {
  background-color: #ef9a9a !important;
}
.red--text.text--lighten-3 {
  color: #ef9a9a !important;
}
.red--text.text--lighten-3 input,
.red--text.text--lighten-3 textarea {
  caret-color: #ef9a9a !important;
}
.red.lighten-2 {
  background-color: #e57373 !important;
  border-color: #e57373 !important;
}
.red.lighten-2--after:after {
  background-color: #e57373 !important;
}
.red--text.text--lighten-2 {
  color: #e57373 !important;
}
.red--text.text--lighten-2 input,
.red--text.text--lighten-2 textarea {
  caret-color: #e57373 !important;
}
.red.lighten-1 {
  background-color: #ef5350 !important;
  border-color: #ef5350 !important;
}
.red.lighten-1--after:after {
  background-color: #ef5350 !important;
}
.red--text.text--lighten-1 {
  color: #ef5350 !important;
}
.red--text.text--lighten-1 input,
.red--text.text--lighten-1 textarea {
  caret-color: #ef5350 !important;
}
.red.darken-1 {
  background-color: #e53935 !important;
  border-color: #e53935 !important;
}
.red.darken-1--after:after {
  background-color: #e53935 !important;
}
.red--text.text--darken-1 {
  color: #e53935 !important;
}
.red--text.text--darken-1 input,
.red--text.text--darken-1 textarea {
  caret-color: #e53935 !important;
}
.red.darken-2 {
  background-color: #d32f2f !important;
  border-color: #d32f2f !important;
}
.red.darken-2--after:after {
  background-color: #d32f2f !important;
}
.red--text.text--darken-2 {
  color: #d32f2f !important;
}
.red--text.text--darken-2 input,
.red--text.text--darken-2 textarea {
  caret-color: #d32f2f !important;
}
.red.darken-3 {
  background-color: #c62828 !important;
  border-color: #c62828 !important;
}
.red.darken-3--after:after {
  background-color: #c62828 !important;
}
.red--text.text--darken-3 {
  color: #c62828 !important;
}
.red--text.text--darken-3 input,
.red--text.text--darken-3 textarea {
  caret-color: #c62828 !important;
}
.red.darken-4 {
  background-color: #b71c1c !important;
  border-color: #b71c1c !important;
}
.red.darken-4--after:after {
  background-color: #b71c1c !important;
}
.red--text.text--darken-4 {
  color: #b71c1c !important;
}
.red--text.text--darken-4 input,
.red--text.text--darken-4 textarea {
  caret-color: #b71c1c !important;
}
.red.accent-1 {
  background-color: #ff8a80 !important;
  border-color: #ff8a80 !important;
}
.red.accent-1--after:after {
  background-color: #ff8a80 !important;
}
.red--text.text--accent-1 {
  color: #ff8a80 !important;
}
.red--text.text--accent-1 input,
.red--text.text--accent-1 textarea {
  caret-color: #ff8a80 !important;
}
.red.accent-2 {
  background-color: #ff5252 !important;
  border-color: #ff5252 !important;
}
.red.accent-2--after:after {
  background-color: #ff5252 !important;
}
.red--text.text--accent-2 {
  color: #ff5252 !important;
}
.red--text.text--accent-2 input,
.red--text.text--accent-2 textarea {
  caret-color: #ff5252 !important;
}
.red.accent-3 {
  background-color: #ff1744 !important;
  border-color: #ff1744 !important;
}
.red.accent-3--after:after {
  background-color: #ff1744 !important;
}
.red--text.text--accent-3 {
  color: #ff1744 !important;
}
.red--text.text--accent-3 input,
.red--text.text--accent-3 textarea {
  caret-color: #ff1744 !important;
}
.red.accent-4 {
  background-color: #d50000 !important;
  border-color: #d50000 !important;
}
.red.accent-4--after:after {
  background-color: #d50000 !important;
}
.red--text.text--accent-4 {
  color: #d50000 !important;
}
.red--text.text--accent-4 input,
.red--text.text--accent-4 textarea {
  caret-color: #d50000 !important;
}
.pink {
  background-color: #e91e63 !important;
  border-color: #e91e63 !important;
}
.pink--text {
  color: #e91e63 !important;
}
.pink--text input,
.pink--text textarea {
  caret-color: #e91e63 !important;
}
.pink--after:after {
  background: #e91e63 !important;
}
.pink.lighten-5 {
  background-color: #fce4ec !important;
  border-color: #fce4ec !important;
}
.pink.lighten-5--after:after {
  background-color: #fce4ec !important;
}
.pink--text.text--lighten-5 {
  color: #fce4ec !important;
}
.pink--text.text--lighten-5 input,
.pink--text.text--lighten-5 textarea {
  caret-color: #fce4ec !important;
}
.pink.lighten-4 {
  background-color: #f8bbd0 !important;
  border-color: #f8bbd0 !important;
}
.pink.lighten-4--after:after {
  background-color: #f8bbd0 !important;
}
.pink--text.text--lighten-4 {
  color: #f8bbd0 !important;
}
.pink--text.text--lighten-4 input,
.pink--text.text--lighten-4 textarea {
  caret-color: #f8bbd0 !important;
}
.pink.lighten-3 {
  background-color: #f48fb1 !important;
  border-color: #f48fb1 !important;
}
.pink.lighten-3--after:after {
  background-color: #f48fb1 !important;
}
.pink--text.text--lighten-3 {
  color: #f48fb1 !important;
}
.pink--text.text--lighten-3 input,
.pink--text.text--lighten-3 textarea {
  caret-color: #f48fb1 !important;
}
.pink.lighten-2 {
  background-color: #f06292 !important;
  border-color: #f06292 !important;
}
.pink.lighten-2--after:after {
  background-color: #f06292 !important;
}
.pink--text.text--lighten-2 {
  color: #f06292 !important;
}
.pink--text.text--lighten-2 input,
.pink--text.text--lighten-2 textarea {
  caret-color: #f06292 !important;
}
.pink.lighten-1 {
  background-color: #ec407a !important;
  border-color: #ec407a !important;
}
.pink.lighten-1--after:after {
  background-color: #ec407a !important;
}
.pink--text.text--lighten-1 {
  color: #ec407a !important;
}
.pink--text.text--lighten-1 input,
.pink--text.text--lighten-1 textarea {
  caret-color: #ec407a !important;
}
.pink.darken-1 {
  background-color: #d81b60 !important;
  border-color: #d81b60 !important;
}
.pink.darken-1--after:after {
  background-color: #d81b60 !important;
}
.pink--text.text--darken-1 {
  color: #d81b60 !important;
}
.pink--text.text--darken-1 input,
.pink--text.text--darken-1 textarea {
  caret-color: #d81b60 !important;
}
.pink.darken-2 {
  background-color: #c2185b !important;
  border-color: #c2185b !important;
}
.pink.darken-2--after:after {
  background-color: #c2185b !important;
}
.pink--text.text--darken-2 {
  color: #c2185b !important;
}
.pink--text.text--darken-2 input,
.pink--text.text--darken-2 textarea {
  caret-color: #c2185b !important;
}
.pink.darken-3 {
  background-color: #ad1457 !important;
  border-color: #ad1457 !important;
}
.pink.darken-3--after:after {
  background-color: #ad1457 !important;
}
.pink--text.text--darken-3 {
  color: #ad1457 !important;
}
.pink--text.text--darken-3 input,
.pink--text.text--darken-3 textarea {
  caret-color: #ad1457 !important;
}
.pink.darken-4 {
  background-color: #880e4f !important;
  border-color: #880e4f !important;
}
.pink.darken-4--after:after {
  background-color: #880e4f !important;
}
.pink--text.text--darken-4 {
  color: #880e4f !important;
}
.pink--text.text--darken-4 input,
.pink--text.text--darken-4 textarea {
  caret-color: #880e4f !important;
}
.pink.accent-1 {
  background-color: #ff80ab !important;
  border-color: #ff80ab !important;
}
.pink.accent-1--after:after {
  background-color: #ff80ab !important;
}
.pink--text.text--accent-1 {
  color: #ff80ab !important;
}
.pink--text.text--accent-1 input,
.pink--text.text--accent-1 textarea {
  caret-color: #ff80ab !important;
}
.pink.accent-2 {
  background-color: #ff4081 !important;
  border-color: #ff4081 !important;
}
.pink.accent-2--after:after {
  background-color: #ff4081 !important;
}
.pink--text.text--accent-2 {
  color: #ff4081 !important;
}
.pink--text.text--accent-2 input,
.pink--text.text--accent-2 textarea {
  caret-color: #ff4081 !important;
}
.pink.accent-3 {
  background-color: #f50057 !important;
  border-color: #f50057 !important;
}
.pink.accent-3--after:after {
  background-color: #f50057 !important;
}
.pink--text.text--accent-3 {
  color: #f50057 !important;
}
.pink--text.text--accent-3 input,
.pink--text.text--accent-3 textarea {
  caret-color: #f50057 !important;
}
.pink.accent-4 {
  background-color: #c51162 !important;
  border-color: #c51162 !important;
}
.pink.accent-4--after:after {
  background-color: #c51162 !important;
}
.pink--text.text--accent-4 {
  color: #c51162 !important;
}
.pink--text.text--accent-4 input,
.pink--text.text--accent-4 textarea {
  caret-color: #c51162 !important;
}
.purple {
  background-color: #9c27b0 !important;
  border-color: #9c27b0 !important;
}
.purple--text {
  color: #9c27b0 !important;
}
.purple--text input,
.purple--text textarea {
  caret-color: #9c27b0 !important;
}
.purple--after:after {
  background: #9c27b0 !important;
}
.purple.lighten-5 {
  background-color: #f3e5f5 !important;
  border-color: #f3e5f5 !important;
}
.purple.lighten-5--after:after {
  background-color: #f3e5f5 !important;
}
.purple--text.text--lighten-5 {
  color: #f3e5f5 !important;
}
.purple--text.text--lighten-5 input,
.purple--text.text--lighten-5 textarea {
  caret-color: #f3e5f5 !important;
}
.purple.lighten-4 {
  background-color: #e1bee7 !important;
  border-color: #e1bee7 !important;
}
.purple.lighten-4--after:after {
  background-color: #e1bee7 !important;
}
.purple--text.text--lighten-4 {
  color: #e1bee7 !important;
}
.purple--text.text--lighten-4 input,
.purple--text.text--lighten-4 textarea {
  caret-color: #e1bee7 !important;
}
.purple.lighten-3 {
  background-color: #ce93d8 !important;
  border-color: #ce93d8 !important;
}
.purple.lighten-3--after:after {
  background-color: #ce93d8 !important;
}
.purple--text.text--lighten-3 {
  color: #ce93d8 !important;
}
.purple--text.text--lighten-3 input,
.purple--text.text--lighten-3 textarea {
  caret-color: #ce93d8 !important;
}
.purple.lighten-2 {
  background-color: #ba68c8 !important;
  border-color: #ba68c8 !important;
}
.purple.lighten-2--after:after {
  background-color: #ba68c8 !important;
}
.purple--text.text--lighten-2 {
  color: #ba68c8 !important;
}
.purple--text.text--lighten-2 input,
.purple--text.text--lighten-2 textarea {
  caret-color: #ba68c8 !important;
}
.purple.lighten-1 {
  background-color: #ab47bc !important;
  border-color: #ab47bc !important;
}
.purple.lighten-1--after:after {
  background-color: #ab47bc !important;
}
.purple--text.text--lighten-1 {
  color: #ab47bc !important;
}
.purple--text.text--lighten-1 input,
.purple--text.text--lighten-1 textarea {
  caret-color: #ab47bc !important;
}
.purple.darken-1 {
  background-color: #8e24aa !important;
  border-color: #8e24aa !important;
}
.purple.darken-1--after:after {
  background-color: #8e24aa !important;
}
.purple--text.text--darken-1 {
  color: #8e24aa !important;
}
.purple--text.text--darken-1 input,
.purple--text.text--darken-1 textarea {
  caret-color: #8e24aa !important;
}
.purple.darken-2 {
  background-color: #7b1fa2 !important;
  border-color: #7b1fa2 !important;
}
.purple.darken-2--after:after {
  background-color: #7b1fa2 !important;
}
.purple--text.text--darken-2 {
  color: #7b1fa2 !important;
}
.purple--text.text--darken-2 input,
.purple--text.text--darken-2 textarea {
  caret-color: #7b1fa2 !important;
}
.purple.darken-3 {
  background-color: #6a1b9a !important;
  border-color: #6a1b9a !important;
}
.purple.darken-3--after:after {
  background-color: #6a1b9a !important;
}
.purple--text.text--darken-3 {
  color: #6a1b9a !important;
}
.purple--text.text--darken-3 input,
.purple--text.text--darken-3 textarea {
  caret-color: #6a1b9a !important;
}
.purple.darken-4 {
  background-color: #4a148c !important;
  border-color: #4a148c !important;
}
.purple.darken-4--after:after {
  background-color: #4a148c !important;
}
.purple--text.text--darken-4 {
  color: #4a148c !important;
}
.purple--text.text--darken-4 input,
.purple--text.text--darken-4 textarea {
  caret-color: #4a148c !important;
}
.purple.accent-1 {
  background-color: #ea80fc !important;
  border-color: #ea80fc !important;
}
.purple.accent-1--after:after {
  background-color: #ea80fc !important;
}
.purple--text.text--accent-1 {
  color: #ea80fc !important;
}
.purple--text.text--accent-1 input,
.purple--text.text--accent-1 textarea {
  caret-color: #ea80fc !important;
}
.purple.accent-2 {
  background-color: #e040fb !important;
  border-color: #e040fb !important;
}
.purple.accent-2--after:after {
  background-color: #e040fb !important;
}
.purple--text.text--accent-2 {
  color: #e040fb !important;
}
.purple--text.text--accent-2 input,
.purple--text.text--accent-2 textarea {
  caret-color: #e040fb !important;
}
.purple.accent-3 {
  background-color: #d500f9 !important;
  border-color: #d500f9 !important;
}
.purple.accent-3--after:after {
  background-color: #d500f9 !important;
}
.purple--text.text--accent-3 {
  color: #d500f9 !important;
}
.purple--text.text--accent-3 input,
.purple--text.text--accent-3 textarea {
  caret-color: #d500f9 !important;
}
.purple.accent-4 {
  background-color: #a0f !important;
  border-color: #a0f !important;
}
.purple.accent-4--after:after {
  background-color: #a0f !important;
}
.purple--text.text--accent-4 {
  color: #a0f !important;
}
.purple--text.text--accent-4 input,
.purple--text.text--accent-4 textarea {
  caret-color: #a0f !important;
}
.deep-purple {
  background-color: #673ab7 !important;
  border-color: #673ab7 !important;
}
.deep-purple--text {
  color: #673ab7 !important;
}
.deep-purple--text input,
.deep-purple--text textarea {
  caret-color: #673ab7 !important;
}
.deep-purple--after:after {
  background: #673ab7 !important;
}
.deep-purple.lighten-5 {
  background-color: #ede7f6 !important;
  border-color: #ede7f6 !important;
}
.deep-purple.lighten-5--after:after {
  background-color: #ede7f6 !important;
}
.deep-purple--text.text--lighten-5 {
  color: #ede7f6 !important;
}
.deep-purple--text.text--lighten-5 input,
.deep-purple--text.text--lighten-5 textarea {
  caret-color: #ede7f6 !important;
}
.deep-purple.lighten-4 {
  background-color: #d1c4e9 !important;
  border-color: #d1c4e9 !important;
}
.deep-purple.lighten-4--after:after {
  background-color: #d1c4e9 !important;
}
.deep-purple--text.text--lighten-4 {
  color: #d1c4e9 !important;
}
.deep-purple--text.text--lighten-4 input,
.deep-purple--text.text--lighten-4 textarea {
  caret-color: #d1c4e9 !important;
}
.deep-purple.lighten-3 {
  background-color: #b39ddb !important;
  border-color: #b39ddb !important;
}
.deep-purple.lighten-3--after:after {
  background-color: #b39ddb !important;
}
.deep-purple--text.text--lighten-3 {
  color: #b39ddb !important;
}
.deep-purple--text.text--lighten-3 input,
.deep-purple--text.text--lighten-3 textarea {
  caret-color: #b39ddb !important;
}
.deep-purple.lighten-2 {
  background-color: #9575cd !important;
  border-color: #9575cd !important;
}
.deep-purple.lighten-2--after:after {
  background-color: #9575cd !important;
}
.deep-purple--text.text--lighten-2 {
  color: #9575cd !important;
}
.deep-purple--text.text--lighten-2 input,
.deep-purple--text.text--lighten-2 textarea {
  caret-color: #9575cd !important;
}
.deep-purple.lighten-1 {
  background-color: #7e57c2 !important;
  border-color: #7e57c2 !important;
}
.deep-purple.lighten-1--after:after {
  background-color: #7e57c2 !important;
}
.deep-purple--text.text--lighten-1 {
  color: #7e57c2 !important;
}
.deep-purple--text.text--lighten-1 input,
.deep-purple--text.text--lighten-1 textarea {
  caret-color: #7e57c2 !important;
}
.deep-purple.darken-1 {
  background-color: #5e35b1 !important;
  border-color: #5e35b1 !important;
}
.deep-purple.darken-1--after:after {
  background-color: #5e35b1 !important;
}
.deep-purple--text.text--darken-1 {
  color: #5e35b1 !important;
}
.deep-purple--text.text--darken-1 input,
.deep-purple--text.text--darken-1 textarea {
  caret-color: #5e35b1 !important;
}
.deep-purple.darken-2 {
  background-color: #512da8 !important;
  border-color: #512da8 !important;
}
.deep-purple.darken-2--after:after {
  background-color: #512da8 !important;
}
.deep-purple--text.text--darken-2 {
  color: #512da8 !important;
}
.deep-purple--text.text--darken-2 input,
.deep-purple--text.text--darken-2 textarea {
  caret-color: #512da8 !important;
}
.deep-purple.darken-3 {
  background-color: #4527a0 !important;
  border-color: #4527a0 !important;
}
.deep-purple.darken-3--after:after {
  background-color: #4527a0 !important;
}
.deep-purple--text.text--darken-3 {
  color: #4527a0 !important;
}
.deep-purple--text.text--darken-3 input,
.deep-purple--text.text--darken-3 textarea {
  caret-color: #4527a0 !important;
}
.deep-purple.darken-4 {
  background-color: #311b92 !important;
  border-color: #311b92 !important;
}
.deep-purple.darken-4--after:after {
  background-color: #311b92 !important;
}
.deep-purple--text.text--darken-4 {
  color: #311b92 !important;
}
.deep-purple--text.text--darken-4 input,
.deep-purple--text.text--darken-4 textarea {
  caret-color: #311b92 !important;
}
.deep-purple.accent-1 {
  background-color: #b388ff !important;
  border-color: #b388ff !important;
}
.deep-purple.accent-1--after:after {
  background-color: #b388ff !important;
}
.deep-purple--text.text--accent-1 {
  color: #b388ff !important;
}
.deep-purple--text.text--accent-1 input,
.deep-purple--text.text--accent-1 textarea {
  caret-color: #b388ff !important;
}
.deep-purple.accent-2 {
  background-color: #7c4dff !important;
  border-color: #7c4dff !important;
}
.deep-purple.accent-2--after:after {
  background-color: #7c4dff !important;
}
.deep-purple--text.text--accent-2 {
  color: #7c4dff !important;
}
.deep-purple--text.text--accent-2 input,
.deep-purple--text.text--accent-2 textarea {
  caret-color: #7c4dff !important;
}
.deep-purple.accent-3 {
  background-color: #651fff !important;
  border-color: #651fff !important;
}
.deep-purple.accent-3--after:after {
  background-color: #651fff !important;
}
.deep-purple--text.text--accent-3 {
  color: #651fff !important;
}
.deep-purple--text.text--accent-3 input,
.deep-purple--text.text--accent-3 textarea {
  caret-color: #651fff !important;
}
.deep-purple.accent-4 {
  background-color: #6200ea !important;
  border-color: #6200ea !important;
}
.deep-purple.accent-4--after:after {
  background-color: #6200ea !important;
}
.deep-purple--text.text--accent-4 {
  color: #6200ea !important;
}
.deep-purple--text.text--accent-4 input,
.deep-purple--text.text--accent-4 textarea {
  caret-color: #6200ea !important;
}
.indigo {
  background-color: #3f51b5 !important;
  border-color: #3f51b5 !important;
}
.indigo--text {
  color: #3f51b5 !important;
}
.indigo--text input,
.indigo--text textarea {
  caret-color: #3f51b5 !important;
}
.indigo--after:after {
  background: #3f51b5 !important;
}
.indigo.lighten-5 {
  background-color: #e8eaf6 !important;
  border-color: #e8eaf6 !important;
}
.indigo.lighten-5--after:after {
  background-color: #e8eaf6 !important;
}
.indigo--text.text--lighten-5 {
  color: #e8eaf6 !important;
}
.indigo--text.text--lighten-5 input,
.indigo--text.text--lighten-5 textarea {
  caret-color: #e8eaf6 !important;
}
.indigo.lighten-4 {
  background-color: #c5cae9 !important;
  border-color: #c5cae9 !important;
}
.indigo.lighten-4--after:after {
  background-color: #c5cae9 !important;
}
.indigo--text.text--lighten-4 {
  color: #c5cae9 !important;
}
.indigo--text.text--lighten-4 input,
.indigo--text.text--lighten-4 textarea {
  caret-color: #c5cae9 !important;
}
.indigo.lighten-3 {
  background-color: #9fa8da !important;
  border-color: #9fa8da !important;
}
.indigo.lighten-3--after:after {
  background-color: #9fa8da !important;
}
.indigo--text.text--lighten-3 {
  color: #9fa8da !important;
}
.indigo--text.text--lighten-3 input,
.indigo--text.text--lighten-3 textarea {
  caret-color: #9fa8da !important;
}
.indigo.lighten-2 {
  background-color: #7986cb !important;
  border-color: #7986cb !important;
}
.indigo.lighten-2--after:after {
  background-color: #7986cb !important;
}
.indigo--text.text--lighten-2 {
  color: #7986cb !important;
}
.indigo--text.text--lighten-2 input,
.indigo--text.text--lighten-2 textarea {
  caret-color: #7986cb !important;
}
.indigo.lighten-1 {
  background-color: #5c6bc0 !important;
  border-color: #5c6bc0 !important;
}
.indigo.lighten-1--after:after {
  background-color: #5c6bc0 !important;
}
.indigo--text.text--lighten-1 {
  color: #5c6bc0 !important;
}
.indigo--text.text--lighten-1 input,
.indigo--text.text--lighten-1 textarea {
  caret-color: #5c6bc0 !important;
}
.indigo.darken-1 {
  background-color: #3949ab !important;
  border-color: #3949ab !important;
}
.indigo.darken-1--after:after {
  background-color: #3949ab !important;
}
.indigo--text.text--darken-1 {
  color: #3949ab !important;
}
.indigo--text.text--darken-1 input,
.indigo--text.text--darken-1 textarea {
  caret-color: #3949ab !important;
}
.indigo.darken-2 {
  background-color: #303f9f !important;
  border-color: #303f9f !important;
}
.indigo.darken-2--after:after {
  background-color: #303f9f !important;
}
.indigo--text.text--darken-2 {
  color: #303f9f !important;
}
.indigo--text.text--darken-2 input,
.indigo--text.text--darken-2 textarea {
  caret-color: #303f9f !important;
}
.indigo.darken-3 {
  background-color: #283593 !important;
  border-color: #283593 !important;
}
.indigo.darken-3--after:after {
  background-color: #283593 !important;
}
.indigo--text.text--darken-3 {
  color: #283593 !important;
}
.indigo--text.text--darken-3 input,
.indigo--text.text--darken-3 textarea {
  caret-color: #283593 !important;
}
.indigo.darken-4 {
  background-color: #1a237e !important;
  border-color: #1a237e !important;
}
.indigo.darken-4--after:after {
  background-color: #1a237e !important;
}
.indigo--text.text--darken-4 {
  color: #1a237e !important;
}
.indigo--text.text--darken-4 input,
.indigo--text.text--darken-4 textarea {
  caret-color: #1a237e !important;
}
.indigo.accent-1 {
  background-color: #8c9eff !important;
  border-color: #8c9eff !important;
}
.indigo.accent-1--after:after {
  background-color: #8c9eff !important;
}
.indigo--text.text--accent-1 {
  color: #8c9eff !important;
}
.indigo--text.text--accent-1 input,
.indigo--text.text--accent-1 textarea {
  caret-color: #8c9eff !important;
}
.indigo.accent-2 {
  background-color: #536dfe !important;
  border-color: #536dfe !important;
}
.indigo.accent-2--after:after {
  background-color: #536dfe !important;
}
.indigo--text.text--accent-2 {
  color: #536dfe !important;
}
.indigo--text.text--accent-2 input,
.indigo--text.text--accent-2 textarea {
  caret-color: #536dfe !important;
}
.indigo.accent-3 {
  background-color: #3d5afe !important;
  border-color: #3d5afe !important;
}
.indigo.accent-3--after:after {
  background-color: #3d5afe !important;
}
.indigo--text.text--accent-3 {
  color: #3d5afe !important;
}
.indigo--text.text--accent-3 input,
.indigo--text.text--accent-3 textarea {
  caret-color: #3d5afe !important;
}
.indigo.accent-4 {
  background-color: #304ffe !important;
  border-color: #304ffe !important;
}
.indigo.accent-4--after:after {
  background-color: #304ffe !important;
}
.indigo--text.text--accent-4 {
  color: #304ffe !important;
}
.indigo--text.text--accent-4 input,
.indigo--text.text--accent-4 textarea {
  caret-color: #304ffe !important;
}
.blue {
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}
.blue--text {
  color: #2196f3 !important;
}
.blue--text input,
.blue--text textarea {
  caret-color: #2196f3 !important;
}
.blue--after:after {
  background: #2196f3 !important;
}
.blue.lighten-5 {
  background-color: #e3f2fd !important;
  border-color: #e3f2fd !important;
}
.blue.lighten-5--after:after {
  background-color: #e3f2fd !important;
}
.blue--text.text--lighten-5 {
  color: #e3f2fd !important;
}
.blue--text.text--lighten-5 input,
.blue--text.text--lighten-5 textarea {
  caret-color: #e3f2fd !important;
}
.blue.lighten-4 {
  background-color: #bbdefb !important;
  border-color: #bbdefb !important;
}
.blue.lighten-4--after:after {
  background-color: #bbdefb !important;
}
.blue--text.text--lighten-4 {
  color: #bbdefb !important;
}
.blue--text.text--lighten-4 input,
.blue--text.text--lighten-4 textarea {
  caret-color: #bbdefb !important;
}
.blue.lighten-3 {
  background-color: #90caf9 !important;
  border-color: #90caf9 !important;
}
.blue.lighten-3--after:after {
  background-color: #90caf9 !important;
}
.blue--text.text--lighten-3 {
  color: #90caf9 !important;
}
.blue--text.text--lighten-3 input,
.blue--text.text--lighten-3 textarea {
  caret-color: #90caf9 !important;
}
.blue.lighten-2 {
  background-color: #64b5f6 !important;
  border-color: #64b5f6 !important;
}
.blue.lighten-2--after:after {
  background-color: #64b5f6 !important;
}
.blue--text.text--lighten-2 {
  color: #64b5f6 !important;
}
.blue--text.text--lighten-2 input,
.blue--text.text--lighten-2 textarea {
  caret-color: #64b5f6 !important;
}
.blue.lighten-1 {
  background-color: #42a5f5 !important;
  border-color: #42a5f5 !important;
}
.blue.lighten-1--after:after {
  background-color: #42a5f5 !important;
}
.blue--text.text--lighten-1 {
  color: #42a5f5 !important;
}
.blue--text.text--lighten-1 input,
.blue--text.text--lighten-1 textarea {
  caret-color: #42a5f5 !important;
}
.blue.darken-1 {
  background-color: #1e88e5 !important;
  border-color: #1e88e5 !important;
}
.blue.darken-1--after:after {
  background-color: #1e88e5 !important;
}
.blue--text.text--darken-1 {
  color: #1e88e5 !important;
}
.blue--text.text--darken-1 input,
.blue--text.text--darken-1 textarea {
  caret-color: #1e88e5 !important;
}
.blue.darken-2 {
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
}
.blue.darken-2--after:after {
  background-color: #1976d2 !important;
}
.blue--text.text--darken-2 {
  color: #1976d2 !important;
}
.blue--text.text--darken-2 input,
.blue--text.text--darken-2 textarea {
  caret-color: #1976d2 !important;
}
.blue.darken-3 {
  background-color: #1565c0 !important;
  border-color: #1565c0 !important;
}
.blue.darken-3--after:after {
  background-color: #1565c0 !important;
}
.blue--text.text--darken-3 {
  color: #1565c0 !important;
}
.blue--text.text--darken-3 input,
.blue--text.text--darken-3 textarea {
  caret-color: #1565c0 !important;
}
.blue.darken-4 {
  background-color: #0d47a1 !important;
  border-color: #0d47a1 !important;
}
.blue.darken-4--after:after {
  background-color: #0d47a1 !important;
}
.blue--text.text--darken-4 {
  color: #0d47a1 !important;
}
.blue--text.text--darken-4 input,
.blue--text.text--darken-4 textarea {
  caret-color: #0d47a1 !important;
}
.blue.accent-1 {
  background-color: #82b1ff !important;
  border-color: #82b1ff !important;
}
.blue.accent-1--after:after {
  background-color: #82b1ff !important;
}
.blue--text.text--accent-1 {
  color: #82b1ff !important;
}
.blue--text.text--accent-1 input,
.blue--text.text--accent-1 textarea {
  caret-color: #82b1ff !important;
}
.blue.accent-2 {
  background-color: #448aff !important;
  border-color: #448aff !important;
}
.blue.accent-2--after:after {
  background-color: #448aff !important;
}
.blue--text.text--accent-2 {
  color: #448aff !important;
}
.blue--text.text--accent-2 input,
.blue--text.text--accent-2 textarea {
  caret-color: #448aff !important;
}
.blue.accent-3 {
  background-color: #2979ff !important;
  border-color: #2979ff !important;
}
.blue.accent-3--after:after {
  background-color: #2979ff !important;
}
.blue--text.text--accent-3 {
  color: #2979ff !important;
}
.blue--text.text--accent-3 input,
.blue--text.text--accent-3 textarea {
  caret-color: #2979ff !important;
}
.blue.accent-4 {
  background-color: #2962ff !important;
  border-color: #2962ff !important;
}
.blue.accent-4--after:after {
  background-color: #2962ff !important;
}
.blue--text.text--accent-4 {
  color: #2962ff !important;
}
.blue--text.text--accent-4 input,
.blue--text.text--accent-4 textarea {
  caret-color: #2962ff !important;
}
.light-blue {
  background-color: #03a9f4 !important;
  border-color: #03a9f4 !important;
}
.light-blue--text {
  color: #03a9f4 !important;
}
.light-blue--text input,
.light-blue--text textarea {
  caret-color: #03a9f4 !important;
}
.light-blue--after:after {
  background: #03a9f4 !important;
}
.light-blue.lighten-5 {
  background-color: #e1f5fe !important;
  border-color: #e1f5fe !important;
}
.light-blue.lighten-5--after:after {
  background-color: #e1f5fe !important;
}
.light-blue--text.text--lighten-5 {
  color: #e1f5fe !important;
}
.light-blue--text.text--lighten-5 input,
.light-blue--text.text--lighten-5 textarea {
  caret-color: #e1f5fe !important;
}
.light-blue.lighten-4 {
  background-color: #b3e5fc !important;
  border-color: #b3e5fc !important;
}
.light-blue.lighten-4--after:after {
  background-color: #b3e5fc !important;
}
.light-blue--text.text--lighten-4 {
  color: #b3e5fc !important;
}
.light-blue--text.text--lighten-4 input,
.light-blue--text.text--lighten-4 textarea {
  caret-color: #b3e5fc !important;
}
.light-blue.lighten-3 {
  background-color: #81d4fa !important;
  border-color: #81d4fa !important;
}
.light-blue.lighten-3--after:after {
  background-color: #81d4fa !important;
}
.light-blue--text.text--lighten-3 {
  color: #81d4fa !important;
}
.light-blue--text.text--lighten-3 input,
.light-blue--text.text--lighten-3 textarea {
  caret-color: #81d4fa !important;
}
.light-blue.lighten-2 {
  background-color: #4fc3f7 !important;
  border-color: #4fc3f7 !important;
}
.light-blue.lighten-2--after:after {
  background-color: #4fc3f7 !important;
}
.light-blue--text.text--lighten-2 {
  color: #4fc3f7 !important;
}
.light-blue--text.text--lighten-2 input,
.light-blue--text.text--lighten-2 textarea {
  caret-color: #4fc3f7 !important;
}
.light-blue.lighten-1 {
  background-color: #29b6f6 !important;
  border-color: #29b6f6 !important;
}
.light-blue.lighten-1--after:after {
  background-color: #29b6f6 !important;
}
.light-blue--text.text--lighten-1 {
  color: #29b6f6 !important;
}
.light-blue--text.text--lighten-1 input,
.light-blue--text.text--lighten-1 textarea {
  caret-color: #29b6f6 !important;
}
.light-blue.darken-1 {
  background-color: #039be5 !important;
  border-color: #039be5 !important;
}
.light-blue.darken-1--after:after {
  background-color: #039be5 !important;
}
.light-blue--text.text--darken-1 {
  color: #039be5 !important;
}
.light-blue--text.text--darken-1 input,
.light-blue--text.text--darken-1 textarea {
  caret-color: #039be5 !important;
}
.light-blue.darken-2 {
  background-color: #0288d1 !important;
  border-color: #0288d1 !important;
}
.light-blue.darken-2--after:after {
  background-color: #0288d1 !important;
}
.light-blue--text.text--darken-2 {
  color: #0288d1 !important;
}
.light-blue--text.text--darken-2 input,
.light-blue--text.text--darken-2 textarea {
  caret-color: #0288d1 !important;
}
.light-blue.darken-3 {
  background-color: #0277bd !important;
  border-color: #0277bd !important;
}
.light-blue.darken-3--after:after {
  background-color: #0277bd !important;
}
.light-blue--text.text--darken-3 {
  color: #0277bd !important;
}
.light-blue--text.text--darken-3 input,
.light-blue--text.text--darken-3 textarea {
  caret-color: #0277bd !important;
}
.light-blue.darken-4 {
  background-color: #01579b !important;
  border-color: #01579b !important;
}
.light-blue.darken-4--after:after {
  background-color: #01579b !important;
}
.light-blue--text.text--darken-4 {
  color: #01579b !important;
}
.light-blue--text.text--darken-4 input,
.light-blue--text.text--darken-4 textarea {
  caret-color: #01579b !important;
}
.light-blue.accent-1 {
  background-color: #80d8ff !important;
  border-color: #80d8ff !important;
}
.light-blue.accent-1--after:after {
  background-color: #80d8ff !important;
}
.light-blue--text.text--accent-1 {
  color: #80d8ff !important;
}
.light-blue--text.text--accent-1 input,
.light-blue--text.text--accent-1 textarea {
  caret-color: #80d8ff !important;
}
.light-blue.accent-2 {
  background-color: #40c4ff !important;
  border-color: #40c4ff !important;
}
.light-blue.accent-2--after:after {
  background-color: #40c4ff !important;
}
.light-blue--text.text--accent-2 {
  color: #40c4ff !important;
}
.light-blue--text.text--accent-2 input,
.light-blue--text.text--accent-2 textarea {
  caret-color: #40c4ff !important;
}
.light-blue.accent-3 {
  background-color: #00b0ff !important;
  border-color: #00b0ff !important;
}
.light-blue.accent-3--after:after {
  background-color: #00b0ff !important;
}
.light-blue--text.text--accent-3 {
  color: #00b0ff !important;
}
.light-blue--text.text--accent-3 input,
.light-blue--text.text--accent-3 textarea {
  caret-color: #00b0ff !important;
}
.light-blue.accent-4 {
  background-color: #0091ea !important;
  border-color: #0091ea !important;
}
.light-blue.accent-4--after:after {
  background-color: #0091ea !important;
}
.light-blue--text.text--accent-4 {
  color: #0091ea !important;
}
.light-blue--text.text--accent-4 input,
.light-blue--text.text--accent-4 textarea {
  caret-color: #0091ea !important;
}
.cyan {
  background-color: #00bcd4 !important;
  border-color: #00bcd4 !important;
}
.cyan--text {
  color: #00bcd4 !important;
}
.cyan--text input,
.cyan--text textarea {
  caret-color: #00bcd4 !important;
}
.cyan--after:after {
  background: #00bcd4 !important;
}
.cyan.lighten-5 {
  background-color: #e0f7fa !important;
  border-color: #e0f7fa !important;
}
.cyan.lighten-5--after:after {
  background-color: #e0f7fa !important;
}
.cyan--text.text--lighten-5 {
  color: #e0f7fa !important;
}
.cyan--text.text--lighten-5 input,
.cyan--text.text--lighten-5 textarea {
  caret-color: #e0f7fa !important;
}
.cyan.lighten-4 {
  background-color: #b2ebf2 !important;
  border-color: #b2ebf2 !important;
}
.cyan.lighten-4--after:after {
  background-color: #b2ebf2 !important;
}
.cyan--text.text--lighten-4 {
  color: #b2ebf2 !important;
}
.cyan--text.text--lighten-4 input,
.cyan--text.text--lighten-4 textarea {
  caret-color: #b2ebf2 !important;
}
.cyan.lighten-3 {
  background-color: #80deea !important;
  border-color: #80deea !important;
}
.cyan.lighten-3--after:after {
  background-color: #80deea !important;
}
.cyan--text.text--lighten-3 {
  color: #80deea !important;
}
.cyan--text.text--lighten-3 input,
.cyan--text.text--lighten-3 textarea {
  caret-color: #80deea !important;
}
.cyan.lighten-2 {
  background-color: #4dd0e1 !important;
  border-color: #4dd0e1 !important;
}
.cyan.lighten-2--after:after {
  background-color: #4dd0e1 !important;
}
.cyan--text.text--lighten-2 {
  color: #4dd0e1 !important;
}
.cyan--text.text--lighten-2 input,
.cyan--text.text--lighten-2 textarea {
  caret-color: #4dd0e1 !important;
}
.cyan.lighten-1 {
  background-color: #26c6da !important;
  border-color: #26c6da !important;
}
.cyan.lighten-1--after:after {
  background-color: #26c6da !important;
}
.cyan--text.text--lighten-1 {
  color: #26c6da !important;
}
.cyan--text.text--lighten-1 input,
.cyan--text.text--lighten-1 textarea {
  caret-color: #26c6da !important;
}
.cyan.darken-1 {
  background-color: #00acc1 !important;
  border-color: #00acc1 !important;
}
.cyan.darken-1--after:after {
  background-color: #00acc1 !important;
}
.cyan--text.text--darken-1 {
  color: #00acc1 !important;
}
.cyan--text.text--darken-1 input,
.cyan--text.text--darken-1 textarea {
  caret-color: #00acc1 !important;
}
.cyan.darken-2 {
  background-color: #0097a7 !important;
  border-color: #0097a7 !important;
}
.cyan.darken-2--after:after {
  background-color: #0097a7 !important;
}
.cyan--text.text--darken-2 {
  color: #0097a7 !important;
}
.cyan--text.text--darken-2 input,
.cyan--text.text--darken-2 textarea {
  caret-color: #0097a7 !important;
}
.cyan.darken-3 {
  background-color: #00838f !important;
  border-color: #00838f !important;
}
.cyan.darken-3--after:after {
  background-color: #00838f !important;
}
.cyan--text.text--darken-3 {
  color: #00838f !important;
}
.cyan--text.text--darken-3 input,
.cyan--text.text--darken-3 textarea {
  caret-color: #00838f !important;
}
.cyan.darken-4 {
  background-color: #006064 !important;
  border-color: #006064 !important;
}
.cyan.darken-4--after:after {
  background-color: #006064 !important;
}
.cyan--text.text--darken-4 {
  color: #006064 !important;
}
.cyan--text.text--darken-4 input,
.cyan--text.text--darken-4 textarea {
  caret-color: #006064 !important;
}
.cyan.accent-1 {
  background-color: #84ffff !important;
  border-color: #84ffff !important;
}
.cyan.accent-1--after:after {
  background-color: #84ffff !important;
}
.cyan--text.text--accent-1 {
  color: #84ffff !important;
}
.cyan--text.text--accent-1 input,
.cyan--text.text--accent-1 textarea {
  caret-color: #84ffff !important;
}
.cyan.accent-2 {
  background-color: #18ffff !important;
  border-color: #18ffff !important;
}
.cyan.accent-2--after:after {
  background-color: #18ffff !important;
}
.cyan--text.text--accent-2 {
  color: #18ffff !important;
}
.cyan--text.text--accent-2 input,
.cyan--text.text--accent-2 textarea {
  caret-color: #18ffff !important;
}
.cyan.accent-3 {
  background-color: #00e5ff !important;
  border-color: #00e5ff !important;
}
.cyan.accent-3--after:after {
  background-color: #00e5ff !important;
}
.cyan--text.text--accent-3 {
  color: #00e5ff !important;
}
.cyan--text.text--accent-3 input,
.cyan--text.text--accent-3 textarea {
  caret-color: #00e5ff !important;
}
.cyan.accent-4 {
  background-color: #00b8d4 !important;
  border-color: #00b8d4 !important;
}
.cyan.accent-4--after:after {
  background-color: #00b8d4 !important;
}
.cyan--text.text--accent-4 {
  color: #00b8d4 !important;
}
.cyan--text.text--accent-4 input,
.cyan--text.text--accent-4 textarea {
  caret-color: #00b8d4 !important;
}
.teal {
  background-color: #009688 !important;
  border-color: #009688 !important;
}
.teal--text {
  color: #009688 !important;
}
.teal--text input,
.teal--text textarea {
  caret-color: #009688 !important;
}
.teal--after:after {
  background: #009688 !important;
}
.teal.lighten-5 {
  background-color: #e0f2f1 !important;
  border-color: #e0f2f1 !important;
}
.teal.lighten-5--after:after {
  background-color: #e0f2f1 !important;
}
.teal--text.text--lighten-5 {
  color: #e0f2f1 !important;
}
.teal--text.text--lighten-5 input,
.teal--text.text--lighten-5 textarea {
  caret-color: #e0f2f1 !important;
}
.teal.lighten-4 {
  background-color: #b2dfdb !important;
  border-color: #b2dfdb !important;
}
.teal.lighten-4--after:after {
  background-color: #b2dfdb !important;
}
.teal--text.text--lighten-4 {
  color: #b2dfdb !important;
}
.teal--text.text--lighten-4 input,
.teal--text.text--lighten-4 textarea {
  caret-color: #b2dfdb !important;
}
.teal.lighten-3 {
  background-color: #80cbc4 !important;
  border-color: #80cbc4 !important;
}
.teal.lighten-3--after:after {
  background-color: #80cbc4 !important;
}
.teal--text.text--lighten-3 {
  color: #80cbc4 !important;
}
.teal--text.text--lighten-3 input,
.teal--text.text--lighten-3 textarea {
  caret-color: #80cbc4 !important;
}
.teal.lighten-2 {
  background-color: #4db6ac !important;
  border-color: #4db6ac !important;
}
.teal.lighten-2--after:after {
  background-color: #4db6ac !important;
}
.teal--text.text--lighten-2 {
  color: #4db6ac !important;
}
.teal--text.text--lighten-2 input,
.teal--text.text--lighten-2 textarea {
  caret-color: #4db6ac !important;
}
.teal.lighten-1 {
  background-color: #26a69a !important;
  border-color: #26a69a !important;
}
.teal.lighten-1--after:after {
  background-color: #26a69a !important;
}
.teal--text.text--lighten-1 {
  color: #26a69a !important;
}
.teal--text.text--lighten-1 input,
.teal--text.text--lighten-1 textarea {
  caret-color: #26a69a !important;
}
.teal.darken-1 {
  background-color: #00897b !important;
  border-color: #00897b !important;
}
.teal.darken-1--after:after {
  background-color: #00897b !important;
}
.teal--text.text--darken-1 {
  color: #00897b !important;
}
.teal--text.text--darken-1 input,
.teal--text.text--darken-1 textarea {
  caret-color: #00897b !important;
}
.teal.darken-2 {
  background-color: #00796b !important;
  border-color: #00796b !important;
}
.teal.darken-2--after:after {
  background-color: #00796b !important;
}
.teal--text.text--darken-2 {
  color: #00796b !important;
}
.teal--text.text--darken-2 input,
.teal--text.text--darken-2 textarea {
  caret-color: #00796b !important;
}
.teal.darken-3 {
  background-color: #00695c !important;
  border-color: #00695c !important;
}
.teal.darken-3--after:after {
  background-color: #00695c !important;
}
.teal--text.text--darken-3 {
  color: #00695c !important;
}
.teal--text.text--darken-3 input,
.teal--text.text--darken-3 textarea {
  caret-color: #00695c !important;
}
.teal.darken-4 {
  background-color: #004d40 !important;
  border-color: #004d40 !important;
}
.teal.darken-4--after:after {
  background-color: #004d40 !important;
}
.teal--text.text--darken-4 {
  color: #004d40 !important;
}
.teal--text.text--darken-4 input,
.teal--text.text--darken-4 textarea {
  caret-color: #004d40 !important;
}
.teal.accent-1 {
  background-color: #a7ffeb !important;
  border-color: #a7ffeb !important;
}
.teal.accent-1--after:after {
  background-color: #a7ffeb !important;
}
.teal--text.text--accent-1 {
  color: #a7ffeb !important;
}
.teal--text.text--accent-1 input,
.teal--text.text--accent-1 textarea {
  caret-color: #a7ffeb !important;
}
.teal.accent-2 {
  background-color: #64ffda !important;
  border-color: #64ffda !important;
}
.teal.accent-2--after:after {
  background-color: #64ffda !important;
}
.teal--text.text--accent-2 {
  color: #64ffda !important;
}
.teal--text.text--accent-2 input,
.teal--text.text--accent-2 textarea {
  caret-color: #64ffda !important;
}
.teal.accent-3 {
  background-color: #1de9b6 !important;
  border-color: #1de9b6 !important;
}
.teal.accent-3--after:after {
  background-color: #1de9b6 !important;
}
.teal--text.text--accent-3 {
  color: #1de9b6 !important;
}
.teal--text.text--accent-3 input,
.teal--text.text--accent-3 textarea {
  caret-color: #1de9b6 !important;
}
.teal.accent-4 {
  background-color: #00bfa5 !important;
  border-color: #00bfa5 !important;
}
.teal.accent-4--after:after {
  background-color: #00bfa5 !important;
}
.teal--text.text--accent-4 {
  color: #00bfa5 !important;
}
.teal--text.text--accent-4 input,
.teal--text.text--accent-4 textarea {
  caret-color: #00bfa5 !important;
}
.green {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}
.green--text {
  color: #4caf50 !important;
}
.green--text input,
.green--text textarea {
  caret-color: #4caf50 !important;
}
.green--after:after {
  background: #4caf50 !important;
}
.green.lighten-5 {
  background-color: #e8f5e9 !important;
  border-color: #e8f5e9 !important;
}
.green.lighten-5--after:after {
  background-color: #e8f5e9 !important;
}
.green--text.text--lighten-5 {
  color: #e8f5e9 !important;
}
.green--text.text--lighten-5 input,
.green--text.text--lighten-5 textarea {
  caret-color: #e8f5e9 !important;
}
.green.lighten-4 {
  background-color: #c8e6c9 !important;
  border-color: #c8e6c9 !important;
}
.green.lighten-4--after:after {
  background-color: #c8e6c9 !important;
}
.green--text.text--lighten-4 {
  color: #c8e6c9 !important;
}
.green--text.text--lighten-4 input,
.green--text.text--lighten-4 textarea {
  caret-color: #c8e6c9 !important;
}
.green.lighten-3 {
  background-color: #a5d6a7 !important;
  border-color: #a5d6a7 !important;
}
.green.lighten-3--after:after {
  background-color: #a5d6a7 !important;
}
.green--text.text--lighten-3 {
  color: #a5d6a7 !important;
}
.green--text.text--lighten-3 input,
.green--text.text--lighten-3 textarea {
  caret-color: #a5d6a7 !important;
}
.green.lighten-2 {
  background-color: #81c784 !important;
  border-color: #81c784 !important;
}
.green.lighten-2--after:after {
  background-color: #81c784 !important;
}
.green--text.text--lighten-2 {
  color: #81c784 !important;
}
.green--text.text--lighten-2 input,
.green--text.text--lighten-2 textarea {
  caret-color: #81c784 !important;
}
.green.lighten-1 {
  background-color: #66bb6a !important;
  border-color: #66bb6a !important;
}
.green.lighten-1--after:after {
  background-color: #66bb6a !important;
}
.green--text.text--lighten-1 {
  color: #66bb6a !important;
}
.green--text.text--lighten-1 input,
.green--text.text--lighten-1 textarea {
  caret-color: #66bb6a !important;
}
.green.darken-1 {
  background-color: #43a047 !important;
  border-color: #43a047 !important;
}
.green.darken-1--after:after {
  background-color: #43a047 !important;
}
.green--text.text--darken-1 {
  color: #43a047 !important;
}
.green--text.text--darken-1 input,
.green--text.text--darken-1 textarea {
  caret-color: #43a047 !important;
}
.green.darken-2 {
  background-color: #388e3c !important;
  border-color: #388e3c !important;
}
.green.darken-2--after:after {
  background-color: #388e3c !important;
}
.green--text.text--darken-2 {
  color: #388e3c !important;
}
.green--text.text--darken-2 input,
.green--text.text--darken-2 textarea {
  caret-color: #388e3c !important;
}
.green.darken-3 {
  background-color: #2e7d32 !important;
  border-color: #2e7d32 !important;
}
.green.darken-3--after:after {
  background-color: #2e7d32 !important;
}
.green--text.text--darken-3 {
  color: #2e7d32 !important;
}
.green--text.text--darken-3 input,
.green--text.text--darken-3 textarea {
  caret-color: #2e7d32 !important;
}
.green.darken-4 {
  background-color: #1b5e20 !important;
  border-color: #1b5e20 !important;
}
.green.darken-4--after:after {
  background-color: #1b5e20 !important;
}
.green--text.text--darken-4 {
  color: #1b5e20 !important;
}
.green--text.text--darken-4 input,
.green--text.text--darken-4 textarea {
  caret-color: #1b5e20 !important;
}
.green.accent-1 {
  background-color: #b9f6ca !important;
  border-color: #b9f6ca !important;
}
.green.accent-1--after:after {
  background-color: #b9f6ca !important;
}
.green--text.text--accent-1 {
  color: #b9f6ca !important;
}
.green--text.text--accent-1 input,
.green--text.text--accent-1 textarea {
  caret-color: #b9f6ca !important;
}
.green.accent-2 {
  background-color: #69f0ae !important;
  border-color: #69f0ae !important;
}
.green.accent-2--after:after {
  background-color: #69f0ae !important;
}
.green--text.text--accent-2 {
  color: #69f0ae !important;
}
.green--text.text--accent-2 input,
.green--text.text--accent-2 textarea {
  caret-color: #69f0ae !important;
}
.green.accent-3 {
  background-color: #00e676 !important;
  border-color: #00e676 !important;
}
.green.accent-3--after:after {
  background-color: #00e676 !important;
}
.green--text.text--accent-3 {
  color: #00e676 !important;
}
.green--text.text--accent-3 input,
.green--text.text--accent-3 textarea {
  caret-color: #00e676 !important;
}
.green.accent-4 {
  background-color: #00c853 !important;
  border-color: #00c853 !important;
}
.green.accent-4--after:after {
  background-color: #00c853 !important;
}
.green--text.text--accent-4 {
  color: #00c853 !important;
}
.green--text.text--accent-4 input,
.green--text.text--accent-4 textarea {
  caret-color: #00c853 !important;
}
.light-green {
  background-color: #8bc34a !important;
  border-color: #8bc34a !important;
}
.light-green--text {
  color: #8bc34a !important;
}
.light-green--text input,
.light-green--text textarea {
  caret-color: #8bc34a !important;
}
.light-green--after:after {
  background: #8bc34a !important;
}
.light-green.lighten-5 {
  background-color: #f1f8e9 !important;
  border-color: #f1f8e9 !important;
}
.light-green.lighten-5--after:after {
  background-color: #f1f8e9 !important;
}
.light-green--text.text--lighten-5 {
  color: #f1f8e9 !important;
}
.light-green--text.text--lighten-5 input,
.light-green--text.text--lighten-5 textarea {
  caret-color: #f1f8e9 !important;
}
.light-green.lighten-4 {
  background-color: #dcedc8 !important;
  border-color: #dcedc8 !important;
}
.light-green.lighten-4--after:after {
  background-color: #dcedc8 !important;
}
.light-green--text.text--lighten-4 {
  color: #dcedc8 !important;
}
.light-green--text.text--lighten-4 input,
.light-green--text.text--lighten-4 textarea {
  caret-color: #dcedc8 !important;
}
.light-green.lighten-3 {
  background-color: #c5e1a5 !important;
  border-color: #c5e1a5 !important;
}
.light-green.lighten-3--after:after {
  background-color: #c5e1a5 !important;
}
.light-green--text.text--lighten-3 {
  color: #c5e1a5 !important;
}
.light-green--text.text--lighten-3 input,
.light-green--text.text--lighten-3 textarea {
  caret-color: #c5e1a5 !important;
}
.light-green.lighten-2 {
  background-color: #aed581 !important;
  border-color: #aed581 !important;
}
.light-green.lighten-2--after:after {
  background-color: #aed581 !important;
}
.light-green--text.text--lighten-2 {
  color: #aed581 !important;
}
.light-green--text.text--lighten-2 input,
.light-green--text.text--lighten-2 textarea {
  caret-color: #aed581 !important;
}
.light-green.lighten-1 {
  background-color: #9ccc65 !important;
  border-color: #9ccc65 !important;
}
.light-green.lighten-1--after:after {
  background-color: #9ccc65 !important;
}
.light-green--text.text--lighten-1 {
  color: #9ccc65 !important;
}
.light-green--text.text--lighten-1 input,
.light-green--text.text--lighten-1 textarea {
  caret-color: #9ccc65 !important;
}
.light-green.darken-1 {
  background-color: #7cb342 !important;
  border-color: #7cb342 !important;
}
.light-green.darken-1--after:after {
  background-color: #7cb342 !important;
}
.light-green--text.text--darken-1 {
  color: #7cb342 !important;
}
.light-green--text.text--darken-1 input,
.light-green--text.text--darken-1 textarea {
  caret-color: #7cb342 !important;
}
.light-green.darken-2 {
  background-color: #689f38 !important;
  border-color: #689f38 !important;
}
.light-green.darken-2--after:after {
  background-color: #689f38 !important;
}
.light-green--text.text--darken-2 {
  color: #689f38 !important;
}
.light-green--text.text--darken-2 input,
.light-green--text.text--darken-2 textarea {
  caret-color: #689f38 !important;
}
.light-green.darken-3 {
  background-color: #558b2f !important;
  border-color: #558b2f !important;
}
.light-green.darken-3--after:after {
  background-color: #558b2f !important;
}
.light-green--text.text--darken-3 {
  color: #558b2f !important;
}
.light-green--text.text--darken-3 input,
.light-green--text.text--darken-3 textarea {
  caret-color: #558b2f !important;
}
.light-green.darken-4 {
  background-color: #33691e !important;
  border-color: #33691e !important;
}
.light-green.darken-4--after:after {
  background-color: #33691e !important;
}
.light-green--text.text--darken-4 {
  color: #33691e !important;
}
.light-green--text.text--darken-4 input,
.light-green--text.text--darken-4 textarea {
  caret-color: #33691e !important;
}
.light-green.accent-1 {
  background-color: #ccff90 !important;
  border-color: #ccff90 !important;
}
.light-green.accent-1--after:after {
  background-color: #ccff90 !important;
}
.light-green--text.text--accent-1 {
  color: #ccff90 !important;
}
.light-green--text.text--accent-1 input,
.light-green--text.text--accent-1 textarea {
  caret-color: #ccff90 !important;
}
.light-green.accent-2 {
  background-color: #b2ff59 !important;
  border-color: #b2ff59 !important;
}
.light-green.accent-2--after:after {
  background-color: #b2ff59 !important;
}
.light-green--text.text--accent-2 {
  color: #b2ff59 !important;
}
.light-green--text.text--accent-2 input,
.light-green--text.text--accent-2 textarea {
  caret-color: #b2ff59 !important;
}
.light-green.accent-3 {
  background-color: #76ff03 !important;
  border-color: #76ff03 !important;
}
.light-green.accent-3--after:after {
  background-color: #76ff03 !important;
}
.light-green--text.text--accent-3 {
  color: #76ff03 !important;
}
.light-green--text.text--accent-3 input,
.light-green--text.text--accent-3 textarea {
  caret-color: #76ff03 !important;
}
.light-green.accent-4 {
  background-color: #64dd17 !important;
  border-color: #64dd17 !important;
}
.light-green.accent-4--after:after {
  background-color: #64dd17 !important;
}
.light-green--text.text--accent-4 {
  color: #64dd17 !important;
}
.light-green--text.text--accent-4 input,
.light-green--text.text--accent-4 textarea {
  caret-color: #64dd17 !important;
}
.lime {
  background-color: #cddc39 !important;
  border-color: #cddc39 !important;
}
.lime--text {
  color: #cddc39 !important;
}
.lime--text input,
.lime--text textarea {
  caret-color: #cddc39 !important;
}
.lime--after:after {
  background: #cddc39 !important;
}
.lime.lighten-5 {
  background-color: #f9fbe7 !important;
  border-color: #f9fbe7 !important;
}
.lime.lighten-5--after:after {
  background-color: #f9fbe7 !important;
}
.lime--text.text--lighten-5 {
  color: #f9fbe7 !important;
}
.lime--text.text--lighten-5 input,
.lime--text.text--lighten-5 textarea {
  caret-color: #f9fbe7 !important;
}
.lime.lighten-4 {
  background-color: #f0f4c3 !important;
  border-color: #f0f4c3 !important;
}
.lime.lighten-4--after:after {
  background-color: #f0f4c3 !important;
}
.lime--text.text--lighten-4 {
  color: #f0f4c3 !important;
}
.lime--text.text--lighten-4 input,
.lime--text.text--lighten-4 textarea {
  caret-color: #f0f4c3 !important;
}
.lime.lighten-3 {
  background-color: #e6ee9c !important;
  border-color: #e6ee9c !important;
}
.lime.lighten-3--after:after {
  background-color: #e6ee9c !important;
}
.lime--text.text--lighten-3 {
  color: #e6ee9c !important;
}
.lime--text.text--lighten-3 input,
.lime--text.text--lighten-3 textarea {
  caret-color: #e6ee9c !important;
}
.lime.lighten-2 {
  background-color: #dce775 !important;
  border-color: #dce775 !important;
}
.lime.lighten-2--after:after {
  background-color: #dce775 !important;
}
.lime--text.text--lighten-2 {
  color: #dce775 !important;
}
.lime--text.text--lighten-2 input,
.lime--text.text--lighten-2 textarea {
  caret-color: #dce775 !important;
}
.lime.lighten-1 {
  background-color: #d4e157 !important;
  border-color: #d4e157 !important;
}
.lime.lighten-1--after:after {
  background-color: #d4e157 !important;
}
.lime--text.text--lighten-1 {
  color: #d4e157 !important;
}
.lime--text.text--lighten-1 input,
.lime--text.text--lighten-1 textarea {
  caret-color: #d4e157 !important;
}
.lime.darken-1 {
  background-color: #c0ca33 !important;
  border-color: #c0ca33 !important;
}
.lime.darken-1--after:after {
  background-color: #c0ca33 !important;
}
.lime--text.text--darken-1 {
  color: #c0ca33 !important;
}
.lime--text.text--darken-1 input,
.lime--text.text--darken-1 textarea {
  caret-color: #c0ca33 !important;
}
.lime.darken-2 {
  background-color: #afb42b !important;
  border-color: #afb42b !important;
}
.lime.darken-2--after:after {
  background-color: #afb42b !important;
}
.lime--text.text--darken-2 {
  color: #afb42b !important;
}
.lime--text.text--darken-2 input,
.lime--text.text--darken-2 textarea {
  caret-color: #afb42b !important;
}
.lime.darken-3 {
  background-color: #9e9d24 !important;
  border-color: #9e9d24 !important;
}
.lime.darken-3--after:after {
  background-color: #9e9d24 !important;
}
.lime--text.text--darken-3 {
  color: #9e9d24 !important;
}
.lime--text.text--darken-3 input,
.lime--text.text--darken-3 textarea {
  caret-color: #9e9d24 !important;
}
.lime.darken-4 {
  background-color: #827717 !important;
  border-color: #827717 !important;
}
.lime.darken-4--after:after {
  background-color: #827717 !important;
}
.lime--text.text--darken-4 {
  color: #827717 !important;
}
.lime--text.text--darken-4 input,
.lime--text.text--darken-4 textarea {
  caret-color: #827717 !important;
}
.lime.accent-1 {
  background-color: #f4ff81 !important;
  border-color: #f4ff81 !important;
}
.lime.accent-1--after:after {
  background-color: #f4ff81 !important;
}
.lime--text.text--accent-1 {
  color: #f4ff81 !important;
}
.lime--text.text--accent-1 input,
.lime--text.text--accent-1 textarea {
  caret-color: #f4ff81 !important;
}
.lime.accent-2 {
  background-color: #eeff41 !important;
  border-color: #eeff41 !important;
}
.lime.accent-2--after:after {
  background-color: #eeff41 !important;
}
.lime--text.text--accent-2 {
  color: #eeff41 !important;
}
.lime--text.text--accent-2 input,
.lime--text.text--accent-2 textarea {
  caret-color: #eeff41 !important;
}
.lime.accent-3 {
  background-color: #c6ff00 !important;
  border-color: #c6ff00 !important;
}
.lime.accent-3--after:after {
  background-color: #c6ff00 !important;
}
.lime--text.text--accent-3 {
  color: #c6ff00 !important;
}
.lime--text.text--accent-3 input,
.lime--text.text--accent-3 textarea {
  caret-color: #c6ff00 !important;
}
.lime.accent-4 {
  background-color: #aeea00 !important;
  border-color: #aeea00 !important;
}
.lime.accent-4--after:after {
  background-color: #aeea00 !important;
}
.lime--text.text--accent-4 {
  color: #aeea00 !important;
}
.lime--text.text--accent-4 input,
.lime--text.text--accent-4 textarea {
  caret-color: #aeea00 !important;
}
.yellow {
  background-color: #ffeb3b !important;
  border-color: #ffeb3b !important;
}
.yellow--text {
  color: #ffeb3b !important;
}
.yellow--text input,
.yellow--text textarea {
  caret-color: #ffeb3b !important;
}
.yellow--after:after {
  background: #ffeb3b !important;
}
.yellow.lighten-5 {
  background-color: #fffde7 !important;
  border-color: #fffde7 !important;
}
.yellow.lighten-5--after:after {
  background-color: #fffde7 !important;
}
.yellow--text.text--lighten-5 {
  color: #fffde7 !important;
}
.yellow--text.text--lighten-5 input,
.yellow--text.text--lighten-5 textarea {
  caret-color: #fffde7 !important;
}
.yellow.lighten-4 {
  background-color: #fff9c4 !important;
  border-color: #fff9c4 !important;
}
.yellow.lighten-4--after:after {
  background-color: #fff9c4 !important;
}
.yellow--text.text--lighten-4 {
  color: #fff9c4 !important;
}
.yellow--text.text--lighten-4 input,
.yellow--text.text--lighten-4 textarea {
  caret-color: #fff9c4 !important;
}
.yellow.lighten-3 {
  background-color: #fff59d !important;
  border-color: #fff59d !important;
}
.yellow.lighten-3--after:after {
  background-color: #fff59d !important;
}
.yellow--text.text--lighten-3 {
  color: #fff59d !important;
}
.yellow--text.text--lighten-3 input,
.yellow--text.text--lighten-3 textarea {
  caret-color: #fff59d !important;
}
.yellow.lighten-2 {
  background-color: #fff176 !important;
  border-color: #fff176 !important;
}
.yellow.lighten-2--after:after {
  background-color: #fff176 !important;
}
.yellow--text.text--lighten-2 {
  color: #fff176 !important;
}
.yellow--text.text--lighten-2 input,
.yellow--text.text--lighten-2 textarea {
  caret-color: #fff176 !important;
}
.yellow.lighten-1 {
  background-color: #ffee58 !important;
  border-color: #ffee58 !important;
}
.yellow.lighten-1--after:after {
  background-color: #ffee58 !important;
}
.yellow--text.text--lighten-1 {
  color: #ffee58 !important;
}
.yellow--text.text--lighten-1 input,
.yellow--text.text--lighten-1 textarea {
  caret-color: #ffee58 !important;
}
.yellow.darken-1 {
  background-color: #fdd835 !important;
  border-color: #fdd835 !important;
}
.yellow.darken-1--after:after {
  background-color: #fdd835 !important;
}
.yellow--text.text--darken-1 {
  color: #fdd835 !important;
}
.yellow--text.text--darken-1 input,
.yellow--text.text--darken-1 textarea {
  caret-color: #fdd835 !important;
}
.yellow.darken-2 {
  background-color: #fbc02d !important;
  border-color: #fbc02d !important;
}
.yellow.darken-2--after:after {
  background-color: #fbc02d !important;
}
.yellow--text.text--darken-2 {
  color: #fbc02d !important;
}
.yellow--text.text--darken-2 input,
.yellow--text.text--darken-2 textarea {
  caret-color: #fbc02d !important;
}
.yellow.darken-3 {
  background-color: #f9a825 !important;
  border-color: #f9a825 !important;
}
.yellow.darken-3--after:after {
  background-color: #f9a825 !important;
}
.yellow--text.text--darken-3 {
  color: #f9a825 !important;
}
.yellow--text.text--darken-3 input,
.yellow--text.text--darken-3 textarea {
  caret-color: #f9a825 !important;
}
.yellow.darken-4 {
  background-color: #f57f17 !important;
  border-color: #f57f17 !important;
}
.yellow.darken-4--after:after {
  background-color: #f57f17 !important;
}
.yellow--text.text--darken-4 {
  color: #f57f17 !important;
}
.yellow--text.text--darken-4 input,
.yellow--text.text--darken-4 textarea {
  caret-color: #f57f17 !important;
}
.yellow.accent-1 {
  background-color: #ffff8d !important;
  border-color: #ffff8d !important;
}
.yellow.accent-1--after:after {
  background-color: #ffff8d !important;
}
.yellow--text.text--accent-1 {
  color: #ffff8d !important;
}
.yellow--text.text--accent-1 input,
.yellow--text.text--accent-1 textarea {
  caret-color: #ffff8d !important;
}
.yellow.accent-2 {
  background-color: #ff0 !important;
  border-color: #ff0 !important;
}
.yellow.accent-2--after:after {
  background-color: #ff0 !important;
}
.yellow--text.text--accent-2 {
  color: #ff0 !important;
}
.yellow--text.text--accent-2 input,
.yellow--text.text--accent-2 textarea {
  caret-color: #ff0 !important;
}
.yellow.accent-3 {
  background-color: #ffea00 !important;
  border-color: #ffea00 !important;
}
.yellow.accent-3--after:after {
  background-color: #ffea00 !important;
}
.yellow--text.text--accent-3 {
  color: #ffea00 !important;
}
.yellow--text.text--accent-3 input,
.yellow--text.text--accent-3 textarea {
  caret-color: #ffea00 !important;
}
.yellow.accent-4 {
  background-color: #ffd600 !important;
  border-color: #ffd600 !important;
}
.yellow.accent-4--after:after {
  background-color: #ffd600 !important;
}
.yellow--text.text--accent-4 {
  color: #ffd600 !important;
}
.yellow--text.text--accent-4 input,
.yellow--text.text--accent-4 textarea {
  caret-color: #ffd600 !important;
}
.amber {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}
.amber--text {
  color: #ffc107 !important;
}
.amber--text input,
.amber--text textarea {
  caret-color: #ffc107 !important;
}
.amber--after:after {
  background: #ffc107 !important;
}
.amber.lighten-5 {
  background-color: #fff8e1 !important;
  border-color: #fff8e1 !important;
}
.amber.lighten-5--after:after {
  background-color: #fff8e1 !important;
}
.amber--text.text--lighten-5 {
  color: #fff8e1 !important;
}
.amber--text.text--lighten-5 input,
.amber--text.text--lighten-5 textarea {
  caret-color: #fff8e1 !important;
}
.amber.lighten-4 {
  background-color: #ffecb3 !important;
  border-color: #ffecb3 !important;
}
.amber.lighten-4--after:after {
  background-color: #ffecb3 !important;
}
.amber--text.text--lighten-4 {
  color: #ffecb3 !important;
}
.amber--text.text--lighten-4 input,
.amber--text.text--lighten-4 textarea {
  caret-color: #ffecb3 !important;
}
.amber.lighten-3 {
  background-color: #ffe082 !important;
  border-color: #ffe082 !important;
}
.amber.lighten-3--after:after {
  background-color: #ffe082 !important;
}
.amber--text.text--lighten-3 {
  color: #ffe082 !important;
}
.amber--text.text--lighten-3 input,
.amber--text.text--lighten-3 textarea {
  caret-color: #ffe082 !important;
}
.amber.lighten-2 {
  background-color: #ffd54f !important;
  border-color: #ffd54f !important;
}
.amber.lighten-2--after:after {
  background-color: #ffd54f !important;
}
.amber--text.text--lighten-2 {
  color: #ffd54f !important;
}
.amber--text.text--lighten-2 input,
.amber--text.text--lighten-2 textarea {
  caret-color: #ffd54f !important;
}
.amber.lighten-1 {
  background-color: #ffca28 !important;
  border-color: #ffca28 !important;
}
.amber.lighten-1--after:after {
  background-color: #ffca28 !important;
}
.amber--text.text--lighten-1 {
  color: #ffca28 !important;
}
.amber--text.text--lighten-1 input,
.amber--text.text--lighten-1 textarea {
  caret-color: #ffca28 !important;
}
.amber.darken-1 {
  background-color: #ffb300 !important;
  border-color: #ffb300 !important;
}
.amber.darken-1--after:after {
  background-color: #ffb300 !important;
}
.amber--text.text--darken-1 {
  color: #ffb300 !important;
}
.amber--text.text--darken-1 input,
.amber--text.text--darken-1 textarea {
  caret-color: #ffb300 !important;
}
.amber.darken-2 {
  background-color: #ffa000 !important;
  border-color: #ffa000 !important;
}
.amber.darken-2--after:after {
  background-color: #ffa000 !important;
}
.amber--text.text--darken-2 {
  color: #ffa000 !important;
}
.amber--text.text--darken-2 input,
.amber--text.text--darken-2 textarea {
  caret-color: #ffa000 !important;
}
.amber.darken-3 {
  background-color: #ff8f00 !important;
  border-color: #ff8f00 !important;
}
.amber.darken-3--after:after {
  background-color: #ff8f00 !important;
}
.amber--text.text--darken-3 {
  color: #ff8f00 !important;
}
.amber--text.text--darken-3 input,
.amber--text.text--darken-3 textarea {
  caret-color: #ff8f00 !important;
}
.amber.darken-4 {
  background-color: #ff6f00 !important;
  border-color: #ff6f00 !important;
}
.amber.darken-4--after:after {
  background-color: #ff6f00 !important;
}
.amber--text.text--darken-4 {
  color: #ff6f00 !important;
}
.amber--text.text--darken-4 input,
.amber--text.text--darken-4 textarea {
  caret-color: #ff6f00 !important;
}
.amber.accent-1 {
  background-color: #ffe57f !important;
  border-color: #ffe57f !important;
}
.amber.accent-1--after:after {
  background-color: #ffe57f !important;
}
.amber--text.text--accent-1 {
  color: #ffe57f !important;
}
.amber--text.text--accent-1 input,
.amber--text.text--accent-1 textarea {
  caret-color: #ffe57f !important;
}
.amber.accent-2 {
  background-color: #ffd740 !important;
  border-color: #ffd740 !important;
}
.amber.accent-2--after:after {
  background-color: #ffd740 !important;
}
.amber--text.text--accent-2 {
  color: #ffd740 !important;
}
.amber--text.text--accent-2 input,
.amber--text.text--accent-2 textarea {
  caret-color: #ffd740 !important;
}
.amber.accent-3 {
  background-color: #ffc400 !important;
  border-color: #ffc400 !important;
}
.amber.accent-3--after:after {
  background-color: #ffc400 !important;
}
.amber--text.text--accent-3 {
  color: #ffc400 !important;
}
.amber--text.text--accent-3 input,
.amber--text.text--accent-3 textarea {
  caret-color: #ffc400 !important;
}
.amber.accent-4 {
  background-color: #ffab00 !important;
  border-color: #ffab00 !important;
}
.amber.accent-4--after:after {
  background-color: #ffab00 !important;
}
.amber--text.text--accent-4 {
  color: #ffab00 !important;
}
.amber--text.text--accent-4 input,
.amber--text.text--accent-4 textarea {
  caret-color: #ffab00 !important;
}
.orange {
  background-color: #ff9800 !important;
  border-color: #ff9800 !important;
}
.orange--text {
  color: #ff9800 !important;
}
.orange--text input,
.orange--text textarea {
  caret-color: #ff9800 !important;
}
.orange--after:after {
  background: #ff9800 !important;
}
.orange.lighten-5 {
  background-color: #fff3e0 !important;
  border-color: #fff3e0 !important;
}
.orange.lighten-5--after:after {
  background-color: #fff3e0 !important;
}
.orange--text.text--lighten-5 {
  color: #fff3e0 !important;
}
.orange--text.text--lighten-5 input,
.orange--text.text--lighten-5 textarea {
  caret-color: #fff3e0 !important;
}
.orange.lighten-4 {
  background-color: #ffe0b2 !important;
  border-color: #ffe0b2 !important;
}
.orange.lighten-4--after:after {
  background-color: #ffe0b2 !important;
}
.orange--text.text--lighten-4 {
  color: #ffe0b2 !important;
}
.orange--text.text--lighten-4 input,
.orange--text.text--lighten-4 textarea {
  caret-color: #ffe0b2 !important;
}
.orange.lighten-3 {
  background-color: #ffcc80 !important;
  border-color: #ffcc80 !important;
}
.orange.lighten-3--after:after {
  background-color: #ffcc80 !important;
}
.orange--text.text--lighten-3 {
  color: #ffcc80 !important;
}
.orange--text.text--lighten-3 input,
.orange--text.text--lighten-3 textarea {
  caret-color: #ffcc80 !important;
}
.orange.lighten-2 {
  background-color: #ffb74d !important;
  border-color: #ffb74d !important;
}
.orange.lighten-2--after:after {
  background-color: #ffb74d !important;
}
.orange--text.text--lighten-2 {
  color: #ffb74d !important;
}
.orange--text.text--lighten-2 input,
.orange--text.text--lighten-2 textarea {
  caret-color: #ffb74d !important;
}
.orange.lighten-1 {
  background-color: #ffa726 !important;
  border-color: #ffa726 !important;
}
.orange.lighten-1--after:after {
  background-color: #ffa726 !important;
}
.orange--text.text--lighten-1 {
  color: #ffa726 !important;
}
.orange--text.text--lighten-1 input,
.orange--text.text--lighten-1 textarea {
  caret-color: #ffa726 !important;
}
.orange.darken-1 {
  background-color: #fb8c00 !important;
  border-color: #fb8c00 !important;
}
.orange.darken-1--after:after {
  background-color: #fb8c00 !important;
}
.orange--text.text--darken-1 {
  color: #fb8c00 !important;
}
.orange--text.text--darken-1 input,
.orange--text.text--darken-1 textarea {
  caret-color: #fb8c00 !important;
}
.orange.darken-2 {
  background-color: #f57c00 !important;
  border-color: #f57c00 !important;
}
.orange.darken-2--after:after {
  background-color: #f57c00 !important;
}
.orange--text.text--darken-2 {
  color: #f57c00 !important;
}
.orange--text.text--darken-2 input,
.orange--text.text--darken-2 textarea {
  caret-color: #f57c00 !important;
}
.orange.darken-3 {
  background-color: #ef6c00 !important;
  border-color: #ef6c00 !important;
}
.orange.darken-3--after:after {
  background-color: #ef6c00 !important;
}
.orange--text.text--darken-3 {
  color: #ef6c00 !important;
}
.orange--text.text--darken-3 input,
.orange--text.text--darken-3 textarea {
  caret-color: #ef6c00 !important;
}
.orange.darken-4 {
  background-color: #e65100 !important;
  border-color: #e65100 !important;
}
.orange.darken-4--after:after {
  background-color: #e65100 !important;
}
.orange--text.text--darken-4 {
  color: #e65100 !important;
}
.orange--text.text--darken-4 input,
.orange--text.text--darken-4 textarea {
  caret-color: #e65100 !important;
}
.orange.accent-1 {
  background-color: #ffd180 !important;
  border-color: #ffd180 !important;
}
.orange.accent-1--after:after {
  background-color: #ffd180 !important;
}
.orange--text.text--accent-1 {
  color: #ffd180 !important;
}
.orange--text.text--accent-1 input,
.orange--text.text--accent-1 textarea {
  caret-color: #ffd180 !important;
}
.orange.accent-2 {
  background-color: #ffab40 !important;
  border-color: #ffab40 !important;
}
.orange.accent-2--after:after {
  background-color: #ffab40 !important;
}
.orange--text.text--accent-2 {
  color: #ffab40 !important;
}
.orange--text.text--accent-2 input,
.orange--text.text--accent-2 textarea {
  caret-color: #ffab40 !important;
}
.orange.accent-3 {
  background-color: #ff9100 !important;
  border-color: #ff9100 !important;
}
.orange.accent-3--after:after {
  background-color: #ff9100 !important;
}
.orange--text.text--accent-3 {
  color: #ff9100 !important;
}
.orange--text.text--accent-3 input,
.orange--text.text--accent-3 textarea {
  caret-color: #ff9100 !important;
}
.orange.accent-4 {
  background-color: #ff6d00 !important;
  border-color: #ff6d00 !important;
}
.orange.accent-4--after:after {
  background-color: #ff6d00 !important;
}
.orange--text.text--accent-4 {
  color: #ff6d00 !important;
}
.orange--text.text--accent-4 input,
.orange--text.text--accent-4 textarea {
  caret-color: #ff6d00 !important;
}
.deep-orange {
  background-color: #ff5722 !important;
  border-color: #ff5722 !important;
}
.deep-orange--text {
  color: #ff5722 !important;
}
.deep-orange--text input,
.deep-orange--text textarea {
  caret-color: #ff5722 !important;
}
.deep-orange--after:after {
  background: #ff5722 !important;
}
.deep-orange.lighten-5 {
  background-color: #fbe9e7 !important;
  border-color: #fbe9e7 !important;
}
.deep-orange.lighten-5--after:after {
  background-color: #fbe9e7 !important;
}
.deep-orange--text.text--lighten-5 {
  color: #fbe9e7 !important;
}
.deep-orange--text.text--lighten-5 input,
.deep-orange--text.text--lighten-5 textarea {
  caret-color: #fbe9e7 !important;
}
.deep-orange.lighten-4 {
  background-color: #ffccbc !important;
  border-color: #ffccbc !important;
}
.deep-orange.lighten-4--after:after {
  background-color: #ffccbc !important;
}
.deep-orange--text.text--lighten-4 {
  color: #ffccbc !important;
}
.deep-orange--text.text--lighten-4 input,
.deep-orange--text.text--lighten-4 textarea {
  caret-color: #ffccbc !important;
}
.deep-orange.lighten-3 {
  background-color: #ffab91 !important;
  border-color: #ffab91 !important;
}
.deep-orange.lighten-3--after:after {
  background-color: #ffab91 !important;
}
.deep-orange--text.text--lighten-3 {
  color: #ffab91 !important;
}
.deep-orange--text.text--lighten-3 input,
.deep-orange--text.text--lighten-3 textarea {
  caret-color: #ffab91 !important;
}
.deep-orange.lighten-2 {
  background-color: #ff8a65 !important;
  border-color: #ff8a65 !important;
}
.deep-orange.lighten-2--after:after {
  background-color: #ff8a65 !important;
}
.deep-orange--text.text--lighten-2 {
  color: #ff8a65 !important;
}
.deep-orange--text.text--lighten-2 input,
.deep-orange--text.text--lighten-2 textarea {
  caret-color: #ff8a65 !important;
}
.deep-orange.lighten-1 {
  background-color: #ff7043 !important;
  border-color: #ff7043 !important;
}
.deep-orange.lighten-1--after:after {
  background-color: #ff7043 !important;
}
.deep-orange--text.text--lighten-1 {
  color: #ff7043 !important;
}
.deep-orange--text.text--lighten-1 input,
.deep-orange--text.text--lighten-1 textarea {
  caret-color: #ff7043 !important;
}
.deep-orange.darken-1 {
  background-color: #f4511e !important;
  border-color: #f4511e !important;
}
.deep-orange.darken-1--after:after {
  background-color: #f4511e !important;
}
.deep-orange--text.text--darken-1 {
  color: #f4511e !important;
}
.deep-orange--text.text--darken-1 input,
.deep-orange--text.text--darken-1 textarea {
  caret-color: #f4511e !important;
}
.deep-orange.darken-2 {
  background-color: #e64a19 !important;
  border-color: #e64a19 !important;
}
.deep-orange.darken-2--after:after {
  background-color: #e64a19 !important;
}
.deep-orange--text.text--darken-2 {
  color: #e64a19 !important;
}
.deep-orange--text.text--darken-2 input,
.deep-orange--text.text--darken-2 textarea {
  caret-color: #e64a19 !important;
}
.deep-orange.darken-3 {
  background-color: #d84315 !important;
  border-color: #d84315 !important;
}
.deep-orange.darken-3--after:after {
  background-color: #d84315 !important;
}
.deep-orange--text.text--darken-3 {
  color: #d84315 !important;
}
.deep-orange--text.text--darken-3 input,
.deep-orange--text.text--darken-3 textarea {
  caret-color: #d84315 !important;
}
.deep-orange.darken-4 {
  background-color: #bf360c !important;
  border-color: #bf360c !important;
}
.deep-orange.darken-4--after:after {
  background-color: #bf360c !important;
}
.deep-orange--text.text--darken-4 {
  color: #bf360c !important;
}
.deep-orange--text.text--darken-4 input,
.deep-orange--text.text--darken-4 textarea {
  caret-color: #bf360c !important;
}
.deep-orange.accent-1 {
  background-color: #ff9e80 !important;
  border-color: #ff9e80 !important;
}
.deep-orange.accent-1--after:after {
  background-color: #ff9e80 !important;
}
.deep-orange--text.text--accent-1 {
  color: #ff9e80 !important;
}
.deep-orange--text.text--accent-1 input,
.deep-orange--text.text--accent-1 textarea {
  caret-color: #ff9e80 !important;
}
.deep-orange.accent-2 {
  background-color: #ff6e40 !important;
  border-color: #ff6e40 !important;
}
.deep-orange.accent-2--after:after {
  background-color: #ff6e40 !important;
}
.deep-orange--text.text--accent-2 {
  color: #ff6e40 !important;
}
.deep-orange--text.text--accent-2 input,
.deep-orange--text.text--accent-2 textarea {
  caret-color: #ff6e40 !important;
}
.deep-orange.accent-3 {
  background-color: #ff3d00 !important;
  border-color: #ff3d00 !important;
}
.deep-orange.accent-3--after:after {
  background-color: #ff3d00 !important;
}
.deep-orange--text.text--accent-3 {
  color: #ff3d00 !important;
}
.deep-orange--text.text--accent-3 input,
.deep-orange--text.text--accent-3 textarea {
  caret-color: #ff3d00 !important;
}
.deep-orange.accent-4 {
  background-color: #dd2c00 !important;
  border-color: #dd2c00 !important;
}
.deep-orange.accent-4--after:after {
  background-color: #dd2c00 !important;
}
.deep-orange--text.text--accent-4 {
  color: #dd2c00 !important;
}
.deep-orange--text.text--accent-4 input,
.deep-orange--text.text--accent-4 textarea {
  caret-color: #dd2c00 !important;
}
.brown {
  background-color: #795548 !important;
  border-color: #795548 !important;
}
.brown--text {
  color: #795548 !important;
}
.brown--text input,
.brown--text textarea {
  caret-color: #795548 !important;
}
.brown--after:after {
  background: #795548 !important;
}
.brown.lighten-5 {
  background-color: #efebe9 !important;
  border-color: #efebe9 !important;
}
.brown.lighten-5--after:after {
  background-color: #efebe9 !important;
}
.brown--text.text--lighten-5 {
  color: #efebe9 !important;
}
.brown--text.text--lighten-5 input,
.brown--text.text--lighten-5 textarea {
  caret-color: #efebe9 !important;
}
.brown.lighten-4 {
  background-color: #d7ccc8 !important;
  border-color: #d7ccc8 !important;
}
.brown.lighten-4--after:after {
  background-color: #d7ccc8 !important;
}
.brown--text.text--lighten-4 {
  color: #d7ccc8 !important;
}
.brown--text.text--lighten-4 input,
.brown--text.text--lighten-4 textarea {
  caret-color: #d7ccc8 !important;
}
.brown.lighten-3 {
  background-color: #bcaaa4 !important;
  border-color: #bcaaa4 !important;
}
.brown.lighten-3--after:after {
  background-color: #bcaaa4 !important;
}
.brown--text.text--lighten-3 {
  color: #bcaaa4 !important;
}
.brown--text.text--lighten-3 input,
.brown--text.text--lighten-3 textarea {
  caret-color: #bcaaa4 !important;
}
.brown.lighten-2 {
  background-color: #a1887f !important;
  border-color: #a1887f !important;
}
.brown.lighten-2--after:after {
  background-color: #a1887f !important;
}
.brown--text.text--lighten-2 {
  color: #a1887f !important;
}
.brown--text.text--lighten-2 input,
.brown--text.text--lighten-2 textarea {
  caret-color: #a1887f !important;
}
.brown.lighten-1 {
  background-color: #8d6e63 !important;
  border-color: #8d6e63 !important;
}
.brown.lighten-1--after:after {
  background-color: #8d6e63 !important;
}
.brown--text.text--lighten-1 {
  color: #8d6e63 !important;
}
.brown--text.text--lighten-1 input,
.brown--text.text--lighten-1 textarea {
  caret-color: #8d6e63 !important;
}
.brown.darken-1 {
  background-color: #6d4c41 !important;
  border-color: #6d4c41 !important;
}
.brown.darken-1--after:after {
  background-color: #6d4c41 !important;
}
.brown--text.text--darken-1 {
  color: #6d4c41 !important;
}
.brown--text.text--darken-1 input,
.brown--text.text--darken-1 textarea {
  caret-color: #6d4c41 !important;
}
.brown.darken-2 {
  background-color: #5d4037 !important;
  border-color: #5d4037 !important;
}
.brown.darken-2--after:after {
  background-color: #5d4037 !important;
}
.brown--text.text--darken-2 {
  color: #5d4037 !important;
}
.brown--text.text--darken-2 input,
.brown--text.text--darken-2 textarea {
  caret-color: #5d4037 !important;
}
.brown.darken-3 {
  background-color: #4e342e !important;
  border-color: #4e342e !important;
}
.brown.darken-3--after:after {
  background-color: #4e342e !important;
}
.brown--text.text--darken-3 {
  color: #4e342e !important;
}
.brown--text.text--darken-3 input,
.brown--text.text--darken-3 textarea {
  caret-color: #4e342e !important;
}
.brown.darken-4 {
  background-color: #3e2723 !important;
  border-color: #3e2723 !important;
}
.brown.darken-4--after:after {
  background-color: #3e2723 !important;
}
.brown--text.text--darken-4 {
  color: #3e2723 !important;
}
.brown--text.text--darken-4 input,
.brown--text.text--darken-4 textarea {
  caret-color: #3e2723 !important;
}
.blue-grey {
  background-color: #607d8b !important;
  border-color: #607d8b !important;
}
.blue-grey--text {
  color: #607d8b !important;
}
.blue-grey--text input,
.blue-grey--text textarea {
  caret-color: #607d8b !important;
}
.blue-grey--after:after {
  background: #607d8b !important;
}
.blue-grey.lighten-5 {
  background-color: #eceff1 !important;
  border-color: #eceff1 !important;
}
.blue-grey.lighten-5--after:after {
  background-color: #eceff1 !important;
}
.blue-grey--text.text--lighten-5 {
  color: #eceff1 !important;
}
.blue-grey--text.text--lighten-5 input,
.blue-grey--text.text--lighten-5 textarea {
  caret-color: #eceff1 !important;
}
.blue-grey.lighten-4 {
  background-color: #cfd8dc !important;
  border-color: #cfd8dc !important;
}
.blue-grey.lighten-4--after:after {
  background-color: #cfd8dc !important;
}
.blue-grey--text.text--lighten-4 {
  color: #cfd8dc !important;
}
.blue-grey--text.text--lighten-4 input,
.blue-grey--text.text--lighten-4 textarea {
  caret-color: #cfd8dc !important;
}
.blue-grey.lighten-3 {
  background-color: #b0bec5 !important;
  border-color: #b0bec5 !important;
}
.blue-grey.lighten-3--after:after {
  background-color: #b0bec5 !important;
}
.blue-grey--text.text--lighten-3 {
  color: #b0bec5 !important;
}
.blue-grey--text.text--lighten-3 input,
.blue-grey--text.text--lighten-3 textarea {
  caret-color: #b0bec5 !important;
}
.blue-grey.lighten-2 {
  background-color: #90a4ae !important;
  border-color: #90a4ae !important;
}
.blue-grey.lighten-2--after:after {
  background-color: #90a4ae !important;
}
.blue-grey--text.text--lighten-2 {
  color: #90a4ae !important;
}
.blue-grey--text.text--lighten-2 input,
.blue-grey--text.text--lighten-2 textarea {
  caret-color: #90a4ae !important;
}
.blue-grey.lighten-1 {
  background-color: #78909c !important;
  border-color: #78909c !important;
}
.blue-grey.lighten-1--after:after {
  background-color: #78909c !important;
}
.blue-grey--text.text--lighten-1 {
  color: #78909c !important;
}
.blue-grey--text.text--lighten-1 input,
.blue-grey--text.text--lighten-1 textarea {
  caret-color: #78909c !important;
}
.blue-grey.darken-1 {
  background-color: #546e7a !important;
  border-color: #546e7a !important;
}
.blue-grey.darken-1--after:after {
  background-color: #546e7a !important;
}
.blue-grey--text.text--darken-1 {
  color: #546e7a !important;
}
.blue-grey--text.text--darken-1 input,
.blue-grey--text.text--darken-1 textarea {
  caret-color: #546e7a !important;
}
.blue-grey.darken-2 {
  background-color: #455a64 !important;
  border-color: #455a64 !important;
}
.blue-grey.darken-2--after:after {
  background-color: #455a64 !important;
}
.blue-grey--text.text--darken-2 {
  color: #455a64 !important;
}
.blue-grey--text.text--darken-2 input,
.blue-grey--text.text--darken-2 textarea {
  caret-color: #455a64 !important;
}
.blue-grey.darken-3 {
  background-color: #37474f !important;
  border-color: #37474f !important;
}
.blue-grey.darken-3--after:after {
  background-color: #37474f !important;
}
.blue-grey--text.text--darken-3 {
  color: #37474f !important;
}
.blue-grey--text.text--darken-3 input,
.blue-grey--text.text--darken-3 textarea {
  caret-color: #37474f !important;
}
.blue-grey.darken-4 {
  background-color: #263238 !important;
  border-color: #263238 !important;
}
.blue-grey.darken-4--after:after {
  background-color: #263238 !important;
}
.blue-grey--text.text--darken-4 {
  color: #263238 !important;
}
.blue-grey--text.text--darken-4 input,
.blue-grey--text.text--darken-4 textarea {
  caret-color: #263238 !important;
}
.grey {
  background-color: #9e9e9e !important;
  border-color: #9e9e9e !important;
}
.grey--text {
  color: #9e9e9e !important;
}
.grey--text input,
.grey--text textarea {
  caret-color: #9e9e9e !important;
}
.grey--after:after {
  background: #9e9e9e !important;
}
.grey.lighten-5 {
  background-color: #fafafa !important;
  border-color: #fafafa !important;
}
.grey.lighten-5--after:after {
  background-color: #fafafa !important;
}
.grey--text.text--lighten-5 {
  color: #fafafa !important;
}
.grey--text.text--lighten-5 input,
.grey--text.text--lighten-5 textarea {
  caret-color: #fafafa !important;
}
.grey.lighten-4 {
  background-color: #f5f5f5 !important;
  border-color: #f5f5f5 !important;
}
.grey.lighten-4--after:after {
  background-color: #f5f5f5 !important;
}
.grey--text.text--lighten-4 {
  color: #f5f5f5 !important;
}
.grey--text.text--lighten-4 input,
.grey--text.text--lighten-4 textarea {
  caret-color: #f5f5f5 !important;
}
.grey.lighten-3 {
  background-color: #eee !important;
  border-color: #eee !important;
}
.grey.lighten-3--after:after {
  background-color: #eee !important;
}
.grey--text.text--lighten-3 {
  color: #eee !important;
}
.grey--text.text--lighten-3 input,
.grey--text.text--lighten-3 textarea {
  caret-color: #eee !important;
}
.grey.lighten-2 {
  background-color: #e0e0e0 !important;
  border-color: #e0e0e0 !important;
}
.grey.lighten-2--after:after {
  background-color: #e0e0e0 !important;
}
.grey--text.text--lighten-2 {
  color: #e0e0e0 !important;
}
.grey--text.text--lighten-2 input,
.grey--text.text--lighten-2 textarea {
  caret-color: #e0e0e0 !important;
}
.grey.lighten-1 {
  background-color: #bdbdbd !important;
  border-color: #bdbdbd !important;
}
.grey.lighten-1--after:after {
  background-color: #bdbdbd !important;
}
.grey--text.text--lighten-1 {
  color: #bdbdbd !important;
}
.grey--text.text--lighten-1 input,
.grey--text.text--lighten-1 textarea {
  caret-color: #bdbdbd !important;
}
.grey.darken-1 {
  background-color: #757575 !important;
  border-color: #757575 !important;
}
.grey.darken-1--after:after {
  background-color: #757575 !important;
}
.grey--text.text--darken-1 {
  color: #757575 !important;
}
.grey--text.text--darken-1 input,
.grey--text.text--darken-1 textarea {
  caret-color: #757575 !important;
}
.grey.darken-2 {
  background-color: #616161 !important;
  border-color: #616161 !important;
}
.grey.darken-2--after:after {
  background-color: #616161 !important;
}
.grey--text.text--darken-2 {
  color: #616161 !important;
}
.grey--text.text--darken-2 input,
.grey--text.text--darken-2 textarea {
  caret-color: #616161 !important;
}
.grey.darken-3 {
  background-color: #424242 !important;
  border-color: #424242 !important;
}
.grey.darken-3--after:after {
  background-color: #424242 !important;
}
.grey--text.text--darken-3 {
  color: #424242 !important;
}
.grey--text.text--darken-3 input,
.grey--text.text--darken-3 textarea {
  caret-color: #424242 !important;
}
.grey.darken-4 {
  background-color: #212121 !important;
  border-color: #212121 !important;
}
.grey.darken-4--after:after {
  background-color: #212121 !important;
}
.grey--text.text--darken-4 {
  color: #212121 !important;
}
.grey--text.text--darken-4 input,
.grey--text.text--darken-4 textarea {
  caret-color: #212121 !important;
}
.shades.black {
  background-color: #000 !important;
  border-color: #000 !important;
}
.shades.black--after:after {
  background-color: #000 !important;
}
.shades--text.text--black {
  color: #000 !important;
}
.shades--text.text--black input,
.shades--text.text--black textarea {
  caret-color: #000 !important;
}
.shades.white {
  background-color: #fff !important;
  border-color: #fff !important;
}
.shades.white--after:after {
  background-color: #fff !important;
}
.shades--text.text--white {
  color: #fff !important;
}
.shades--text.text--white input,
.shades--text.text--white textarea {
  caret-color: #fff !important;
}
.shades.transparent {
  background-color: transparent !important;
  border-color: transparent !important;
}
.shades.transparent--after:after {
  background-color: transparent !important;
}
.shades--text.text--transparent {
  color: transparent !important;
}
.shades--text.text--transparent input,
.shades--text.text--transparent textarea {
  caret-color: transparent !important;
}
html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow-y: scroll; /* All browsers without overlaying scrollbars */
  -webkit-text-size-adjust: 100%; /* iOS 8+ */
}
*,
::before,
::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}
::before,
::after {
  text-decoration: inherit; /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
  vertical-align: inherit;
}
* {
  background-repeat: no-repeat; /* Set `background-repeat: no-repeat` to all elements */
  padding: 0; /* Reset `padding` and `margin` of all elements */
  margin: 0;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
hr {
  overflow: visible; /* Show the overflow in Edge and IE */
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
summary {
  display: list-item; /* Add the correct display in all browsers */
}
small {
  font-size: 80%; /* Set font-size to 80% in `small` elements */
}
[hidden],
template {
  display: none; /* Add the correct display in IE */
}
abbr[title] {
  border-bottom: 1px dotted; /* Add a bordered underline effect in all browsers */
  text-decoration: none; /* Remove text decoration in Firefox 40+ */
}
a {
  background-color: transparent; /* Remove the gray background on active links in IE 10 */
  -webkit-text-decoration-skip: objects; /* Remove gaps in links underline in iOS 8+ and Safari 8+ */
}
a:active,
a:hover {
  outline-width: 0; /* Remove the outline when hovering in all browsers */
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace; /* Specify the font family of code elements */
}
b,
strong {
  font-weight: bolder; /* Correct style set to `bold` in Edge 12+, Safari 6.2+, and Chrome 18+ */
}
dfn {
  font-style: italic; /* Address styling not present in Safari and Chrome */
}
mark {
  background-color: #ff0;
  color: #000;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
input {
  border-radius: 0;
}
button,
[type="button"],
[type="reset"],
[type="submit"],
[role="button"] {
  cursor: pointer;
}
[disabled] {
  cursor: default;
}
[type="number"] {
  width: auto; /* Firefox 36+ */
}
[type="search"] {
  -webkit-appearance: textfield; /* Safari 8+ */
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; /* Safari 8 */
}
textarea {
  overflow: auto; /* Internet Explorer 11+ */
  resize: vertical; /* Specify textarea resizability */
}
button,
input,
optgroup,
select,
textarea {
  font: inherit; /* Specify font inheritance of form elements */
}
optgroup {
  font-weight: bold; /* Restore the font weight unset by the previous rule. */
}
button {
  overflow: visible; /* Address `overflow` set to `hidden` in IE 8/9/10/11 */
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: 0;
  padding: 0;
}
button:-moz-focusring,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  outline: 0;
  border: 0;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; /* Correct the inability to style clickable types in iOS */
}
button,
select {
  text-transform: none; /* Firefox 40+, Internet Explorer 11- */
}
button,
input,
select,
textarea {
  background-color: transparent;
  border-style: none;
  color: inherit;
}
select {
  -moz-appearance: none; /* Firefox 36+ */
  -webkit-appearance: none; /* Chrome 41+ */
}
select::-ms-expand {
  display: none; /* Internet Explorer 11+ */
}
select::-ms-value {
  color: currentColor; /* Internet Explorer 11+ */
}
legend {
  border: 0; /* Correct `color` not being inherited in IE 8/9/10/11 */
  color: inherit; /* Correct the color inheritance from `fieldset` elements in IE */
  display: table; /* Correct the text wrapping in Edge and IE */
  max-width: 100%; /* Correct the text wrapping in Edge and IE */
  white-space: normal; /* Correct the text wrapping in Edge and IE */
}
::-webkit-file-upload-button {
  -webkit-appearance: button; /* Correct the inability to style clickable types in iOS and Safari */
  font: inherit; /* Change font properties to `inherit` in Chrome and Safari */
}
[type="search"] {
  -webkit-appearance: textfield; /* Correct the odd appearance in Chrome and Safari */
  outline-offset: -2px; /* Correct the outline style in Safari */
}
img {
  border-style: none; /* Remove border when inside `a` element in IE 8/9/10 */
}
progress {
  vertical-align: baseline;
}
svg:not(:root) {
  overflow: hidden; /* Internet Explorer 11- */
}
audio,
canvas,
progress,
video {
  display: inline-block; /* Internet Explorer 11+, Windows Phone 8.1+ */
}
[aria-busy="true"] {
  cursor: progress;
}
[aria-controls] {
  cursor: pointer;
}
[aria-disabled] {
  cursor: default;
}
::-moz-selection {
  background-color: #b3d4fc; /* Required when declaring ::selection */
  color: #000;
  text-shadow: none;
}
::selection {
  background-color: #b3d4fc; /* Required when declaring ::selection */
  color: #000;
  text-shadow: none;
}
.bottom-sheet-transition-enter {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.bottom-sheet-transition-leave-to {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.carousel-transition-enter {
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);
}
.carousel-transition-leave,
.carousel-transition-leave-to {
  position: absolute;
  top: 0;
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
}
.carousel-reverse-transition-enter {
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
}
.carousel-reverse-transition-leave,
.carousel-reverse-transition-leave-to {
  position: absolute;
  top: 0;
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);
}
.dialog-transition-enter,
.dialog-transition-leave-to {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  opacity: 0;
}
.dialog-transition-enter-to,
.dialog-transition-leave {
  opacity: 1;
}
.dialog-bottom-transition-enter,
.dialog-bottom-transition-leave-to {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.tab-transition-enter {
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);
}
.tab-transition-enter-to {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.tab-transition-leave,
.tab-transition-leave-active {
  position: absolute;
  top: 0;
}
.tab-transition-leave-to {
  position: absolute;
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
}
.tab-reverse-transition-enter {
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
}
.tab-reverse-transition-leave,
.tab-reverse-transition-leave-to {
  top: 0;
  position: absolute;
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);
}
.scale-transition-enter-active,
.scale-transition-leave-active {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.scale-transition-enter,
.scale-transition-leave,
.scale-transition-leave-to {
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
}
.slide-y-transition-enter-active,
.slide-y-transition-leave-active {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.slide-y-transition-enter,
.slide-y-transition-leave-to {
  opacity: 0;
  -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
}
.slide-y-reverse-transition-enter-active,
.slide-y-reverse-transition-leave-active {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.slide-y-reverse-transition-enter,
.slide-y-reverse-transition-leave-to {
  opacity: 0;
  -webkit-transform: translateY(15px);
          transform: translateY(15px);
}
.slide-x-transition-enter-active,
.slide-x-transition-leave-active {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.slide-x-transition-enter,
.slide-x-transition-leave-to {
  opacity: 0;
  -webkit-transform: translateX(-15px);
          transform: translateX(-15px);
}
.slide-x-reverse-transition-enter-active,
.slide-x-reverse-transition-leave-active {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.slide-x-reverse-transition-enter,
.slide-x-reverse-transition-leave-to {
  opacity: 0;
  -webkit-transform: translateX(15px);
          transform: translateX(15px);
}
.fade-transition-enter-active,
.fade-transition-leave-active {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.fade-transition-enter,
.fade-transition-leave-to {
  opacity: 0;
}
.fab-transition-enter-active,
.fab-transition-leave-active {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.fab-transition-enter,
.fab-transition-leave-to {
  -webkit-transform: scale(0) rotate(-45deg);
          transform: scale(0) rotate(-45deg);
}
.blockquote {
  padding: 16px 0 16px 24px;
  font-size: 18px;
  font-weight: 300;
}
code,
kbd {
  display: inline-block;
  border-radius: 3px;
  white-space: pre-wrap;
  font-size: 85%;
  font-weight: 900;
}
code:after,
kbd:after,
code:before,
kbd:before {
  content: "\A0";
  letter-spacing: -1px;
}
code {
  background-color: #f5f5f5;
  color: #bd4147;
  -webkit-box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
kbd {
  background: #424242;
  color: #fff;
}
html {
  font-size: 14px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  overflow-x: hidden;
}
.application {
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
}
::-ms-clear,
::-ms-reveal {
  display: none;
}
.browser-list {
  padding-left: 24px;
}
.browser-list--unstyled {
  list-style-type: none;
}
.display-4 {
  font-size: 112px !important;
  font-weight: 300;
  line-height: 1 !important;
  letter-spacing: -0.04em !important;
}
.display-3 {
  font-size: 56px !important;
  font-weight: 400;
  line-height: 1.35 !important;
  letter-spacing: -0.02em !important;
}
.display-2 {
  font-size: 45px !important;
  font-weight: 400;
  line-height: 48px !important;
  letter-spacing: normal !important;
}
.display-1 {
  font-size: 34px !important;
  font-weight: 400;
  line-height: 40px !important;
  letter-spacing: normal !important;
}
.headline {
  font-size: 24px !important;
  font-weight: 400;
  line-height: 32px !important;
  letter-spacing: normal !important;
}
.title {
  font-size: 20px !important;
  font-weight: 500;
  line-height: 1 !important;
  letter-spacing: 0.02em !important;
}
.subheading {
  font-size: 16px !important;
  font-weight: 400;
}
.body-2 {
  font-size: 14px !important;
  font-weight: 500;
}
.body-1 {
  font-size: 14px !important;
  font-weight: 400;
}
.caption {
  font-size: 12px !important;
  font-weight: 400;
}
.btn {
  font-size: 14px;
  font-weight: 500;
}
p {
  margin-bottom: 16px;
}
@-webkit-keyframes shake {
  59% {
    margin-left: 0;
  }
  60%, 80% {
    margin-left: 2px;
  }
  70%, 90% {
    margin-left: -2px;
  }
}
@keyframes shake {
  59% {
    margin-left: 0;
  }
  60%, 80% {
    margin-left: 2px;
  }
  70%, 90% {
    margin-left: -2px;
  }
}
.elevation-0 {
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12) !important;
}
.elevation-1 {
  -webkit-box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
}
.elevation-2 {
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important;
}
.elevation-3 {
  -webkit-box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12) !important;
}
.elevation-4 {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12) !important;
}
.elevation-5 {
  -webkit-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12) !important;
}
.elevation-6 {
  -webkit-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important;
}
.elevation-7 {
  -webkit-box-shadow: 0px 4px 5px -2px rgba(0,0,0,0.2), 0px 7px 10px 1px rgba(0,0,0,0.14), 0px 2px 16px 1px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 4px 5px -2px rgba(0,0,0,0.2), 0px 7px 10px 1px rgba(0,0,0,0.14), 0px 2px 16px 1px rgba(0,0,0,0.12) !important;
}
.elevation-8 {
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important;
}
.elevation-9 {
  -webkit-box-shadow: 0px 5px 6px -3px rgba(0,0,0,0.2), 0px 9px 12px 1px rgba(0,0,0,0.14), 0px 3px 16px 2px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 5px 6px -3px rgba(0,0,0,0.2), 0px 9px 12px 1px rgba(0,0,0,0.14), 0px 3px 16px 2px rgba(0,0,0,0.12) !important;
}
.elevation-10 {
  -webkit-box-shadow: 0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12) !important;
}
.elevation-11 {
  -webkit-box-shadow: 0px 6px 7px -4px rgba(0,0,0,0.2), 0px 11px 15px 1px rgba(0,0,0,0.14), 0px 4px 20px 3px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 6px 7px -4px rgba(0,0,0,0.2), 0px 11px 15px 1px rgba(0,0,0,0.14), 0px 4px 20px 3px rgba(0,0,0,0.12) !important;
}
.elevation-12 {
  -webkit-box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12) !important;
}
.elevation-13 {
  -webkit-box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 13px 19px 2px rgba(0,0,0,0.14), 0px 5px 24px 4px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 13px 19px 2px rgba(0,0,0,0.14), 0px 5px 24px 4px rgba(0,0,0,0.12) !important;
}
.elevation-14 {
  -webkit-box-shadow: 0px 7px 9px -4px rgba(0,0,0,0.2), 0px 14px 21px 2px rgba(0,0,0,0.14), 0px 5px 26px 4px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 7px 9px -4px rgba(0,0,0,0.2), 0px 14px 21px 2px rgba(0,0,0,0.14), 0px 5px 26px 4px rgba(0,0,0,0.12) !important;
}
.elevation-15 {
  -webkit-box-shadow: 0px 8px 9px -5px rgba(0,0,0,0.2), 0px 15px 22px 2px rgba(0,0,0,0.14), 0px 6px 28px 5px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 8px 9px -5px rgba(0,0,0,0.2), 0px 15px 22px 2px rgba(0,0,0,0.14), 0px 6px 28px 5px rgba(0,0,0,0.12) !important;
}
.elevation-16 {
  -webkit-box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12) !important;
}
.elevation-17 {
  -webkit-box-shadow: 0px 8px 11px -5px rgba(0,0,0,0.2), 0px 17px 26px 2px rgba(0,0,0,0.14), 0px 6px 32px 5px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 8px 11px -5px rgba(0,0,0,0.2), 0px 17px 26px 2px rgba(0,0,0,0.14), 0px 6px 32px 5px rgba(0,0,0,0.12) !important;
}
.elevation-18 {
  -webkit-box-shadow: 0px 9px 11px -5px rgba(0,0,0,0.2), 0px 18px 28px 2px rgba(0,0,0,0.14), 0px 7px 34px 6px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 9px 11px -5px rgba(0,0,0,0.2), 0px 18px 28px 2px rgba(0,0,0,0.14), 0px 7px 34px 6px rgba(0,0,0,0.12) !important;
}
.elevation-19 {
  -webkit-box-shadow: 0px 9px 12px -6px rgba(0,0,0,0.2), 0px 19px 29px 2px rgba(0,0,0,0.14), 0px 7px 36px 6px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 9px 12px -6px rgba(0,0,0,0.2), 0px 19px 29px 2px rgba(0,0,0,0.14), 0px 7px 36px 6px rgba(0,0,0,0.12) !important;
}
.elevation-20 {
  -webkit-box-shadow: 0px 10px 13px -6px rgba(0,0,0,0.2), 0px 20px 31px 3px rgba(0,0,0,0.14), 0px 8px 38px 7px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 10px 13px -6px rgba(0,0,0,0.2), 0px 20px 31px 3px rgba(0,0,0,0.14), 0px 8px 38px 7px rgba(0,0,0,0.12) !important;
}
.elevation-21 {
  -webkit-box-shadow: 0px 10px 13px -6px rgba(0,0,0,0.2), 0px 21px 33px 3px rgba(0,0,0,0.14), 0px 8px 40px 7px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 10px 13px -6px rgba(0,0,0,0.2), 0px 21px 33px 3px rgba(0,0,0,0.14), 0px 8px 40px 7px rgba(0,0,0,0.12) !important;
}
.elevation-22 {
  -webkit-box-shadow: 0px 10px 14px -6px rgba(0,0,0,0.2), 0px 22px 35px 3px rgba(0,0,0,0.14), 0px 8px 42px 7px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 10px 14px -6px rgba(0,0,0,0.2), 0px 22px 35px 3px rgba(0,0,0,0.14), 0px 8px 42px 7px rgba(0,0,0,0.12) !important;
}
.elevation-23 {
  -webkit-box-shadow: 0px 11px 14px -7px rgba(0,0,0,0.2), 0px 23px 36px 3px rgba(0,0,0,0.14), 0px 9px 44px 8px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 11px 14px -7px rgba(0,0,0,0.2), 0px 23px 36px 3px rgba(0,0,0,0.14), 0px 9px 44px 8px rgba(0,0,0,0.12) !important;
}
.elevation-24 {
  -webkit-box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12) !important;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.right {
  float: right !important;
}
.left {
  float: left !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.ma-0 {
  margin: 0 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.pa-0 {
  padding: 0 0 !important;
}
.mt-1 {
  margin-top: 4px !important;
}
.mr-1 {
  margin-right: 4px !important;
}
.mb-1 {
  margin-bottom: 4px !important;
}
.ml-1 {
  margin-left: 4px !important;
}
.mx-1 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.my-1 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.ma-1 {
  margin: 4px 4px !important;
}
.pt-1 {
  padding-top: 4px !important;
}
.pr-1 {
  padding-right: 4px !important;
}
.pb-1 {
  padding-bottom: 4px !important;
}
.pl-1 {
  padding-left: 4px !important;
}
.px-1 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.pa-1 {
  padding: 4px 4px !important;
}
.mt-2 {
  margin-top: 8px !important;
}
.mr-2 {
  margin-right: 8px !important;
}
.mb-2 {
  margin-bottom: 8px !important;
}
.ml-2 {
  margin-left: 8px !important;
}
.mx-2 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.ma-2 {
  margin: 8px 8px !important;
}
.pt-2 {
  padding-top: 8px !important;
}
.pr-2 {
  padding-right: 8px !important;
}
.pb-2 {
  padding-bottom: 8px !important;
}
.pl-2 {
  padding-left: 8px !important;
}
.px-2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.pa-2 {
  padding: 8px 8px !important;
}
.mt-3 {
  margin-top: 16px !important;
}
.mr-3 {
  margin-right: 16px !important;
}
.mb-3 {
  margin-bottom: 16px !important;
}
.ml-3 {
  margin-left: 16px !important;
}
.mx-3 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}
.my-3 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.ma-3 {
  margin: 16px 16px !important;
}
.pt-3 {
  padding-top: 16px !important;
}
.pr-3 {
  padding-right: 16px !important;
}
.pb-3 {
  padding-bottom: 16px !important;
}
.pl-3 {
  padding-left: 16px !important;
}
.px-3 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.py-3 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.pa-3 {
  padding: 16px 16px !important;
}
.mt-4 {
  margin-top: 24px !important;
}
.mr-4 {
  margin-right: 24px !important;
}
.mb-4 {
  margin-bottom: 24px !important;
}
.ml-4 {
  margin-left: 24px !important;
}
.mx-4 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}
.my-4 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}
.ma-4 {
  margin: 24px 24px !important;
}
.pt-4 {
  padding-top: 24px !important;
}
.pr-4 {
  padding-right: 24px !important;
}
.pb-4 {
  padding-bottom: 24px !important;
}
.pl-4 {
  padding-left: 24px !important;
}
.px-4 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.py-4 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.pa-4 {
  padding: 24px 24px !important;
}
.mt-5 {
  margin-top: 48px !important;
}
.mr-5 {
  margin-right: 48px !important;
}
.mb-5 {
  margin-bottom: 48px !important;
}
.ml-5 {
  margin-left: 48px !important;
}
.mx-5 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}
.my-5 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}
.ma-5 {
  margin: 48px 48px !important;
}
.pt-5 {
  padding-top: 48px !important;
}
.pr-5 {
  padding-right: 48px !important;
}
.pb-5 {
  padding-bottom: 48px !important;
}
.pl-5 {
  padding-left: 48px !important;
}
.px-5 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}
.py-5 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}
.pa-5 {
  padding: 48px 48px !important;
}
.tooltip {
  position: relative;
}
.tooltip__content {
  background: #616161;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  padding: 5px 8px;
  position: absolute;
  text-transform: initial;
  -webkit-transition: 0.15s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.15s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: auto;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.tooltip__content[class*="-active"] {
  pointer-events: none;
}
@media screen {
  [hidden~="screen"] {
    display: inherit;
  }
  [hidden~="screen"]:not(:active):not(:focus):not(:target) {
    position: absolute !important;
    clip: rect(0 0 0 0) !important;
  }
}
@media only screen and (max-width: 599px) {
  .hidden-xs-only {
    display: none !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 959px) {
  .hidden-sm-only {
    display: none !important;
  }
}
@media only screen and (max-width: 959px) {
  .hidden-sm-and-down {
    display: none !important;
  }
  .tooltip .tooltip__content {
    padding: 10px 16px;
  }
}
@media only screen and (min-width: 600px) {
  .hidden-sm-and-up {
    display: none !important;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1263px) {
  .hidden-md-only {
    display: none !important;
  }
}
@media only screen and (max-width: 1263px) {
  .hidden-md-and-down {
    display: none !important;
  }
}
@media only screen and (min-width: 960px) {
  .hidden-md-and-up {
    display: none !important;
  }
}
@media only screen and (min-width: 1264px) and (max-width: 1903px) {
  .hidden-lg-only {
    display: none !important;
  }
}
@media only screen and (max-width: 1903px) {
  .hidden-lg-and-down {
    display: none !important;
  }
}
@media only screen and (min-width: 1264px) {
  .hidden-lg-and-up {
    display: none !important;
  }
}
@media only screen and (min-width: 1904px) {
  .hidden-xl-only {
    display: none !important;
  }
}
@media all and (min-width: 0) {
  .text-xs-left {
    text-align: left !important;
  }
  .text-xs-center {
    text-align: center !important;
  }
  .text-xs-right {
    text-align: right !important;
  }
  .text-xs-justify {
    text-align: justify !important;
  }
}
@media all and (min-width: 600px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-justify {
    text-align: justify !important;
  }
}
@media all and (min-width: 960px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-center {
    text-align: center !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-justify {
    text-align: justify !important;
  }
}
@media all and (min-width: 1264px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-justify {
    text-align: justify !important;
  }
}
@media all and (min-width: 1904px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-justify {
    text-align: justify !important;
  }
}
.application {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.application--wrap {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  position: relative;
}
.application--wrap > main:not(.content) {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.application.theme--light {
  background: #fafafa;
  color: rgba(0,0,0,0.87);
}
.application.theme--light a {
  cursor: pointer;
}
.application.theme--dark {
  background: #303030;
  color: #fff;
}
.application.theme--dark a {
  cursor: pointer;
}
@-moz-document url-prefix() {
@media print {
    .application {
      display: block;
    }
    .application--wrap {
      display: block;
    }
}
}
.alert {
  border-radius: 0;
  border-width: 4px 0 0 0;
  border-style: solid;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  margin: 4px auto;
  padding: 16px;
  position: relative;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.alert .alert__icon.icon,
.alert__dismissible .icon {
  -ms-flex-item-align: center;
      align-self: center;
  color: rgba(0,0,0,0.3);
  font-size: 24px;
}
.alert--outline .icon {
  color: inherit !important;
}
.alert__icon {
  margin-right: 16px;
}
.alert__dismissible {
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-left: 16px;
  margin-right: 0;
  text-decoration: none;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.alert__dismissible:hover {
  color: rgba(26,26,26,0.3);
}
.alert--no-icon .alert__icon {
  display: none;
}
.alert > div {
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
}
.alert.alert {
  border-color: rgba(0,0,0,0.12) !important;
}
.alert.alert--outline {
  border: 1px solid currentColor !important;
}
@media screen and (max-width: 600px) {
  .alert__icon {
    display: none;
  }
}
/* Themes */
.theme--light .icon,
.application .theme--light.icon {
  color: rgba(0,0,0,0.54);
}
.theme--light .icon.icon--disabled:not(.input-group__append-icon),
.application .theme--light.icon.icon--disabled:not(.input-group__append-icon) {
  color: rgba(0,0,0,0.38) !important;
}
.theme--dark .icon,
.application .theme--dark.icon {
  color: #fff;
}
.theme--dark .icon.icon--disabled:not(.input-group__append-icon),
.application .theme--dark.icon.icon--disabled:not(.input-group__append-icon) {
  color: rgba(255,255,255,0.5) !important;
}
.icon {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 24px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  vertical-align: middle;
}
.icon.icon--large {
  font-size: 2.5rem;
}
.icon.icon--medium {
  font-size: 2rem;
}
.icon.icon--x-large {
  font-size: 3rem;
}
.icon.icon--disabled {
  cursor: default;
}
.avatar {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  vertical-align: middle;
}
.avatar img,
.avatar .icon {
  border-radius: 50%;
  height: inherit;
  width: inherit;
}
.avatar--tile {
  border-radius: 0;
}
.avatar--tile img,
.avatar--tile .icon {
  border-radius: 0;
}
.badge {
  display: inline-block;
  position: relative;
}
.badge__badge {
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  font-size: 14px;
  top: -11px;
  right: -22px;
  border-radius: 50%;
  height: 22px;
  width: 22px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.badge__badge .icon {
  font-size: 14px;
}
.badge--overlap .badge__badge {
  top: -8px;
  right: -8px;
}
.badge--overlap.badge--left .badge__badge {
  left: -8px;
  right: initial;
}
.badge--overlap.badge--bottom .badge__badge {
  bottom: -8px;
  top: initial;
}
.badge--left .badge__badge {
  left: -22px;
}
.badge--bottom .badge__badge {
  bottom: -11px;
  top: initial;
}
.bottom-nav {
  bottom: 0;
  -webkit-box-shadow: 0 3px 14px 2px rgba(0,0,0,0.12);
          box-shadow: 0 3px 14px 2px rgba(0,0,0,0.12);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 56px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: fixed;
  -webkit-transform: translate(0, 60px);
          transform: translate(0, 60px);
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
  z-index: 4;
}
.bottom-nav--absolute {
  position: absolute;
}
.bottom-nav--active {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.bottom-nav .btn {
  background: transparent !important;
  border-radius: 0;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  font-weight: 400;
  height: 100%;
  margin: 0;
  max-width: 168px;
  min-width: 80px;
  padding: 0px;
  text-transform: none;
  opacity: 0.5;
  width: 100%;
}
.bottom-nav .btn .btn__content {
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  height: 56px;
  font-size: 12px;
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  white-space: nowrap;
  will-change: font-size;
}
.bottom-nav .btn .btn__content i.icon {
  color: inherit;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.bottom-nav .btn--active {
  opacity: 1;
}
.bottom-nav .btn--active .btn__content {
  font-size: 14px;
}
.bottom-nav .btn--active .btn__content:before {
  opacity: 0;
}
.bottom-nav .btn--active .btn__content .icon {
  -webkit-transform: none;
          transform: none;
}
.bottom-nav .btn:not(.btn--active) {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
.bottom-nav--shift .btn__content {
  font-size: 14px;
}
.bottom-nav--shift .btn__content span {
  height: 21px;
}
.bottom-nav--shift .btn {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  min-width: 56px;
  max-width: 96px;
}
.bottom-nav--shift .btn--active {
  min-width: 96px;
  max-width: 168px;
}
.bottom-nav--shift .btn:not(.btn--active) .btn__content .icon {
  -webkit-transform: scale(1, 1) translate(0, 10px);
          transform: scale(1, 1) translate(0, 10px);
}
.bottom-nav--shift .btn:not(.btn--active) .btn__content span {
  color: transparent;
}
.bottom-sheet.dialog {
  -ms-flex-item-align: end;
      align-self: flex-end;
  border-radius: 0;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
  margin: 0;
  min-width: 100%;
  overflow: visible;
  -webkit-transition: 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.bottom-sheet.dialog.bottom-sheet--inset {
  max-width: 70%;
  min-width: 0;
}
@media only screen and (max-width: 599px) {
  .bottom-sheet.dialog.bottom-sheet--inset {
    max-width: none;
  }
}
.dialog {
  -webkit-box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
          box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
  border-radius: 2px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  width: 100%;
}
.dialog__content {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  width: 100%;
  z-index: 6;
  outline: none;
}
.dialog:not(.dialog--fullscreen) {
  max-height: 90%;
}
.dialog__container {
  display: inline-block;
  vertical-align: middle;
}
.dialog--fullscreen {
  margin: 0;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
}
.dialog--fullscreen > .card {
  min-height: 100%;
  min-width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}
.dialog--scrollable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dialog--scrollable > .card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.dialog--scrollable > .card > .card__title,
.dialog--scrollable > .card > .card__actions {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}
.dialog--scrollable > .card > .card__text {
  overflow-y: auto;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  -webkit-transition: 0.5s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.5s cubic-bezier(0.25, 0.8, 0.5, 1);
  z-index: 5;
}
.overlay--absolute {
  position: absolute;
}
.overlay:before {
  background-color: #212121;
  bottom: 0;
  content: '';
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: inherit;
  transition: inherit;
  -webkit-transition-delay: 150ms;
          transition-delay: 150ms;
  width: 100%;
}
.overlay--active {
  pointer-events: auto;
  -ms-touch-action: none;
      touch-action: none;
}
.overlay--active:before {
  opacity: 0.46;
}
.theme--light .breadcrumbs li.breadcrumbs__divider,
.application .theme--light.breadcrumbs li.breadcrumbs__divider,
.theme--light .breadcrumbs li:last-child .breadcrumbs__item,
.application .theme--light.breadcrumbs li:last-child .breadcrumbs__item,
.theme--light .breadcrumbs li .breadcrumbs__item--disabled,
.application .theme--light.breadcrumbs li .breadcrumbs__item--disabled {
  color: rgba(0,0,0,0.38);
}
.theme--dark .breadcrumbs li.breadcrumbs__divider,
.application .theme--dark.breadcrumbs li.breadcrumbs__divider,
.theme--dark .breadcrumbs li:last-child .breadcrumbs__item,
.application .theme--dark.breadcrumbs li:last-child .breadcrumbs__item,
.theme--dark .breadcrumbs li .breadcrumbs__item--disabled,
.application .theme--dark.breadcrumbs li .breadcrumbs__item--disabled {
  color: rgba(255,255,255,0.5);
}
.breadcrumbs {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  list-style-type: none;
  margin: 0;
  padding: 18px 12px;
}
.breadcrumbs li {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
}
.breadcrumbs li .icon {
  font-size: 16px;
}
.breadcrumbs li:last-child a {
  cursor: default;
  pointer-events: none;
}
.breadcrumbs li:nth-child(even) {
  padding: 0 12px;
}
.breadcrumbs--large li {
  font-size: 16px;
}
.breadcrumbs--large li .icon {
  font-size: 16px;
}
.breadcrumbs__item {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-decoration: none;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.breadcrumbs__item--disabled {
  pointer-events: none;
}
.ripple__container {
  color: inherit;
  border-radius: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 0;
  pointer-events: none;
  contain: strict;
}
.ripple__animation {
  color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background: currentColor;
  opacity: 0;
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.2, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.2, 1);
  pointer-events: none;
  overflow: hidden;
  will-change: transform, opacity;
}
.ripple__animation--enter {
  -webkit-transition: none;
  transition: none;
}
.ripple__animation--visible {
  opacity: 0.15;
}
.theme--light .btn,
.application .theme--light.btn {
  color: rgba(0,0,0,0.87);
}
.theme--light .btn.btn--disabled,
.application .theme--light.btn.btn--disabled {
  color: rgba(0,0,0,0.26) !important;
}
.theme--light .btn.btn--disabled .icon,
.application .theme--light.btn.btn--disabled .icon {
  color: rgba(0,0,0,0.26) !important;
}
.theme--light .btn.btn--disabled:not(.btn--icon):not(.btn--flat),
.application .theme--light.btn.btn--disabled:not(.btn--icon):not(.btn--flat) {
  background-color: rgba(0,0,0,0.12) !important;
}
.theme--light .btn:not(.btn--icon):not(.btn--flat),
.application .theme--light.btn:not(.btn--icon):not(.btn--flat) {
  background-color: #f5f5f5;
}
.theme--dark .btn,
.application .theme--dark.btn {
  color: #fff;
}
.theme--dark .btn.btn--disabled,
.application .theme--dark.btn.btn--disabled {
  color: rgba(255,255,255,0.3) !important;
}
.theme--dark .btn.btn--disabled .icon,
.application .theme--dark.btn.btn--disabled .icon {
  color: rgba(255,255,255,0.3) !important;
}
.theme--dark .btn.btn--disabled:not(.btn--icon):not(.btn--flat),
.application .theme--dark.btn.btn--disabled:not(.btn--icon):not(.btn--flat) {
  background-color: rgba(255,255,255,0.12) !important;
}
.theme--dark .btn:not(.btn--icon):not(.btn--flat),
.application .theme--dark.btn:not(.btn--icon):not(.btn--flat) {
  background-color: #212121;
}
/** Base Spec */
.btn {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 2px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 36px;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  font-size: 14px;
  font-weight: 500;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 6px 8px;
  min-width: 88px;
  outline: 0;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  position: relative;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
/** Psuedo */
.btn__content:before {
  border-radius: inherit;
  color: inherit;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  opacity: 0.12;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
/** Content */
.btn--active .btn__content:before,
.btn:hover .btn__content:before,
.btn:focus .btn__content:before {
  background-color: currentColor;
}
.btn__content {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: inherit;
  color: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
  padding: 0 16px;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
}
/** Icons */
.btn .btn__content .icon {
  color: inherit;
}
/** Types */
.btn--flat {
  background-color: transparent !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.btn:not(.btn--depressed) {
  will-change: box-shadow;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.btn:not(.btn--depressed):active {
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
          box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}
.btn--icon {
  background: transparent;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border-radius: 50%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 36px;
  width: 36px;
  min-width: 0;
}
.btn--icon .btn__content {
  padding: 0;
}
.btn--icon .btn__content:before {
  border-radius: 50%;
}
.btn--icon.btn--small {
  width: 28px;
}
.btn--icon.btn--small .btn__content {
  height: 28px;
}
.btn--icon.btn--large {
  width: 44px;
}
.btn--icon.btn--large .btn__content {
  height: 44px;
}
.btn--floating {
  border-radius: 50%;
  min-width: 0;
  height: 56px;
  width: 56px;
  padding: 0;
  -webkit-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
}
.btn--floating.btn--fixed,
.btn--floating.btn--absolute {
  z-index: 4;
}
.btn--floating:active {
  -webkit-box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12);
          box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12);
}
.btn--floating .btn__content {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  margin: 0;
  padding: 0;
}
.btn--floating:after {
  border-radius: 50%;
}
.btn--floating .btn__content :not(:only-child) {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.btn--floating .btn__content :not(:only-child):last-child {
  opacity: 0;
  position: absolute;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  left: calc(50% - 12px);
  top: calc(50% - 12px);
}
.btn--floating.btn--active .btn__content :not(:only-child):first-child {
  opacity: 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  left: calc(50% - 12);
  top: calc(50% - 12);
}
.btn--floating.btn--active .btn__content :not(:only-child):last-child {
  opacity: 1;
  -webkit-transform: none;
          transform: none;
}
.btn--floating .icon {
  height: 24px;
  width: 24px;
}
.btn--floating.btn--small {
  height: 40px;
  width: 40px;
}
.btn--floating.btn--small .icon {
  font-size: 18px;
  height: 18px;
  width: 18px;
}
.btn--floating.btn--small.btn--floating .icon {
  left: calc(50% - 9px);
  top: calc(50% - 9px);
}
.btn--floating.btn--large {
  height: 72px;
  width: 72px;
}
.btn--floating.btn--large .icon {
  font-size: 30px;
  height: 30px;
  width: 30px;
}
.btn--floating.btn--large.btn--floating .icon {
  left: calc(50% - 15px);
  top: calc(50% - 15px);
}
.btn--reverse .btn__content {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.btn--reverse.btn--column .btn__content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
.btn--fixed,
.btn--absolute {
  margin: 0;
}
.btn.btn--absolute {
  position: absolute;
}
.btn.btn--fixed {
  position: fixed;
}
.btn--top:not(.btn--absolute) {
  top: 16px;
}
.btn--top.btn--absolute {
  top: -28px;
}
.btn--top.btn--absolute.btn--small {
  top: -20px;
}
.btn--top.btn--absolute.btn--large {
  top: -36px;
}
.btn--bottom:not(.btn--absolute) {
  bottom: 16px;
}
.btn--bottom.btn--absolute {
  bottom: -28px;
}
.btn--bottom.btn--absolute.btn--small {
  bottom: -20px;
}
.btn--bottom.btn--absolute.btn--large {
  bottom: -36px;
}
.btn--left {
  left: 16px;
}
.btn--right {
  right: 16px;
}
/** Disabled */
.btn.btn--disabled {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  pointer-events: none;
}
/** Sizes */
.btn--small {
  font-size: 13px;
  height: 28px;
}
.btn--small .btn__content {
  padding: 0 8px;
}
.btn--large {
  font-size: 15px;
  height: 44px;
}
.btn--large .btn__content {
  padding: 0 32px;
}
/** Loader */
.btn--loader {
  pointer-events: none;
}
.btn--loader .btn__content {
  opacity: 0;
}
.btn__loading {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.btn__loading .icon--left {
  margin-right: 1rem;
  line-height: inherit;
}
.btn__loading .icon--right {
  margin-left: 1rem;
  line-height: inherit;
}
/** Custom Buttons */
.btn.btn--outline {
  border: 1px solid currentColor;
  background: transparent !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.btn.btn--outline:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.btn--block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin: 6px 0;
  width: 100%;
}
.btn--round {
  border-radius: 28px;
}
.btn--round:after {
  border-radius: 28px;
}
/** Button w/ directional Icon */
.btn .icon--right {
  margin-left: 16px;
}
.btn .icon--left {
  margin-right: 16px;
}
/** Themes */
.btn.primary,
.btn.secondary,
.btn.success,
.btn.error,
.btn.warning,
.btn.info {
  color: #fff;
}
/** Themes */
.theme--light .btn-toggle,
.application .theme--light.btn-toggle {
  background: #fff;
}
.theme--light .btn-toggle .btn,
.application .theme--light.btn-toggle .btn {
  color: rgba(0,0,0,0.87);
}
.theme--light .btn-toggle .btn[data-selected]:not(:last-child):not([data-only-child]),
.application .theme--light.btn-toggle .btn[data-selected]:not(:last-child):not([data-only-child]) {
  border-right-color: rgba(0,0,0,0.26);
}
.theme--dark .btn-toggle,
.application .theme--dark.btn-toggle {
  background: #424242;
}
.theme--dark .btn-toggle .btn,
.application .theme--dark.btn-toggle .btn {
  color: #fff;
}
.theme--dark .btn-toggle .btn[data-selected]:not(:last-child):not([data-only-child]),
.application .theme--dark.btn-toggle .btn[data-selected]:not(:last-child):not([data-only-child]) {
  border-right-color: rgba(255,255,255,0.3);
}
.btn-toggle {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 2px;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  will-change: background, box-shadow;
}
.btn-toggle .btn {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-width: auto;
  width: auto;
  padding: 0 8px;
  margin: 0;
  opacity: 0.4;
  border-radius: 0;
}
.btn-toggle .btn:not(:last-child) {
  border-right: 1px solid transparent;
}
.btn-toggle .btn:after {
  display: none;
}
.btn-toggle .btn[data-selected] {
  opacity: 1;
}
.btn-toggle .btn__content {
  padding: 0;
}
.btn-toggle .btn span + .icon {
  font-size: initial;
  margin-left: 10px;
}
.btn-toggle .btn:first-child {
  border-radius: 2px 0 0 2px;
}
.btn-toggle .btn:last-child {
  border-radius: 0 2px 2px 0;
}
.btn-toggle--selected {
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
/* Themes */
.theme--light .card,
.application .theme--light.card {
  background-color: #fff;
  color: rgba(0,0,0,0.87);
}
.theme--dark .card,
.application .theme--dark.card {
  background-color: #424242;
  color: #fff;
}
.card {
  display: block;
  border-radius: 2px;
  min-width: 0;
  position: relative;
  text-decoration: none;
  -webkit-box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
.card > *:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.card > *:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.card--raised {
  -webkit-box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
}
.card--tile {
  border-radius: 0;
}
.card--flat {
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
}
.card--hover {
  cursor: pointer;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -webkit-transition-property: -webkit-box-shadow;
  transition-property: -webkit-box-shadow;
  transition-property: box-shadow;
  transition-property: box-shadow, -webkit-box-shadow;
}
.card--hover:hover {
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
          box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}
.card__title {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 16px;
}
.card__title--primary {
  padding-top: 24px;
}
.card__text {
  padding: 16px;
  width: 100%;
}
.card__media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  position: relative;
}
.card__media img {
  width: 100%;
}
.card__media__background {
  border-radius: inherit;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.card__media__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  position: relative;
}
.card__actions {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 4px;
}
.card__actions > *,
.card__actions .btn {
  margin: 0 4px;
}
.carousel {
  height: 500px;
  width: 100%;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.carousel__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}
.carousel__left,
.carousel__right {
  position: absolute;
  top: 50%;
  z-index: 1;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.carousel__left .btn,
.carousel__right .btn {
  color: #fff;
  margin: 0 !important;
  height: auto;
  width: auto;
}
.carousel__left .btn i,
.carousel__right .btn i {
  font-size: 48px;
}
.carousel__left .btn:hover,
.carousel__right .btn:hover {
  background: none;
}
.carousel__left {
  left: 5px;
}
.carousel__right {
  right: 5px;
}
.carousel__controls {
  background: rgba(0,0,0,0.5);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 0;
  position: absolute;
  height: 50px;
  list-style-type: none;
  width: 100%;
  z-index: 1;
}
.carousel__controls__item {
  color: #fff;
  margin: 0 1rem !important;
}
.carousel__controls__item i {
  opacity: 0.5;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.carousel__controls__item--active i {
  opacity: 1;
  vertical-align: middle;
  font-size: 2rem !important;
}
.carousel__controls__item:hover {
  background: none;
}
.carousel__controls__item:hover i {
  opacity: 0.8;
}
/* Theme */
.theme--light .input-group input,
.application .theme--light.input-group input,
.theme--light .input-group textarea,
.application .theme--light.input-group textarea {
  color: rgba(0,0,0,0.87);
}
.theme--light .input-group input:disabled,
.application .theme--light.input-group input:disabled,
.theme--light .input-group textarea:disabled,
.application .theme--light.input-group textarea:disabled {
  color: rgba(0,0,0,0.38);
}
.theme--light .input-group:not(.input-group--error) .input-group__messages,
.application .theme--light.input-group:not(.input-group--error) .input-group__messages {
  color: rgba(0,0,0,0.54);
}
.theme--light .input-group.input-group--textarea:not(.input-group--full-width) .input-group__input,
.application .theme--light.input-group.input-group--textarea:not(.input-group--full-width) .input-group__input {
  border: 2px solid rgba(0,0,0,0.54);
}
.theme--light .input-group.input-group--dirty .input-group__selections__comma:not(.input-group__selections__comma--active),
.application .theme--light.input-group.input-group--dirty .input-group__selections__comma:not(.input-group__selections__comma--active) {
  color: rgba(0,0,0,0.87);
}
.theme--light .input-group:not(.input-group--error) label,
.application .theme--light.input-group:not(.input-group--error) label {
  color: rgba(0,0,0,0.54);
}
.theme--light .input-group:not(.input-group--error).input-group--disabled label,
.application .theme--light.input-group:not(.input-group--error).input-group--disabled label,
.theme--light .input-group:not(.input-group--error).input-group--disabled .input-group__selections__comma,
.application .theme--light.input-group:not(.input-group--error).input-group--disabled .input-group__selections__comma {
  color: rgba(0,0,0,0.38);
}
.theme--light .input-group:not(.input-group--error) .input-group__details:before,
.application .theme--light.input-group:not(.input-group--error) .input-group__details:before {
  background-color: rgba(0,0,0,0.42);
}
.theme--light .input-group:not(.input-group--error):not(.input-group--focused):not(.input-group--disabled) .input-group__input .input-group__append-icon:not(:hover),
.application .theme--light.input-group:not(.input-group--error):not(.input-group--focused):not(.input-group--disabled) .input-group__input .input-group__append-icon:not(:hover),
.theme--light .input-group:not(.input-group--error):not(.input-group--focused):not(.input-group--disabled) .input-group__input .input-group__prepend-icon:not(:hover),
.application .theme--light.input-group:not(.input-group--error):not(.input-group--focused):not(.input-group--disabled) .input-group__input .input-group__prepend-icon:not(:hover) {
  color: rgba(0,0,0,0.54);
}
.theme--light .input-group:not(.input-group--error):not(.input-group--focused):not(.input-group--disabled):not(.input-group--disabled):not(.input-group--overflow):not(.input-group--segmented):not(.input-group--editable):hover .input-group__details:before,
.application .theme--light.input-group:not(.input-group--error):not(.input-group--focused):not(.input-group--disabled):not(.input-group--disabled):not(.input-group--overflow):not(.input-group--segmented):not(.input-group--editable):hover .input-group__details:before {
  background-color: rgba(0,0,0,0.87);
}
.theme--light .input-group:not(.input-group--error):not(.input-group--focused):not(.input-group--disabled):not(.input-group--disabled):not(.input-group--overflow):not(.input-group--segmented):not(.input-group--editable):hover.input-group--textarea:not(.input-group--full-width) .input-group__input,
.application .theme--light.input-group:not(.input-group--error):not(.input-group--focused):not(.input-group--disabled):not(.input-group--disabled):not(.input-group--overflow):not(.input-group--segmented):not(.input-group--editable):hover.input-group--textarea:not(.input-group--full-width) .input-group__input {
  border-color: rgba(0,0,0,0.87);
}
.theme--light .input-group.input-group--overflow .input-group__input:before,
.application .theme--light.input-group.input-group--overflow .input-group__input:before,
.theme--light .input-group.input-group--segmented .input-group__input:before,
.application .theme--light.input-group.input-group--segmented .input-group__input:before,
.theme--light .input-group.input-group--editable .input-group__input:before,
.application .theme--light.input-group.input-group--editable .input-group__input:before,
.theme--light .input-group.input-group--overflow .input-group__details:before,
.application .theme--light.input-group.input-group--overflow .input-group__details:before,
.theme--light .input-group.input-group--segmented .input-group__details:before,
.application .theme--light.input-group.input-group--segmented .input-group__details:before,
.theme--light .input-group.input-group--editable .input-group__details:before,
.application .theme--light.input-group.input-group--editable .input-group__details:before {
  background-color: rgba(0,0,0,0.12);
}
.theme--light .input-group.input-group--disabled .input-group__input .input-group__append-icon,
.application .theme--light.input-group.input-group--disabled .input-group__input .input-group__append-icon,
.theme--light .input-group.input-group--disabled .input-group__input .input-group__prepend-icon,
.application .theme--light.input-group.input-group--disabled .input-group__input .input-group__prepend-icon {
  color: rgba(0,0,0,0.38);
}
.theme--light .input-group.input-group--disabled .input-group__details:before,
.application .theme--light.input-group.input-group--disabled .input-group__details:before {
  background-color: transparent;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(0,0,0,0.38)), color-stop(33%, rgba(0,0,0,0.38)), color-stop(0, transparent));
  background-image: linear-gradient(to right, rgba(0,0,0,0.38) 0, rgba(0,0,0,0.38) 33%, transparent 0);
}
.theme--light .input-group .input-group--text-field__prefix,
.application .theme--light.input-group .input-group--text-field__prefix,
.theme--light .input-group .input-group--text-field__suffix,
.application .theme--light.input-group .input-group--text-field__suffix {
  color: rgba(0,0,0,0.54);
}
.theme--light .input-group .input-group--text-field.input-group--disabled__prefix,
.application .theme--light.input-group .input-group--text-field.input-group--disabled__prefix,
.theme--light .input-group .input-group--text-field.input-group--disabled__suffix,
.application .theme--light.input-group .input-group--text-field.input-group--disabled__suffix {
  color: rgba(0,0,0,0.38);
}
.theme--dark .input-group input,
.application .theme--dark.input-group input,
.theme--dark .input-group textarea,
.application .theme--dark.input-group textarea {
  color: #fff;
}
.theme--dark .input-group input:disabled,
.application .theme--dark.input-group input:disabled,
.theme--dark .input-group textarea:disabled,
.application .theme--dark.input-group textarea:disabled {
  color: rgba(255,255,255,0.5);
}
.theme--dark .input-group:not(.input-group--error) .input-group__messages,
.application .theme--dark.input-group:not(.input-group--error) .input-group__messages {
  color: rgba(255,255,255,0.7);
}
.theme--dark .input-group.input-group--textarea:not(.input-group--full-width) .input-group__input,
.application .theme--dark.input-group.input-group--textarea:not(.input-group--full-width) .input-group__input {
  border: 2px solid rgba(255,255,255,0.7);
}
.theme--dark .input-group.input-group--dirty .input-group__selections__comma:not(.input-group__selections__comma--active),
.application .theme--dark.input-group.input-group--dirty .input-group__selections__comma:not(.input-group__selections__comma--active) {
  color: #fff;
}
.theme--dark .input-group:not(.input-group--error) label,
.application .theme--dark.input-group:not(.input-group--error) label {
  color: rgba(255,255,255,0.7);
}
.theme--dark .input-group:not(.input-group--error).input-group--disabled label,
.application .theme--dark.input-group:not(.input-group--error).input-group--disabled label,
.theme--dark .input-group:not(.input-group--error).input-group--disabled .input-group__selections__comma,
.application .theme--dark.input-group:not(.input-group--error).input-group--disabled .input-group__selections__comma {
  color: rgba(255,255,255,0.5);
}
.theme--dark .input-group:not(.input-group--error) .input-group__details:before,
.application .theme--dark.input-group:not(.input-group--error) .input-group__details:before {
  background-color: rgba(255,255,255,0.7);
}
.theme--dark .input-group:not(.input-group--error):not(.input-group--focused):not(.input-group--disabled) .input-group__input .input-group__append-icon:not(:hover),
.application .theme--dark.input-group:not(.input-group--error):not(.input-group--focused):not(.input-group--disabled) .input-group__input .input-group__append-icon:not(:hover),
.theme--dark .input-group:not(.input-group--error):not(.input-group--focused):not(.input-group--disabled) .input-group__input .input-group__prepend-icon:not(:hover),
.application .theme--dark.input-group:not(.input-group--error):not(.input-group--focused):not(.input-group--disabled) .input-group__input .input-group__prepend-icon:not(:hover) {
  color: rgba(255,255,255,0.7);
}
.theme--dark .input-group:not(.input-group--error):not(.input-group--focused):not(.input-group--disabled):not(.input-group--disabled):not(.input-group--overflow):not(.input-group--segmented):not(.input-group--editable):hover .input-group__details:before,
.application .theme--dark.input-group:not(.input-group--error):not(.input-group--focused):not(.input-group--disabled):not(.input-group--disabled):not(.input-group--overflow):not(.input-group--segmented):not(.input-group--editable):hover .input-group__details:before {
  background-color: #fff;
}
.theme--dark .input-group:not(.input-group--error):not(.input-group--focused):not(.input-group--disabled):not(.input-group--disabled):not(.input-group--overflow):not(.input-group--segmented):not(.input-group--editable):hover.input-group--textarea:not(.input-group--full-width) .input-group__input,
.application .theme--dark.input-group:not(.input-group--error):not(.input-group--focused):not(.input-group--disabled):not(.input-group--disabled):not(.input-group--overflow):not(.input-group--segmented):not(.input-group--editable):hover.input-group--textarea:not(.input-group--full-width) .input-group__input {
  border-color: #fff;
}
.theme--dark .input-group.input-group--overflow .input-group__input:before,
.application .theme--dark.input-group.input-group--overflow .input-group__input:before,
.theme--dark .input-group.input-group--segmented .input-group__input:before,
.application .theme--dark.input-group.input-group--segmented .input-group__input:before,
.theme--dark .input-group.input-group--editable .input-group__input:before,
.application .theme--dark.input-group.input-group--editable .input-group__input:before,
.theme--dark .input-group.input-group--overflow .input-group__details:before,
.application .theme--dark.input-group.input-group--overflow .input-group__details:before,
.theme--dark .input-group.input-group--segmented .input-group__details:before,
.application .theme--dark.input-group.input-group--segmented .input-group__details:before,
.theme--dark .input-group.input-group--editable .input-group__details:before,
.application .theme--dark.input-group.input-group--editable .input-group__details:before {
  background-color: rgba(255,255,255,0.12);
}
.theme--dark .input-group.input-group--disabled .input-group__input .input-group__append-icon,
.application .theme--dark.input-group.input-group--disabled .input-group__input .input-group__append-icon,
.theme--dark .input-group.input-group--disabled .input-group__input .input-group__prepend-icon,
.application .theme--dark.input-group.input-group--disabled .input-group__input .input-group__prepend-icon {
  color: rgba(255,255,255,0.5);
}
.theme--dark .input-group.input-group--disabled .input-group__details:before,
.application .theme--dark.input-group.input-group--disabled .input-group__details:before {
  background-color: transparent;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255,255,255,0.5)), color-stop(33%, rgba(255,255,255,0.5)), color-stop(0, transparent));
  background-image: linear-gradient(to right, rgba(255,255,255,0.5) 0, rgba(255,255,255,0.5) 33%, transparent 0);
}
.theme--dark .input-group .input-group--text-field__prefix,
.application .theme--dark.input-group .input-group--text-field__prefix,
.theme--dark .input-group .input-group--text-field__suffix,
.application .theme--dark.input-group .input-group--text-field__suffix {
  color: rgba(255,255,255,0.7);
}
.theme--dark .input-group .input-group--text-field.input-group--disabled__prefix,
.application .theme--dark.input-group .input-group--text-field.input-group--disabled__prefix,
.theme--dark .input-group .input-group--text-field.input-group--disabled__suffix,
.application .theme--dark.input-group .input-group--text-field.input-group--disabled__suffix {
  color: rgba(255,255,255,0.5);
}
.input-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  min-width: 24px;
  padding: 18px 0 0;
  position: relative;
  width: 100%;
  outline: none;
  -webkit-transition: -webkit-box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: -webkit-box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), -webkit-box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
/** Label */
.input-group label {
  display: inline-block;
  font-size: 16px;
  line-height: 30px;
  height: 30px;
  max-width: 90%;
  min-width: 0;
  overflow: hidden;
  pointer-events: none;
  text-align: left;
  text-overflow: ellipsis;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  white-space: nowrap;
  width: 100%;
  z-index: 0;
}
/** Blocks */
.input-group__input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
  min-width: 0;
  min-height: 30px;
}
/** Icons */
.input-group__icon-cb {
  cursor: pointer;
}
.input-group.input-group--focused .input-group__append-icon,
.input-group.input-group--error .input-group__append-icon,
.input-group.input-group--focused .input-group__prepend-icon,
.input-group.input-group--error .input-group__prepend-icon {
  color: inherit;
}
.input-group.input-group--append-icon label,
.input-group.input-group--selection-controls label {
  max-width: 75%;
}
.input-group.input-group--prepend-icon label {
  max-width: 75%;
}
.input-group.input-group--append-icon.input-group--prepend-icon label {
  max-width: 65%;
}
.input-group .input-group__append-icon {
  padding: 0 6px;
  -webkit-transition: 0.3s cubic-bezier(0.4, 0, 0.6, 1);
  transition: 0.3s cubic-bezier(0.4, 0, 0.6, 1);
}
.input-group .input-group__append-icon,
.input-group .input-group__prepend-icon {
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
/** States */
.input-group.input-group--single-line.input-group--dirty label,
.input-group.input-group--solo.input-group--dirty label {
  display: none;
}
.input-group.input-group--solo {
  background: #fff;
  min-height: 46px;
  border-radius: 2px;
  padding: 0;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.input-group.input-group--solo label {
  top: 8px;
  padding-left: 16px;
  -webkit-transform: none !important;
          transform: none !important;
}
.input-group.input-group--solo .input-group__input {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 8px 16px;
}
.input-group.input-group--solo .input-group__details {
  display: none;
}
.input-group--disabled .input-group__details:before {
  background-color: transparent;
  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;
}
.input-group.input-group--text-field:not(.input-group--single-line):not(.input-group--error).input-group--focused label {
  color: inherit;
}
.input-group.input-group--text-field:not(.input-group--single-line):not(.input-group--error).input-group--focused .input-group__input {
  border-color: inherit;
}
.input-group.input-group--text-field.input-group--focused:not(.input-group--disabled) .input-group__details:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
.input-group--required label:after {
  content: '*';
}
.input-group.input-group--error label {
  -webkit-animation: shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
          animation: shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.input-group.input-group--error .input-group__messages {
  color: inherit;
}
.input-group.input-group--error .input-group__details:before {
  background-color: currentColor;
}
/** Details */
.input-group .slide-y-transition-leave,
.input-group .slide-y-transition-leave-to {
  position: absolute;
}
.input-group__details {
  color: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 4px;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
  font-size: 12px;
  min-height: 26px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.input-group__details:after,
.input-group__details:before {
  content: '';
  position: absolute;
  left: 0;
  -webkit-transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.input-group__details:after {
  background-color: currentColor;
  color: inherit;
  top: 0;
  height: 2px;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: center center 0;
          transform-origin: center center 0;
  width: 100%;
  z-index: 1;
}
.input-group__details:before {
  top: 0;
  height: 1px;
  width: 100%;
  z-index: 0;
}
.input-group--hide-details .input-group__details {
  min-height: 2px;
  padding: 0;
}
.input-group--async-loading .input-group__details:before,
.input-group--async-loading .input-group__details:after {
  display: none;
}
.input-group .progress-linear {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
}
/** Hint */
.input-group__hint {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
/** Error */
.input-group .input-group__error {
  color: inherit;
  -webkit-box-flex: 1;
      -ms-flex: 1 0;
          flex: 1 0;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
/** Types */
.input-group--overflow.input-group--active,
.input-group--segmented.input-group--active,
.input-group--editable.input-group--active {
  background-color: #fff;
}
/** Theme */
.theme--light .input-group--selection-controls label,
.application .theme--light.input-group--selection-controls label {
  color: rgba(0,0,0,0.87);
}
.theme--light .input-group--selection-controls .icon--selection-control,
.application .theme--light.input-group--selection-controls .icon--selection-control {
  color: rgba(0,0,0,0.54);
}
.theme--light .input-group--selection-controls.input-group--active .icon--selection-control,
.application .theme--light.input-group--selection-controls.input-group--active .icon--selection-control {
  color: inherit;
}
.theme--light .input-group--selection-controls.input-group--disabled .input-group__input,
.application .theme--light.input-group--selection-controls.input-group--disabled .input-group__input {
  color: rgba(0,0,0,0.38);
}
.theme--light .input-group--selection-controls.input-group--disabled .input-group__input .icon--radio,
.application .theme--light.input-group--selection-controls.input-group--disabled .input-group__input .icon--radio,
.theme--light .input-group--selection-controls.input-group--disabled .input-group__input .icon--checkbox,
.application .theme--light.input-group--selection-controls.input-group--disabled .input-group__input .icon--checkbox {
  color: inherit;
}
.theme--dark .input-group--selection-controls label,
.application .theme--dark.input-group--selection-controls label {
  color: #fff;
}
.theme--dark .input-group--selection-controls .icon--selection-control,
.application .theme--dark.input-group--selection-controls .icon--selection-control {
  color: rgba(255,255,255,0.7);
}
.theme--dark .input-group--selection-controls.input-group--active .icon--selection-control,
.application .theme--dark.input-group--selection-controls.input-group--active .icon--selection-control {
  color: inherit;
}
.theme--dark .input-group--selection-controls.input-group--disabled .input-group__input,
.application .theme--dark.input-group--selection-controls.input-group--disabled .input-group__input {
  color: rgba(255,255,255,0.5);
}
.theme--dark .input-group--selection-controls.input-group--disabled .input-group__input .icon--radio,
.application .theme--dark.input-group--selection-controls.input-group--disabled .input-group__input .icon--radio,
.theme--dark .input-group--selection-controls.input-group--disabled .input-group__input .icon--checkbox,
.application .theme--dark.input-group--selection-controls.input-group--disabled .input-group__input .icon--checkbox {
  color: inherit;
}
.input-group--selection-controls.input-group--tab-focused .input-group--selection-controls__ripple:before,
.input-group--tab-focused .input-group:focus .input-group--selection-controls__ripple:before {
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  opacity: 0.15;
}
/** Input */
.input-group.input-group--selection-controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
}
.input-group.input-group--selection-controls .icon--selection-control {
  cursor: pointer;
  position: absolute;
  left: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: 0.3s cubic-bezier(0.4, 0, 0.6, 1);
  transition: 0.3s cubic-bezier(0.4, 0, 0.6, 1);
}
.input-group.input-group--selection-controls .input-group__details:before,
.input-group.input-group--selection-controls .input-group__details:after {
  display: none;
}
.input-group.input-group--selection-controls .input-group__input {
  color: inherit;
  width: 100%;
  position: relative;
}
.input-group.input-group--selection-controls .input-group__input .icon--selection-control {
  -ms-flex-item-align: center;
      align-self: center;
  height: 30px;
  margin: auto;
}
.input-group.input-group--selection-controls.input-group--error label {
  color: inherit;
}
.input-group.input-group--selection-controls.input-group--error .input-group__input .icon--selection-control {
  color: inherit;
}
/** Label */
.input-group.input-group--selection-controls label {
  cursor: pointer;
  position: absolute;
  left: 32px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  z-index: 1;
  pointer-events: all;
}
.input-group--selection-controls__ripple {
  border-radius: 50%;
  height: 48px;
  width: 48px;
  cursor: pointer;
  position: absolute;
  -webkit-transform: translate(-12px, -50%);
          transform: translate(-12px, -50%);
  -webkit-transform-origin: center center;
          transform-origin: center center;
  top: 50%;
  left: 0;
}
.input-group--selection-controls__ripple:before {
  content: '';
  position: absolute;
  width: 36px;
  height: 36px;
  background: currentColor;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0.3);
          transform: translate(-50%, -50%) scale(0.3);
  opacity: 0;
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.2, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.2, 1);
  -webkit-transform-origin: center center;
          transform-origin: center center;
}
/** Appended and prepended icons */
.input-group--prepend-icon.input-group--selection-controls .icon--selection-control,
.input-group--prepend-icon.input-group--selection-controls .input-group--selection-controls__ripple {
  left: 38px;
}
.input-group--prepend-icon.input-group--selection-controls label {
  left: 76px;
}
.input-group--prepend-icon.radio-group--row .icon--selection-control,
.input-group--prepend-icon.radio-group--row .input-group--selection-controls__ripple {
  left: 14px;
}
.input-group--prepend-icon.radio-group--row label {
  left: 52px;
}
.theme--light .chip,
.application .theme--light.chip {
  background: #e0e0e0;
  color: rgba(0,0,0,0.87);
}
.theme--light .chip:not(.chip--outline) .chip__close,
.application .theme--light.chip:not(.chip--outline) .chip__close {
  color: rgba(0,0,0,0.54);
}
.theme--dark .chip,
.application .theme--dark.chip {
  background: #fff;
  color: rgba(0,0,0,0.87);
}
.theme--dark .chip:not(.chip--outline) .chip__close,
.application .theme--dark.chip:not(.chip--outline) .chip__close {
  color: #fff;
}
.chip {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 28px;
  border: 1px solid transparent;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 13px;
  margin: 4px;
  outline: none;
  position: relative;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  vertical-align: middle;
}
.chip .chip__content {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 28px;
  cursor: default;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 32px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 12px;
  vertical-align: middle;
  white-space: nowrap;
  z-index: 1;
}
.chip--removable .chip__content {
  padding: 0 4px 0 12px;
}
.chip .avatar {
  height: 32px !important;
  margin-left: -12px;
  margin-right: 8px;
  min-width: 32px;
  width: 32px !important;
}
.chip .avatar img {
  height: 100%;
  width: 100%;
}
.chip:focus:not(.chip--disabled),
.chip--active:not(.chip--disabled),
.chip--selected:not(.chip--disabled) {
  border-color: rgba(0,0,0,0.13);
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.chip:focus:not(.chip--disabled):after,
.chip--active:not(.chip--disabled):after,
.chip--selected:not(.chip--disabled):after {
  background: currentColor;
  border-radius: inherit;
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  -webkit-transition: inherit;
  transition: inherit;
  width: 100%;
  pointer-events: none;
  opacity: 0.13;
}
.chip--label {
  border-radius: 2px;
}
.chip--label .chip__content {
  border-radius: 2px;
}
.chip.chip--outline {
  background: transparent !important;
  border-color: currentColor;
  color: #9e9e9e;
}
.chip--small {
  height: 26px;
}
.chip--small .avatar {
  height: 26px;
  min-width: 26px;
  width: 26px;
}
.chip--small .icon {
  font-size: 20px;
}
.chip__close {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: inherit;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 20px;
  margin: 0 -1px 0 4px;
  text-decoration: none;
  -webkit-transition: none !important;
  transition: none !important;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.chip__close > .icon {
  color: inherit !important;
}
.chip__close:hover {
  opacity: 0.8;
}
.chip--select-multi {
  margin: 4px 4px 4px 0;
}
.chip .icon {
  color: inherit;
  -webkit-transition: none;
  transition: none;
}
.chip .icon--right {
  margin-left: 12px;
  margin-right: -8px;
}
.chip .icon--left {
  margin-left: -8px;
  margin-right: 12px;
}
/* Theme */
.theme--light .table,
.application .theme--light.table {
  background-color: #fff;
  color: rgba(0,0,0,0.87);
}
.theme--light .table thead tr:first-child,
.application .theme--light.table thead tr:first-child {
  border-bottom: 1px solid rgba(0,0,0,0.12);
}
.theme--light .table thead th,
.application .theme--light.table thead th {
  color: rgba(0,0,0,0.54);
}
.theme--light .table tbody tr:not(:last-child),
.application .theme--light.table tbody tr:not(:last-child) {
  border-bottom: 1px solid rgba(0,0,0,0.12);
}
.theme--light .table tbody tr[active],
.application .theme--light.table tbody tr[active] {
  background: #f5f5f5;
}
.theme--light .table tbody tr:hover:not(.datatable__expand-row),
.application .theme--light.table tbody tr:hover:not(.datatable__expand-row) {
  background: #eee;
}
.theme--light .table tfoot tr,
.application .theme--light.table tfoot tr {
  border-top: 1px solid rgba(0,0,0,0.12);
}
.theme--dark .table,
.application .theme--dark.table {
  background-color: #424242;
  color: #fff;
}
.theme--dark .table thead tr:first-child,
.application .theme--dark.table thead tr:first-child {
  border-bottom: 1px solid rgba(255,255,255,0.12);
}
.theme--dark .table thead th,
.application .theme--dark.table thead th {
  color: rgba(255,255,255,0.7);
}
.theme--dark .table tbody tr:not(:last-child),
.application .theme--dark.table tbody tr:not(:last-child) {
  border-bottom: 1px solid rgba(255,255,255,0.12);
}
.theme--dark .table tbody tr[active],
.application .theme--dark.table tbody tr[active] {
  background: #505050;
}
.theme--dark .table tbody tr:hover:not(.datatable__expand-row),
.application .theme--dark.table tbody tr:hover:not(.datatable__expand-row) {
  background: #616161;
}
.theme--dark .table tfoot tr,
.application .theme--dark.table tfoot tr {
  border-top: 1px solid rgba(255,255,255,0.12);
}
.table__overflow {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
table.table {
  border-radius: 2px;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
}
table.table thead td:not(:nth-child(1)),
table.table tbody td:not(:nth-child(1)),
table.table thead th:not(:nth-child(1)),
table.table tbody th:not(:nth-child(1)),
table.table thead td:first-child,
table.table tbody td:first-child,
table.table thead th:first-child,
table.table tbody th:first-child {
  padding: 0 24px;
}
table.table thead tr {
  height: 56px;
}
table.table thead th {
  font-weight: 500;
  font-size: 12px;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
table.table thead th.sortable {
  pointer-events: auto;
}
table.table thead th > div {
  width: 100%;
}
table.table tbody tr {
  -webkit-transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  will-change: background;
}
table.table tbody td,
table.table tbody th {
  height: 48px;
}
table.table tbody td {
  font-weight: 400;
  font-size: 13px;
}
table.table .input-group--selection-controls {
  padding: 0;
}
table.table .input-group--selection-controls .input-group__details {
  display: none;
}
table.table .input-group--selection-controls.checkbox .icon {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
table.table .input-group--selection-controls.checkbox .input-group--selection-controls__ripple {
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
table.table tfoot tr:not(:last-child) {
  height: 48px;
}
table.table tfoot tr:not(:last-child):not(:only-child) td {
  padding: 0 24px;
}
table.table tfoot tr {
  height: 56px;
}
/* Theme */
.theme--light .datatable thead th.column.sortable i,
.application .theme--light.datatable thead th.column.sortable i {
  color: rgba(0,0,0,0.38);
}
.theme--light .datatable thead th.column.sortable:hover,
.application .theme--light.datatable thead th.column.sortable:hover {
  color: rgba(0,0,0,0.87);
}
.theme--light .datatable thead th.column.sortable.active,
.application .theme--light.datatable thead th.column.sortable.active {
  color: rgba(0,0,0,0.87);
}
.theme--light .datatable thead th.column.sortable.active i,
.application .theme--light.datatable thead th.column.sortable.active i {
  color: rgba(0,0,0,0.87);
}
.theme--light .datatable .datatable__actions,
.application .theme--light.datatable .datatable__actions {
  color: rgba(0,0,0,0.54);
}
.theme--light .datatable .datatable__actions__select .input-group--select .input-group__selections__comma,
.application .theme--light.datatable .datatable__actions__select .input-group--select .input-group__selections__comma {
  color: rgba(0,0,0,0.54) !important;
}
.theme--light .datatable .datatable__actions__select .input-group--select .input-group__append-icon,
.application .theme--light.datatable .datatable__actions__select .input-group--select .input-group__append-icon {
  color: rgba(0,0,0,0.54) !important;
}
.theme--dark .datatable thead th.column.sortable i,
.application .theme--dark.datatable thead th.column.sortable i {
  color: rgba(255,255,255,0.5);
}
.theme--dark .datatable thead th.column.sortable:hover,
.application .theme--dark.datatable thead th.column.sortable:hover {
  color: #fff;
}
.theme--dark .datatable thead th.column.sortable.active,
.application .theme--dark.datatable thead th.column.sortable.active {
  color: #fff;
}
.theme--dark .datatable thead th.column.sortable.active i,
.application .theme--dark.datatable thead th.column.sortable.active i {
  color: #fff;
}
.theme--dark .datatable .datatable__actions,
.application .theme--dark.datatable .datatable__actions {
  color: rgba(255,255,255,0.7);
}
.theme--dark .datatable .datatable__actions__select .input-group--select .input-group__selections__comma,
.application .theme--dark.datatable .datatable__actions__select .input-group--select .input-group__selections__comma {
  color: rgba(255,255,255,0.7) !important;
}
.theme--dark .datatable .datatable__actions__select .input-group--select .input-group__append-icon,
.application .theme--dark.datatable .datatable__actions__select .input-group--select .input-group__append-icon {
  color: rgba(255,255,255,0.7) !important;
}
.datatable thead th.column.sortable {
  cursor: pointer;
}
.datatable thead th.column.sortable i {
  font-size: 16px;
  vertical-align: sub;
  display: inline-block;
  opacity: 0;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.datatable thead th.column.sortable:hover i {
  opacity: 0.6;
}
.datatable thead th.column.sortable.active {
  -webkit-transform: none;
          transform: none;
}
.datatable thead th.column.sortable.active i {
  opacity: 1;
}
.datatable thead th.column.sortable.active.desc i {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
/** Actions */
.datatable__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 12px;
}
.datatable__actions .btn {
  color: inherit;
}
.datatable__actions .btn:last-of-type {
  margin-left: 18px;
}
.datatable__actions__pagination {
  text-align: center;
  margin: 0 26px 0 32px;
}
.datatable__actions__select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.datatable__actions__select .input-group--select {
  margin: 13px 0 13px 34px;
  padding: 0;
  position: initial;
}
.datatable__actions__select .input-group--select .input-group__selections__comma {
  font-size: 12px;
}
.datatable__progress {
  height: auto !important;
}
.datatable__progress tr,
.datatable__progress td,
.datatable__progress th {
  height: auto !important;
}
.datatable__progress th {
  padding: 0 !important;
}
.datatable__progress th .progress-linear {
  top: -2px;
  margin: 0 0 -2px;
}
.datatable__expand-row {
  border: none !important;
}
.datatable__expand-col {
  padding: 0 !important;
  height: 0px !important;
}
.datatable__expand-col--expanded {
  border-bottom: 1px solid rgba(0,0,0,0.12);
}
.datatable__expand-content {
  -webkit-transition: height 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: height 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.datatable__expand-content > .card {
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.progress-linear {
  background: transparent;
  margin: 1rem 0;
  overflow: hidden;
  width: 100%;
  position: relative;
}
.progress-linear__bar {
  width: 100%;
  height: inherit;
  position: relative;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  z-index: 1;
}
.progress-linear__bar__determinate {
  height: inherit;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}
.progress-linear__bar__indeterminate .long,
.progress-linear__bar__indeterminate .short {
  height: inherit;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  will-change: left, right;
  width: auto;
  background-color: inherit;
}
.progress-linear__bar__indeterminate--active .long {
  -webkit-animation: indeterminate;
          animation: indeterminate;
  -webkit-animation-duration: 2.2s;
          animation-duration: 2.2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.progress-linear__bar__indeterminate--active .short {
  -webkit-animation: indeterminate-short;
          animation: indeterminate-short;
  -webkit-animation-duration: 2.2s;
          animation-duration: 2.2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.progress-linear__background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}
.progress-linear--query .progress-linear__bar__indeterminate--active .long {
  -webkit-animation: query;
          animation: query;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.progress-linear--query .progress-linear__bar__indeterminate--active .short {
  -webkit-animation: query-short;
          animation: query-short;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
@-webkit-keyframes indeterminate {
  0% {
    left: -90%;
    right: 100%;
  }
  60% {
    left: -90%;
    right: 100%;
  }
  100% {
    left: 100%;
    right: -35%;
  }
}
@keyframes indeterminate {
  0% {
    left: -90%;
    right: 100%;
  }
  60% {
    left: -90%;
    right: 100%;
  }
  100% {
    left: 100%;
    right: -35%;
  }
}
@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@-webkit-keyframes query {
  0% {
    right: -90%;
    left: 100%;
  }
  60% {
    right: -90%;
    left: 100%;
  }
  100% {
    right: 100%;
    left: -35%;
  }
}
@keyframes query {
  0% {
    right: -90%;
    left: 100%;
  }
  60% {
    right: -90%;
    left: 100%;
  }
  100% {
    right: 100%;
    left: -35%;
  }
}
@-webkit-keyframes query-short {
  0% {
    right: -200%;
    left: 100%;
  }
  60% {
    right: 107%;
    left: -8%;
  }
  100% {
    right: 107%;
    left: -8%;
  }
}
@keyframes query-short {
  0% {
    right: -200%;
    left: 100%;
  }
  60% {
    right: 107%;
    left: -8%;
  }
  100% {
    right: 107%;
    left: -8%;
  }
}
.theme--light .input-group--text-field.input-group--text-field-box .input-group__input,
.application .theme--light.input-group--text-field.input-group--text-field-box .input-group__input {
  background: rgba(255,255,255,0.6);
}
.theme--light .input-group--text-field input::-webkit-input-placeholder,
.application .theme--light.input-group--text-field input::-webkit-input-placeholder,
.theme--light .input-group--text-field textarea::-webkit-input-placeholder,
.application .theme--light.input-group--text-field textarea::-webkit-input-placeholder {
  color: rgba(0,0,0,0.38);
}
.theme--light .input-group--text-field input:-ms-input-placeholder,
.application .theme--light.input-group--text-field input:-ms-input-placeholder,
.theme--light .input-group--text-field textarea:-ms-input-placeholder,
.application .theme--light.input-group--text-field textarea:-ms-input-placeholder {
  color: rgba(0,0,0,0.38);
}
.theme--light .input-group--text-field input::-ms-input-placeholder,
.application .theme--light.input-group--text-field input::-ms-input-placeholder,
.theme--light .input-group--text-field textarea::-ms-input-placeholder,
.application .theme--light.input-group--text-field textarea::-ms-input-placeholder {
  color: rgba(0,0,0,0.38);
}
.theme--light .input-group--text-field input::placeholder,
.application .theme--light.input-group--text-field input::placeholder,
.theme--light .input-group--text-field textarea::placeholder,
.application .theme--light.input-group--text-field textarea::placeholder {
  color: rgba(0,0,0,0.38);
}
.theme--light .input-group--text-field:not(.input-group--error) .input-group__counter,
.application .theme--light.input-group--text-field:not(.input-group--error) .input-group__counter {
  color: rgba(0,0,0,0.54);
}
.theme--dark .input-group--text-field.input-group--text-field-box .input-group__input,
.application .theme--dark.input-group--text-field.input-group--text-field-box .input-group__input {
  background: rgba(255,255,255,0.1);
}
.theme--dark .input-group--text-field input::-webkit-input-placeholder,
.application .theme--dark.input-group--text-field input::-webkit-input-placeholder,
.theme--dark .input-group--text-field textarea::-webkit-input-placeholder,
.application .theme--dark.input-group--text-field textarea::-webkit-input-placeholder {
  color: rgba(255,255,255,0.5);
}
.theme--dark .input-group--text-field input:-ms-input-placeholder,
.application .theme--dark.input-group--text-field input:-ms-input-placeholder,
.theme--dark .input-group--text-field textarea:-ms-input-placeholder,
.application .theme--dark.input-group--text-field textarea:-ms-input-placeholder {
  color: rgba(255,255,255,0.5);
}
.theme--dark .input-group--text-field input::-ms-input-placeholder,
.application .theme--dark.input-group--text-field input::-ms-input-placeholder,
.theme--dark .input-group--text-field textarea::-ms-input-placeholder,
.application .theme--dark.input-group--text-field textarea::-ms-input-placeholder {
  color: rgba(255,255,255,0.5);
}
.theme--dark .input-group--text-field input::placeholder,
.application .theme--dark.input-group--text-field input::placeholder,
.theme--dark .input-group--text-field textarea::placeholder,
.application .theme--dark.input-group--text-field textarea::placeholder {
  color: rgba(255,255,255,0.5);
}
.theme--dark .input-group--text-field:not(.input-group--error) .input-group__counter,
.application .theme--dark.input-group--text-field:not(.input-group--error) .input-group__counter {
  color: rgba(255,255,255,0.7);
}
/** Label */
.input-group--text-field label {
  position: absolute;
  top: 18px;
  left: 0;
}
/** Input */
.input-group--text-field input,
.input-group--text-field textarea {
  font-size: 16px;
}
.input-group--text-field input::-webkit-input-placeholder,
.input-group--text-field textarea::-webkit-input-placeholder {
  color: inherit;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.input-group--text-field input:-ms-input-placeholder,
.input-group--text-field textarea:-ms-input-placeholder {
  color: inherit;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.input-group--text-field input::-ms-input-placeholder,
.input-group--text-field textarea::-ms-input-placeholder {
  color: inherit;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.input-group--text-field input::placeholder,
.input-group--text-field textarea::placeholder {
  color: inherit;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.input-group--text-field input {
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 30px;
  margin: 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.input-group--text-field input:focus {
  outline: none;
}
.input-group--text-field input:disabled {
  pointer-events: none;
}
/** Textarea */
.input-group--text-field textarea {
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
}
.input-group--text-field textarea:focus {
  outline: none;
}
.input-group--text-field.input-group--textarea label {
  top: 13px;
}
.input-group--text-field.input-group--textarea .input-group__input {
  border-radius: 2px;
  -webkit-transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.input-group--text-field.input-group--textarea textarea {
  font-size: 16px;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.input-group--text-field.input-group--textarea:not(.input-group--full-width) label {
  top: 30px;
  left: 15px;
}
.input-group--text-field.input-group--textarea:not(.input-group--full-width) .input-group__input {
  padding: 30px 0 0 13px;
}
.input-group--text-field.input-group--textarea .input-group__details:before,
.input-group--text-field.input-group--textarea .input-group__details:after {
  opacity: 0;
}
/** Counter */
.input-group--text-field .input-group__counter {
  margin-left: auto;
}
.input-group--text-field .input-group__counter--error {
  color: inherit;
}
/** Input States */
.input-group--text-field.input-group--placeholder.input-group--dirty input::-webkit-input-placeholder,
.input-group--text-field.input-group--placeholder.input-group--dirty textarea::-webkit-input-placeholder {
  opacity: 0;
}
.input-group--text-field.input-group--placeholder.input-group--dirty input:-ms-input-placeholder,
.input-group--text-field.input-group--placeholder.input-group--dirty textarea:-ms-input-placeholder {
  opacity: 0;
}
.input-group--text-field.input-group--placeholder.input-group--dirty input::-ms-input-placeholder,
.input-group--text-field.input-group--placeholder.input-group--dirty textarea::-ms-input-placeholder {
  opacity: 0;
}
.input-group--text-field.input-group--placeholder.input-group--dirty input::placeholder,
.input-group--text-field.input-group--placeholder.input-group--dirty textarea::placeholder {
  opacity: 0;
}
/** Types */
.input-group--text-field.input-group--prepend-icon .input-group__prepend-icon {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  min-width: 40px;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.input-group--text-field.input-group--prepend-icon .input-group__details {
  margin-left: auto;
  max-width: calc(100% - 40px);
}
.input-group--text-field.input-group--prepend-icon label {
  left: 40px;
}
.input-group--text-field:not(.input-group--single-line).input-group--focused label,
.input-group--text-field:not(.input-group--single-line).input-group--placeholder label {
  opacity: 1;
}
.input-group--text-field:not(.input-group--single-line).input-group--focused:not(.input-group--textarea) label,
.input-group--text-field:not(.input-group--single-line).input-group--placeholder:not(.input-group--textarea) label {
  -webkit-transform: translate(0, -18px) scale(0.75);
          transform: translate(0, -18px) scale(0.75);
}
.input-group--text-field:not(.input-group--single-line).input-group--focused:not(.input-group--full-width).input-group--textarea label,
.input-group--text-field:not(.input-group--single-line).input-group--placeholder:not(.input-group--full-width).input-group--textarea label {
  -webkit-transform: translate(0, -8px) scale(0.75);
          transform: translate(0, -8px) scale(0.75);
}
.input-group--text-field:not(.input-group--single-line).input-group--focused.input-group--text-field-box label,
.input-group--text-field:not(.input-group--single-line).input-group--placeholder.input-group--text-field-box label {
  -webkit-transform: translate(0, -10px) scale(0.75);
          transform: translate(0, -10px) scale(0.75);
}
.input-group--text-field.input-group--dirty.input-group--select label,
.input-group--text-field.input-group--dirty:not(.input-group--textarea) label {
  -webkit-transform: translate(0, -18px) scale(0.75);
          transform: translate(0, -18px) scale(0.75);
}
.input-group--text-field.input-group--dirty:not(.input-group--full-width).input-group--textarea label {
  -webkit-transform: translate(0, -8px) scale(0.75);
          transform: translate(0, -8px) scale(0.75);
}
.input-group--text-field.input-group--multi-line textarea {
  padding-top: 4px;
}
.input-group--text-field.input-group--full-width {
  padding: 16px;
}
.input-group--text-field.input-group--full-width label {
  margin-left: 16px;
}
.input-group--text-field.input-group--full-width .input-group__details:before,
.input-group--text-field.input-group--full-width .input-group__details:after {
  display: none;
}
/** Prefix/Suffix */
.input-group--prefix:not(.input-group--focused):not(.input-group--dirty) label {
  left: 16px;
}
.input-group--prefix .input-group--text-field__prefix,
.input-group--suffix .input-group--text-field__prefix,
.input-group--prefix .input-group--text-field__suffix,
.input-group--suffix .input-group--text-field__suffix {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 16px;
  margin-top: 1px;
}
.input-group--prefix .input-group--text-field__prefix,
.input-group--suffix .input-group--text-field__prefix {
  margin-right: 3px;
}
.input-group--prefix .input-group--text-field__suffix,
.input-group--suffix .input-group--text-field__suffix {
  margin-left: 3px;
}
/** Box */
.input-group--text-field-box input,
.input-group--text-field-box textarea {
  cursor: pointer;
}
.input-group--text-field-box label {
  left: 16px;
}
.input-group--text-field-box .input-group__input {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  min-height: 56px;
}
.input-group--text-field-box .input-group__details {
  padding: 8px 16px 0;
}
.input-group--text-field-box .input-group__details:before,
.input-group--text-field-box .input-group__details:after {
  height: 2px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.input-group--text-field-box.input-group--multi-line textarea {
  padding-left: 24px;
  padding-right: 24px;
}
.input-group--text-field-box:not(.input-group--textarea).input-group--multi-line .input-group__input {
  padding-top: 26px;
}
.input-group--text-field-box:not(.input-group--textarea).input-group--multi-line label {
  top: 26px;
}
.input-group--text-field-box:not(.input-group--textarea):not(.input-group--multi-line) .input-group__input {
  padding-left: 16px;
  padding-right: 16px;
}
.input-group--text-field-box:not(.input-group--textarea):not(.input-group--multi-line) label {
  top: 32px;
}
.input-group--text-field-box:not(.input-group--textarea):not(.input-group--single-line).input-group--focused label,
.input-group--text-field-box:not(.input-group--textarea):not(.input-group--single-line).input-group--dirty label {
  -webkit-transform: translate(0, -10px) scale(0.75);
          transform: translate(0, -10px) scale(0.75);
}
.input-group--text-field-box.input-group--prepend-icon .input-group__details:before,
.input-group--text-field-box.input-group--prepend-icon .input-group__details:after {
  margin-left: 56px;
  max-width: calc(100% - 56px);
}
.input-group--text-field-box.input-group--prepend-icon label {
  left: 56px;
}
.theme--light .input-group--select.input-group--editable .input-group__input:hover,
.application .theme--light.input-group--select.input-group--editable .input-group__input:hover,
.theme--light .input-group--select.input-group--overflow .input-group__input:hover,
.application .theme--light.input-group--select.input-group--overflow .input-group__input:hover,
.theme--light .input-group--select.input-group--segmented .input-group__input:hover,
.application .theme--light.input-group--select.input-group--segmented .input-group__input:hover {
  background: #fff;
}
.theme--light .input-group--select.input-group--editable.input-group--focused .input-group__input,
.application .theme--light.input-group--select.input-group--editable.input-group--focused .input-group__input,
.theme--light .input-group--select.input-group--overflow.input-group--focused .input-group__input,
.application .theme--light.input-group--select.input-group--overflow.input-group--focused .input-group__input,
.theme--light .input-group--select.input-group--segmented.input-group--focused .input-group__input,
.application .theme--light.input-group--select.input-group--segmented.input-group--focused .input-group__input {
  background: #fff;
}
.theme--dark .input-group--select.input-group--editable .input-group__input:hover,
.application .theme--dark.input-group--select.input-group--editable .input-group__input:hover,
.theme--dark .input-group--select.input-group--overflow .input-group__input:hover,
.application .theme--dark.input-group--select.input-group--overflow .input-group__input:hover,
.theme--dark .input-group--select.input-group--segmented .input-group__input:hover,
.application .theme--dark.input-group--select.input-group--segmented .input-group__input:hover {
  background: #424242;
}
.theme--dark .input-group--select.input-group--editable.input-group--focused .input-group__input,
.application .theme--dark.input-group--select.input-group--editable.input-group--focused .input-group__input,
.theme--dark .input-group--select.input-group--overflow.input-group--focused .input-group__input,
.application .theme--dark.input-group--select.input-group--overflow.input-group--focused .input-group__input,
.theme--dark .input-group--select.input-group--segmented.input-group--focused .input-group__input,
.application .theme--dark.input-group--select.input-group--segmented.input-group--focused .input-group__input {
  background: #424242;
}
.input-group--select .input-group--select__autocomplete {
  display: block;
  height: 0px;
}
.input-group--select .input-group--select__autocomplete--index {
  opacity: 0 !important;
}
.input-group--select .input-group__append-icon {
  -webkit-transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.input-group--select .input-group__append-icon.input-group__icon-clearable {
  -webkit-transition: none;
  transition: none;
}
.input-group--select:not(.input-group--dirty) .input-group--select__autocomplete,
.input-group--select.input-group--focused .input-group--select__autocomplete {
  padding-bottom: 1px;
  height: 30px;
}
.input-group--select.input-group--focused .input-group--select__autocomplete {
  display: inline-block;
  opacity: 1;
}
.input-group--select.input-group--focused.input-group--open .input-group__append-icon:not(.input-group__icon-clearable) {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.input-group--select .input-group__input {
  cursor: pointer;
}
.input-group--select.input-group--disabled {
  cursor: default;
  pointer-events: none;
}
.input-group--select .input-group__selections {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
  width: 100%;
}
.input-group--select .input-group__selections__comma {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 16px;
  padding: 3px 4px 3px 0;
}
.input-group--select .input-group__selections__comma--active {
  color: inherit;
}
.input-group--select .menu {
  display: inline;
}
.input-group--select .fade-transition-leave-active {
  position: absolute;
  left: 0;
}
.input-group--select.input-group--autocomplete.input-group--search-focused .input-group__selections__comma {
  display: none;
}
.input-group--autocomplete .input-group__selections {
  cursor: text;
}
.input-group.input-group--overflow,
.input-group.input-group--editable,
.input-group.input-group--segmented {
  padding: 0;
}
.input-group.input-group--overflow .input-group__append-icon,
.input-group.input-group--editable .input-group__append-icon,
.input-group.input-group--segmented .input-group__append-icon {
  padding: 0;
}
.input-group.input-group--overflow .input-group__selections,
.input-group.input-group--editable .input-group__selections,
.input-group.input-group--segmented .input-group__selections,
.input-group.input-group--overflow input,
.input-group.input-group--editable input,
.input-group.input-group--segmented input {
  height: 48px;
}
.input-group.input-group--overflow .input-group__selections__comma,
.input-group.input-group--editable .input-group__selections__comma,
.input-group.input-group--segmented .input-group__selections__comma,
.input-group.input-group--overflow input,
.input-group.input-group--editable input,
.input-group.input-group--segmented input {
  top: 0;
  left: 0;
  padding-left: 16px;
}
.input-group.input-group--overflow .input-group__selections,
.input-group.input-group--editable .input-group__selections,
.input-group.input-group--segmented .input-group__selections {
  width: calc(100% - 55px);
}
.input-group.input-group--overflow .input-group__selections .btn,
.input-group.input-group--editable .input-group__selections .btn,
.input-group.input-group--segmented .input-group__selections .btn {
  border-radius: 0;
  margin: 0;
  height: 48px;
  width: 100%;
}
.input-group.input-group--overflow .input-group__selections .btn .btn__content,
.input-group.input-group--editable .input-group__selections .btn .btn__content,
.input-group.input-group--segmented .input-group__selections .btn .btn__content {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
}
.input-group.input-group--overflow .input-group__input,
.input-group.input-group--editable .input-group__input,
.input-group.input-group--segmented .input-group__input {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.input-group.input-group--overflow.input-group--focused .input-group__input,
.input-group.input-group--editable.input-group--focused .input-group__input,
.input-group.input-group--segmented.input-group--focused .input-group__input {
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
          box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}
.input-group.input-group--overflow label,
.input-group.input-group--editable label,
.input-group.input-group--segmented label {
  left: 16px !important;
  top: 9px !important;
}
.input-group.input-group--overflow .input-group__details:after,
.input-group.input-group--editable .input-group__details:after,
.input-group.input-group--segmented .input-group__details:after {
  display: none;
}
.input-group.input-group--overflow .input-group__input,
.input-group.input-group--editable .input-group__input,
.input-group.input-group--segmented .input-group__input {
  padding: 0;
}
.input-group.input-group--overflow .input-group__input:before,
.input-group.input-group--editable .input-group__input:before,
.input-group.input-group--segmented .input-group__input:before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  top: 0;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.input-group.input-group--overflow .input-group__append-icon,
.input-group.input-group--editable .input-group__append-icon,
.input-group.input-group--segmented .input-group__append-icon {
  width: 55px;
}
.input-group--segmented .input-group__input:after,
.input-group--editable .input-group__input:hover:after,
.input-group.input-group--focused.input-group--editable .input-group__input:after {
  background-color: rgba(0,0,0,0.12);
  content: '';
  position: absolute;
  right: 55px;
  height: 48px;
  top: 0;
  width: 1px;
}
.menu__content--select .input-group--selection-controls__ripple {
  display: none;
}
.menu__content--autocomplete {
  border-radius: 0;
}
.menu__content--autocomplete > .card {
  border-radius: 0;
}
/* Themes */
.theme--light .list,
.application .theme--light.list {
  background: #fff;
}
.theme--light .list .list__tile:not(.list__tile--active),
.application .theme--light.list .list__tile:not(.list__tile--active) {
  color: rgba(0,0,0,0.87);
}
.theme--light .list .list__tile__sub-title,
.application .theme--light.list .list__tile__sub-title {
  color: rgba(0,0,0,0.54);
}
.theme--light .list .divider,
.application .theme--light.list .divider {
  background-color: rgba(0,0,0,0.12);
}
.theme--light .list .list__tile__mask,
.application .theme--light.list .list__tile__mask {
  color: rgba(0,0,0,0.38);
}
.theme--light .list--group .list__tile--active .list__tile__title,
.application .theme--light.list--group .list__tile--active .list__tile__title {
  color: #1976d2;
}
.theme--dark .list,
.application .theme--dark.list {
  background: #424242;
}
.theme--dark .list .list__tile:not(.list__tile--active),
.application .theme--dark.list .list__tile:not(.list__tile--active) {
  color: #fff;
}
.theme--dark .list .list__tile__sub-title,
.application .theme--dark.list .list__tile__sub-title {
  color: rgba(255,255,255,0.7);
}
.theme--dark .list .divider,
.application .theme--dark.list .divider {
  background-color: rgba(255,255,255,0.12);
}
.theme--dark .list .list__tile__mask,
.application .theme--dark.list .list__tile__mask {
  color: rgba(255,255,255,0.5);
}
.theme--dark .list--group .list__tile--active .list__tile__title,
.application .theme--dark.list--group .list__tile--active .list__tile__title {
  color: #82b1ff;
}
.list {
  list-style-type: none;
  padding: 8px 0 8px;
  -webkit-transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.list .input-group {
  margin: 0;
}
.list > .list__tile ~ .list__tile {
  margin-top: 0;
}
.list__tile {
  font-size: 16px;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 48px;
  text-decoration: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 16px;
  margin: 0;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.list__tile:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 1px;
  opacity: 0;
  width: 100%;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  background-color: rgba(0,0,0,0.12);
}
.list__tile--link {
  cursor: pointer;
}
.list__tile--link:hover,
.list__tile--highlighted {
  background: rgba(0,0,0,0.12);
}
.list__tile__content,
.list__tile__action,
.list__tile__avatar {
  height: 100%;
}
.list__tile__title,
.list__tile__sub-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.list__tile__title {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  height: 24px;
  line-height: 24px;
  position: relative;
  text-align: left;
}
.list__tile__sub-title {
  font-size: 14px;
}
.list__tile .avatar {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  min-width: 56px;
}
.list__tile__action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  min-width: 56px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.list__tile__action .input-group--selection-controls {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
}
.list__tile__action .input-group__details {
  display: none;
}
.list__tile__action .icon {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.list__tile__action .btn {
  padding: 0;
  margin: 0;
}
.list__tile__action .btn--icon {
  margin: -8px;
}
.list__tile__action-text {
  color: #9e9e9e;
  font-size: 12px;
}
.list__tile__action--stack {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: nowrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.list__tile__content {
  text-align: left;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.list__tile__content ~ .avatar {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.list__tile__content ~ .list__tile__action:not(.list__tile__action--stack) {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.list__tile--active .list__tile__action:first-of-type .icon {
  color: inherit;
}
.list__tile--disabled {
  opacity: 0.4 !important;
  pointer-events: none;
}
.list__tile--avatar {
  height: 56px;
}
.list__tile--avatar .avatar img,
.list__tile--avatar .avatar .icon {
  height: 40px;
  width: 40px;
}
.list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
}
.list--dense .subheader {
  font-size: 13px;
  height: 40px;
}
.list--dense .list--group .subheader {
  height: 40px;
}
.list--dense .list__tile {
  font-size: 13px;
}
.list--dense .list__tile--avatar {
  height: 48px;
}
.list--dense .list__tile:not(.list__tile--avatar) {
  height: 40px;
}
.list--dense .list__tile .icon {
  font-size: 22px;
}
.list--dense .list__tile__sub-title {
  font-size: 13px;
}
.list--two-line .list__tile {
  height: 72px;
}
.list--two-line.list--dense .list__tile {
  height: 60px;
}
.list--three-line .list__tile {
  height: 88px;
}
.list--three-line .list__tile__sub-title {
  white-space: initial;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.list--three-line.list--dense .list__tile {
  height: 76px;
}
.list--group {
  position: relative;
  padding: 0;
}
.list--group:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  opacity: 0;
  width: 100%;
  background-color: rgba(0,0,0,0.12);
}
.list--group .list__tile {
  padding-left: 72px;
}
.list--group__header > li:first-child {
  cursor: pointer;
  display: block;
}
.list--group__header > li:first-child:hover {
  background: rgba(0,0,0,0.12);
}
.list--group__header + .list--group:after {
  opacity: 1;
}
.list--group__header--active .list__tile {
  background: rgba(0,0,0,0.12);
}
.list--group__header--active .list__tile:after {
  opacity: 1;
}
.list--group__header--active .list__tile .list__tile__title {
  color: inherit;
}
.list--group__header--active .list__tile .list__tile__action:last-of-type .icon {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.list--group__header--no-action + .list--group .list__tile {
  padding-left: 16px;
}
.list--subheader {
  padding-top: 0;
}
.list.list--two-line .list__tile__avatar,
.list.list--three-line .list__tile__avatar {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.menu {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.menu--disabled {
  cursor: default;
}
.menu--disabled .menu__activator,
.menu--disabled .menu__activator > * {
  cursor: default;
  pointer-events: none;
}
.menu__activator {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  position: relative;
}
.menu__activator input[readonly] {
  cursor: pointer;
}
.menu__activator .toolbar__side-icon {
  margin: 0;
}
.menu__content {
  position: absolute;
  display: inline-block;
  border-radius: 2px;
  max-width: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  contain: content;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
          box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}
.menu__content--active {
  pointer-events: none;
}
.menu__content--dropdown {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 1px solid rgba(0,0,0,0.12);
}
.menu__content > .card {
  contain: content;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.menu-transition-enter .list__tile {
  min-width: 0;
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
  opacity: 0;
  -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
  pointer-events: none;
}
.menu-transition-enter-to .list__tile {
  pointer-events: auto;
  opacity: 1;
}
.menu-transition-enter-to .list__tile--active {
  -webkit-transform: none !important;
          transform: none !important;
}
.menu-transition-leave-to {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}
.menu-transition-leave-active,
.menu-transition-leave-to {
  pointer-events: none;
}
.menu-transition-enter,
.menu-transition-leave-to {
  opacity: 0;
}
.menu-transition-enter-to,
.menu-transition-leave {
  opacity: 1;
}
.menu-transition-enter-active,
.menu-transition-leave-active {
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.menu-transition-enter.menu__content--auto .list__tile--active {
  opacity: 1;
  -webkit-transform: none !important;
          transform: none !important;
  pointer-events: auto;
}
.theme--light .small-dialog__content,
.application .theme--light.small-dialog__content {
  background: #fff;
}
.theme--light .small-dialog__actions,
.application .theme--light.small-dialog__actions {
  background: #fff;
}
.theme--light .small-dialog a,
.application .theme--light.small-dialog a {
  color: rgba(0,0,0,0.87);
}
.theme--dark .small-dialog__content,
.application .theme--dark.small-dialog__content {
  background: #424242;
}
.theme--dark .small-dialog__actions,
.application .theme--dark.small-dialog__actions {
  background: #424242;
}
.theme--dark .small-dialog a,
.application .theme--dark.small-dialog a {
  color: #fff;
}
.small-dialog {
  display: block;
  height: 100%;
}
.small-dialog__content {
  padding: 0 24px;
}
.small-dialog__actions {
  text-align: right;
}
.small-dialog a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  text-decoration: none;
}
.small-dialog a > * {
  width: 100%;
}
.small-dialog .menu__activator {
  height: 100%;
}
/* Themes */
.theme--dark .picker .picker__title,
.application .theme--dark.picker .picker__title {
  background: #616161;
}
.theme--light .picker,
.application .theme--light.picker {
  color: rgba(0,0,0,0.87);
}
.theme--light .picker .picker__body,
.application .theme--light.picker .picker__body {
  background: #fff;
}
.theme--dark .picker,
.application .theme--dark.picker {
  color: #fff;
}
.theme--dark .picker .picker__body,
.application .theme--dark.picker .picker__body {
  background: #424242;
}
.picker {
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 290px;
  contain: layout style;
}
.picker .card__row--actions {
  border: none;
  margin-top: -20px;
}
.picker__title {
  color: #fff;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  height: 105px;
  padding: 16px;
}
.picker__body {
  height: 290px;
  overflow: hidden;
  position: relative;
}
.picker--landscape {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 500px;
}
.picker--landscape .picker__title {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 170px;
          flex: 0 1 170px;
  width: 170px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
}
.picker--landscape .picker__body {
  -webkit-box-flex: 1;
      -ms-flex: 1 0;
          flex: 1 0;
  width: 330px;
  margin-left: 170px;
}
.picker--landscape .card__row--actions {
  margin-left: 170px;
  width: 330px;
}
/** Themes */
.theme--light .picker--date .picker--date__years li,
.application .theme--light.picker--date .picker--date__years li {
  color: rgba(0,0,0,0.87);
}
.theme--light .picker--date .picker--date__header-selector-date strong:not(:hover),
.application .theme--light.picker--date .picker--date__header-selector-date strong:not(:hover) {
  color: rgba(0,0,0,0.87) !important;
}
.theme--dark .picker--date .picker--date__years li,
.application .theme--dark.picker--date .picker--date__years li {
  color: #fff;
}
.theme--dark .picker--date .picker--date__header-selector-date strong:not(:hover),
.application .theme--dark.picker--date .picker--date__header-selector-date strong:not(:hover) {
  color: #fff !important;
}
.picker--date__table .btn.btn--active {
  color: #fff;
}
/** Years */
.picker--date__years {
  font-size: 16px;
  font-weight: 400;
  list-style-type: none;
  max-height: 290px;
  overflow: auto;
  padding: 0;
  text-align: center;
}
.picker--date__years li {
  cursor: pointer;
  padding: 8px 0;
  -webkit-transition: none;
  transition: none;
}
.picker--date__years li.active {
  font-size: 24px;
  font-weight: 500;
  padding: 10px 0;
}
.picker--date__years li:hover {
  background: rgba(0,0,0,0.12);
}
/** Title */
.picker--date__title {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.picker--date__title-year {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
}
.picker--date__title-date {
  font-size: 34px;
  text-align: left;
}
.picker--date__title-date > div {
  position: relative;
}
.picker--date__title-year,
.picker--date__title-date {
  font-weight: 500;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.picker--date__title-year:not(.active),
.picker--date__title-date:not(.active) {
  cursor: pointer;
}
/** Header */
.picker--date__header {
  padding: 4px 16px;
}
.picker--date__header-selector {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
}
.picker--date__header-selector .btn {
  margin: 0;
}
.picker--date__header-selector .icon {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.picker--date__header-selector-date {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.picker--date__header-selector-date strong {
  cursor: pointer;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  display: block;
  width: 100%;
}
.picker--date .btn {
  z-index: auto;
}
.picker--date__table {
  position: relative;
}
.picker--date table {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  top: 0;
  table-layout: fixed;
}
.picker--date table th {
  padding: 8px 0;
  font-weight: 600;
  font-size: 12px;
}
.picker--date table th,
.picker--date table td {
  text-align: center;
  width: 45px;
}
.picker--date table .btn {
  margin: 0;
  height: 32px;
  width: 32px;
}
.picker--month__table table {
  width: 100%;
}
.picker--month__table table td {
  width: 33.333333%;
  height: 56px;
  vertical-align: middle;
}
.theme--light .divider,
.application .theme--light.divider {
  background-color: rgba(0,0,0,0.12);
}
.theme--dark .divider,
.application .theme--dark.divider {
  background-color: rgba(255,255,255,0.12);
}
.divider {
  border: none;
  display: block;
  height: 1px;
  min-height: 1px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
}
.divider--inset {
  margin-left: 72px;
  width: calc(100% - 72px);
}
/** Theme */
.theme--light .expansion-panel .expansion-panel__container,
.application .theme--light.expansion-panel .expansion-panel__container {
  border-top: 1px solid rgba(0,0,0,0.12);
  background-color: #fff;
  color: rgba(0,0,0,0.87);
}
.theme--light .expansion-panel .expansion-panel__container .expansion-panel__header .icon,
.application .theme--light.expansion-panel .expansion-panel__container .expansion-panel__header .icon {
  color: rgba(0,0,0,0.54);
}
.theme--light .expansion-panel--focusable .expansion-panel__container:focus,
.application .theme--light.expansion-panel--focusable .expansion-panel__container:focus {
  background-color: #eee;
}
.theme--dark .expansion-panel .expansion-panel__container,
.application .theme--dark.expansion-panel .expansion-panel__container {
  border-top: 1px solid rgba(255,255,255,0.12);
  background-color: #424242;
  color: #fff;
}
.theme--dark .expansion-panel .expansion-panel__container .expansion-panel__header .icon,
.application .theme--dark.expansion-panel .expansion-panel__container .expansion-panel__header .icon {
  color: #fff;
}
.theme--dark .expansion-panel--focusable .expansion-panel__container:focus,
.application .theme--dark.expansion-panel--focusable .expansion-panel__container:focus {
  background-color: rgba(0,0,0,0.7);
}
.expansion-panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  list-style-type: none;
  padding: 0;
  text-align: left;
  width: 100%;
  -webkit-box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
.expansion-panel__container {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
  outline: none;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.expansion-panel__container:first-child {
  border-top: none !important;
}
.expansion-panel__container .header__icon {
  margin-left: auto;
}
.expansion-panel__container .header__icon .icon {
  -webkit-transition: none;
  transition: none;
}
.expansion-panel__container--active > .expansion-panel__header .header__icon .icon {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.expansion-panel__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  padding: 12px 24px;
}
.expansion-panel__header > *:not(.header__icon) {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.expansion-panel__body {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.expansion-panel__body .card {
  border-radius: 0;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
}
.expansion-panel--popout,
.expansion-panel--inset {
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
}
.expansion-panel--popout .expansion-panel__container--active,
.expansion-panel--inset .expansion-panel__container--active {
  margin: 16px;
  -webkit-box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
}
.expansion-panel--popout .expansion-panel__container,
.expansion-panel--inset .expansion-panel__container {
  max-width: 95%;
}
.expansion-panel--popout .expansion-panel__container--active {
  max-width: 100%;
}
.expansion-panel--inset .expansion-panel__container--active {
  max-width: 85%;
}
.theme--light .footer,
.application .theme--light.footer {
  background: #f5f5f5;
}
.theme--dark .footer,
.application .theme--dark.footer {
  background: #212121;
}
.footer {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0 !important;
      -ms-flex: 0 1 auto !important;
          flex: 0 1 auto !important;
  min-height: 36px;
  -webkit-transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.footer--absolute,
.footer--fixed {
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}
.footer--absolute {
  position: absolute;
}
.footer--fixed {
  position: fixed;
}
.footer > *:first-child {
  margin-left: 8px;
}
.footer > *:last-child {
  margin-right: 8px;
}
@media only screen and (max-width: 599px) {
  .footer > *:first-child {
    margin-left: 16px;
  }
  .footer > *:last-child {
    margin-right: 16px;
  }
}
.content {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  max-width: 100%;
  -webkit-transition: 0.2s padding cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.2s padding cubic-bezier(0.4, 0, 0.2, 1);
  will-change: padding;
}
.content--wrap {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
}
@-moz-document url-prefix() {
@media print {
    .content--wrap {
      display: block;
    }
}
}
.container {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  margin: auto;
  padding: 16px;
  width: 100%;
}
.container.fluid {
  max-width: 100%;
}
.container.fill-height {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.container.fill-height .layout {
  height: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.container.grid-list-xs {
  padding: 2px;
}
.container.grid-list-xs .layout .flex {
  padding: 1px;
}
.container.grid-list-xs .layout:only-child {
  margin: -1px;
}
.container.grid-list-xs .layout:not(:only-child) {
  margin: auto -1px;
}
.container.grid-list-xs *:not(:only-child) .layout:first-child {
  margin-top: -1px;
}
.container.grid-list-xs *:not(:only-child) .layout:last-child {
  margin-bottom: -1px;
}
.container.grid-list-sm {
  padding: 4px;
}
.container.grid-list-sm .layout .flex {
  padding: 2px;
}
.container.grid-list-sm .layout:only-child {
  margin: -2px;
}
.container.grid-list-sm .layout:not(:only-child) {
  margin: auto -2px;
}
.container.grid-list-sm *:not(:only-child) .layout:first-child {
  margin-top: -2px;
}
.container.grid-list-sm *:not(:only-child) .layout:last-child {
  margin-bottom: -2px;
}
.container.grid-list-md {
  padding: 8px;
}
.container.grid-list-md .layout .flex {
  padding: 4px;
}
.container.grid-list-md .layout:only-child {
  margin: -4px;
}
.container.grid-list-md .layout:not(:only-child) {
  margin: auto -4px;
}
.container.grid-list-md *:not(:only-child) .layout:first-child {
  margin-top: -4px;
}
.container.grid-list-md *:not(:only-child) .layout:last-child {
  margin-bottom: -4px;
}
.container.grid-list-lg {
  padding: 16px;
}
.container.grid-list-lg .layout .flex {
  padding: 8px;
}
.container.grid-list-lg .layout:only-child {
  margin: -8px;
}
.container.grid-list-lg .layout:not(:only-child) {
  margin: auto -8px;
}
.container.grid-list-lg *:not(:only-child) .layout:first-child {
  margin-top: -8px;
}
.container.grid-list-lg *:not(:only-child) .layout:last-child {
  margin-bottom: -8px;
}
.container.grid-list-xl {
  padding: 24px;
}
.container.grid-list-xl .layout .flex {
  padding: 12px;
}
.container.grid-list-xl .layout:only-child {
  margin: -12px;
}
.container.grid-list-xl .layout:not(:only-child) {
  margin: auto -12px;
}
.container.grid-list-xl *:not(:only-child) .layout:first-child {
  margin-top: -12px;
}
.container.grid-list-xl *:not(:only-child) .layout:last-child {
  margin-bottom: -12px;
}
.layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}
.layout.row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.layout.row.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.layout.column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.layout.column.reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
.layout.wrap {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.flex,
.child-flex > * {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.align-start {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.align-end {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.align-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.align-baseline {
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
.align-content-start {
  -ms-flex-line-pack: start;
      align-content: flex-start;
}
.align-content-end {
  -ms-flex-line-pack: end;
      align-content: flex-end;
}
.align-content-center {
  -ms-flex-line-pack: center;
      align-content: center;
}
.align-content-space-between {
  -ms-flex-line-pack: justify;
      align-content: space-between;
}
.align-content-space-around {
  -ms-flex-line-pack: distribute;
      align-content: space-around;
}
.justify-start {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.justify-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.justify-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.justify-space-around {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.justify-space-between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.spacer {
  -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important;
}
.scroll-y {
  overflow-y: auto;
}
.fill-height {
  height: 100%;
}
.hide-overflow {
  overflow: hidden !important;
}
.show-overflow {
  overflow: visible !important;
}
.no-wrap {
  white-space: nowrap;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.d-inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.d-flex > *,
.d-inline-flex > * {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
@media only screen and (min-width: 960px) {
  .container {
    max-width: 900px;
  }
}
@media only screen and (min-width: 1264px) {
  .container {
    max-width: 1185px;
  }
}
@media only screen and (min-width: 1904px) {
  .container {
    max-width: 1785px;
  }
}
@media only screen and (max-width: 599px) {
  .container {
    padding: 24px;
  }
}
@media all and (min-width: 0) {
  .flex.xs1 {
    -ms-flex-preferred-size: 8.333333333333332%;
    flex-basis: 8.333333333333332%;
    max-width: 8.333333333333332%;
  }
  .flex.order-xs1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .flex.xs2 {
    -ms-flex-preferred-size: 16.666666666666664%;
    flex-basis: 16.666666666666664%;
    max-width: 16.666666666666664%;
  }
  .flex.order-xs2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .flex.xs3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .flex.order-xs3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .flex.xs4 {
    -ms-flex-preferred-size: 33.33333333333333%;
    flex-basis: 33.33333333333333%;
    max-width: 33.33333333333333%;
  }
  .flex.order-xs4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .flex.xs5 {
    -ms-flex-preferred-size: 41.66666666666667%;
    flex-basis: 41.66666666666667%;
    max-width: 41.66666666666667%;
  }
  .flex.order-xs5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .flex.xs6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .flex.order-xs6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .flex.xs7 {
    -ms-flex-preferred-size: 58.333333333333336%;
    flex-basis: 58.333333333333336%;
    max-width: 58.333333333333336%;
  }
  .flex.order-xs7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .flex.xs8 {
    -ms-flex-preferred-size: 66.66666666666666%;
    flex-basis: 66.66666666666666%;
    max-width: 66.66666666666666%;
  }
  .flex.order-xs8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .flex.xs9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .flex.order-xs9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .flex.xs10 {
    -ms-flex-preferred-size: 83.33333333333334%;
    flex-basis: 83.33333333333334%;
    max-width: 83.33333333333334%;
  }
  .flex.order-xs10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .flex.xs11 {
    -ms-flex-preferred-size: 91.66666666666666%;
    flex-basis: 91.66666666666666%;
    max-width: 91.66666666666666%;
  }
  .flex.order-xs11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .flex.xs12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .flex.order-xs12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .flex.offset-xs0 {
    margin-left: 0%;
  }
  .flex.offset-xs1 {
    margin-left: 8.333333333333332%;
  }
  .flex.offset-xs2 {
    margin-left: 16.666666666666664%;
  }
  .flex.offset-xs3 {
    margin-left: 25%;
  }
  .flex.offset-xs4 {
    margin-left: 33.33333333333333%;
  }
  .flex.offset-xs5 {
    margin-left: 41.66666666666667%;
  }
  .flex.offset-xs6 {
    margin-left: 50%;
  }
  .flex.offset-xs7 {
    margin-left: 58.333333333333336%;
  }
  .flex.offset-xs8 {
    margin-left: 66.66666666666666%;
  }
  .flex.offset-xs9 {
    margin-left: 75%;
  }
  .flex.offset-xs10 {
    margin-left: 83.33333333333334%;
  }
  .flex.offset-xs11 {
    margin-left: 91.66666666666666%;
  }
  .flex.offset-xs12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 600px) {
  .flex.sm1 {
    -ms-flex-preferred-size: 8.333333333333332%;
    flex-basis: 8.333333333333332%;
    max-width: 8.333333333333332%;
  }
  .flex.order-sm1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .flex.sm2 {
    -ms-flex-preferred-size: 16.666666666666664%;
    flex-basis: 16.666666666666664%;
    max-width: 16.666666666666664%;
  }
  .flex.order-sm2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .flex.sm3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .flex.order-sm3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .flex.sm4 {
    -ms-flex-preferred-size: 33.33333333333333%;
    flex-basis: 33.33333333333333%;
    max-width: 33.33333333333333%;
  }
  .flex.order-sm4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .flex.sm5 {
    -ms-flex-preferred-size: 41.66666666666667%;
    flex-basis: 41.66666666666667%;
    max-width: 41.66666666666667%;
  }
  .flex.order-sm5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .flex.sm6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .flex.order-sm6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .flex.sm7 {
    -ms-flex-preferred-size: 58.333333333333336%;
    flex-basis: 58.333333333333336%;
    max-width: 58.333333333333336%;
  }
  .flex.order-sm7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .flex.sm8 {
    -ms-flex-preferred-size: 66.66666666666666%;
    flex-basis: 66.66666666666666%;
    max-width: 66.66666666666666%;
  }
  .flex.order-sm8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .flex.sm9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .flex.order-sm9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .flex.sm10 {
    -ms-flex-preferred-size: 83.33333333333334%;
    flex-basis: 83.33333333333334%;
    max-width: 83.33333333333334%;
  }
  .flex.order-sm10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .flex.sm11 {
    -ms-flex-preferred-size: 91.66666666666666%;
    flex-basis: 91.66666666666666%;
    max-width: 91.66666666666666%;
  }
  .flex.order-sm11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .flex.sm12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .flex.order-sm12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .flex.offset-sm0 {
    margin-left: 0%;
  }
  .flex.offset-sm1 {
    margin-left: 8.333333333333332%;
  }
  .flex.offset-sm2 {
    margin-left: 16.666666666666664%;
  }
  .flex.offset-sm3 {
    margin-left: 25%;
  }
  .flex.offset-sm4 {
    margin-left: 33.33333333333333%;
  }
  .flex.offset-sm5 {
    margin-left: 41.66666666666667%;
  }
  .flex.offset-sm6 {
    margin-left: 50%;
  }
  .flex.offset-sm7 {
    margin-left: 58.333333333333336%;
  }
  .flex.offset-sm8 {
    margin-left: 66.66666666666666%;
  }
  .flex.offset-sm9 {
    margin-left: 75%;
  }
  .flex.offset-sm10 {
    margin-left: 83.33333333333334%;
  }
  .flex.offset-sm11 {
    margin-left: 91.66666666666666%;
  }
  .flex.offset-sm12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 960px) {
  .flex.md1 {
    -ms-flex-preferred-size: 8.333333333333332%;
    flex-basis: 8.333333333333332%;
    max-width: 8.333333333333332%;
  }
  .flex.order-md1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .flex.md2 {
    -ms-flex-preferred-size: 16.666666666666664%;
    flex-basis: 16.666666666666664%;
    max-width: 16.666666666666664%;
  }
  .flex.order-md2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .flex.md3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .flex.order-md3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .flex.md4 {
    -ms-flex-preferred-size: 33.33333333333333%;
    flex-basis: 33.33333333333333%;
    max-width: 33.33333333333333%;
  }
  .flex.order-md4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .flex.md5 {
    -ms-flex-preferred-size: 41.66666666666667%;
    flex-basis: 41.66666666666667%;
    max-width: 41.66666666666667%;
  }
  .flex.order-md5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .flex.md6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .flex.order-md6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .flex.md7 {
    -ms-flex-preferred-size: 58.333333333333336%;
    flex-basis: 58.333333333333336%;
    max-width: 58.333333333333336%;
  }
  .flex.order-md7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .flex.md8 {
    -ms-flex-preferred-size: 66.66666666666666%;
    flex-basis: 66.66666666666666%;
    max-width: 66.66666666666666%;
  }
  .flex.order-md8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .flex.md9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .flex.order-md9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .flex.md10 {
    -ms-flex-preferred-size: 83.33333333333334%;
    flex-basis: 83.33333333333334%;
    max-width: 83.33333333333334%;
  }
  .flex.order-md10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .flex.md11 {
    -ms-flex-preferred-size: 91.66666666666666%;
    flex-basis: 91.66666666666666%;
    max-width: 91.66666666666666%;
  }
  .flex.order-md11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .flex.md12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .flex.order-md12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .flex.offset-md0 {
    margin-left: 0%;
  }
  .flex.offset-md1 {
    margin-left: 8.333333333333332%;
  }
  .flex.offset-md2 {
    margin-left: 16.666666666666664%;
  }
  .flex.offset-md3 {
    margin-left: 25%;
  }
  .flex.offset-md4 {
    margin-left: 33.33333333333333%;
  }
  .flex.offset-md5 {
    margin-left: 41.66666666666667%;
  }
  .flex.offset-md6 {
    margin-left: 50%;
  }
  .flex.offset-md7 {
    margin-left: 58.333333333333336%;
  }
  .flex.offset-md8 {
    margin-left: 66.66666666666666%;
  }
  .flex.offset-md9 {
    margin-left: 75%;
  }
  .flex.offset-md10 {
    margin-left: 83.33333333333334%;
  }
  .flex.offset-md11 {
    margin-left: 91.66666666666666%;
  }
  .flex.offset-md12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 1264px) {
  .flex.lg1 {
    -ms-flex-preferred-size: 8.333333333333332%;
    flex-basis: 8.333333333333332%;
    max-width: 8.333333333333332%;
  }
  .flex.order-lg1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .flex.lg2 {
    -ms-flex-preferred-size: 16.666666666666664%;
    flex-basis: 16.666666666666664%;
    max-width: 16.666666666666664%;
  }
  .flex.order-lg2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .flex.lg3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .flex.order-lg3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .flex.lg4 {
    -ms-flex-preferred-size: 33.33333333333333%;
    flex-basis: 33.33333333333333%;
    max-width: 33.33333333333333%;
  }
  .flex.order-lg4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .flex.lg5 {
    -ms-flex-preferred-size: 41.66666666666667%;
    flex-basis: 41.66666666666667%;
    max-width: 41.66666666666667%;
  }
  .flex.order-lg5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .flex.lg6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .flex.order-lg6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .flex.lg7 {
    -ms-flex-preferred-size: 58.333333333333336%;
    flex-basis: 58.333333333333336%;
    max-width: 58.333333333333336%;
  }
  .flex.order-lg7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .flex.lg8 {
    -ms-flex-preferred-size: 66.66666666666666%;
    flex-basis: 66.66666666666666%;
    max-width: 66.66666666666666%;
  }
  .flex.order-lg8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .flex.lg9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .flex.order-lg9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .flex.lg10 {
    -ms-flex-preferred-size: 83.33333333333334%;
    flex-basis: 83.33333333333334%;
    max-width: 83.33333333333334%;
  }
  .flex.order-lg10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .flex.lg11 {
    -ms-flex-preferred-size: 91.66666666666666%;
    flex-basis: 91.66666666666666%;
    max-width: 91.66666666666666%;
  }
  .flex.order-lg11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .flex.lg12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .flex.order-lg12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .flex.offset-lg0 {
    margin-left: 0%;
  }
  .flex.offset-lg1 {
    margin-left: 8.333333333333332%;
  }
  .flex.offset-lg2 {
    margin-left: 16.666666666666664%;
  }
  .flex.offset-lg3 {
    margin-left: 25%;
  }
  .flex.offset-lg4 {
    margin-left: 33.33333333333333%;
  }
  .flex.offset-lg5 {
    margin-left: 41.66666666666667%;
  }
  .flex.offset-lg6 {
    margin-left: 50%;
  }
  .flex.offset-lg7 {
    margin-left: 58.333333333333336%;
  }
  .flex.offset-lg8 {
    margin-left: 66.66666666666666%;
  }
  .flex.offset-lg9 {
    margin-left: 75%;
  }
  .flex.offset-lg10 {
    margin-left: 83.33333333333334%;
  }
  .flex.offset-lg11 {
    margin-left: 91.66666666666666%;
  }
  .flex.offset-lg12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 1904px) {
  .flex.xl1 {
    -ms-flex-preferred-size: 8.333333333333332%;
    flex-basis: 8.333333333333332%;
    max-width: 8.333333333333332%;
  }
  .flex.order-xl1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .flex.xl2 {
    -ms-flex-preferred-size: 16.666666666666664%;
    flex-basis: 16.666666666666664%;
    max-width: 16.666666666666664%;
  }
  .flex.order-xl2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .flex.xl3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .flex.order-xl3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .flex.xl4 {
    -ms-flex-preferred-size: 33.33333333333333%;
    flex-basis: 33.33333333333333%;
    max-width: 33.33333333333333%;
  }
  .flex.order-xl4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .flex.xl5 {
    -ms-flex-preferred-size: 41.66666666666667%;
    flex-basis: 41.66666666666667%;
    max-width: 41.66666666666667%;
  }
  .flex.order-xl5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .flex.xl6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .flex.order-xl6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .flex.xl7 {
    -ms-flex-preferred-size: 58.333333333333336%;
    flex-basis: 58.333333333333336%;
    max-width: 58.333333333333336%;
  }
  .flex.order-xl7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .flex.xl8 {
    -ms-flex-preferred-size: 66.66666666666666%;
    flex-basis: 66.66666666666666%;
    max-width: 66.66666666666666%;
  }
  .flex.order-xl8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .flex.xl9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .flex.order-xl9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .flex.xl10 {
    -ms-flex-preferred-size: 83.33333333333334%;
    flex-basis: 83.33333333333334%;
    max-width: 83.33333333333334%;
  }
  .flex.order-xl10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .flex.xl11 {
    -ms-flex-preferred-size: 91.66666666666666%;
    flex-basis: 91.66666666666666%;
    max-width: 91.66666666666666%;
  }
  .flex.order-xl11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .flex.xl12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .flex.order-xl12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .flex.offset-xl0 {
    margin-left: 0%;
  }
  .flex.offset-xl1 {
    margin-left: 8.333333333333332%;
  }
  .flex.offset-xl2 {
    margin-left: 16.666666666666664%;
  }
  .flex.offset-xl3 {
    margin-left: 25%;
  }
  .flex.offset-xl4 {
    margin-left: 33.33333333333333%;
  }
  .flex.offset-xl5 {
    margin-left: 41.66666666666667%;
  }
  .flex.offset-xl6 {
    margin-left: 50%;
  }
  .flex.offset-xl7 {
    margin-left: 58.333333333333336%;
  }
  .flex.offset-xl8 {
    margin-left: 66.66666666666666%;
  }
  .flex.offset-xl9 {
    margin-left: 75%;
  }
  .flex.offset-xl10 {
    margin-left: 83.33333333333334%;
  }
  .flex.offset-xl11 {
    margin-left: 91.66666666666666%;
  }
  .flex.offset-xl12 {
    margin-left: 100%;
  }
}
.theme--light .navigation-drawer,
.application .theme--light.navigation-drawer {
  background-color: #fff;
}
.theme--light .navigation-drawer:not(.navigation-drawer--floating) .navigation-drawer__border,
.application .theme--light.navigation-drawer:not(.navigation-drawer--floating) .navigation-drawer__border {
  background-color: rgba(0,0,0,0.12);
}
.theme--light .navigation-drawer a:not(.list__tile--active),
.application .theme--light.navigation-drawer a:not(.list__tile--active),
.theme--light .navigation-drawer .list,
.application .theme--light.navigation-drawer .list {
  color: rgba(0,0,0,0.87);
}
.theme--light .navigation-drawer .divider,
.application .theme--light.navigation-drawer .divider {
  background-color: rgba(0,0,0,0.12);
}
.theme--light .navigation-drawer .list__tile:not(.list__tile--active),
.application .theme--light.navigation-drawer .list__tile:not(.list__tile--active) {
  color: rgba(0,0,0,0.87);
}
.theme--light .navigation-drawer .list__tile:not(.list__tile--active) .icon,
.application .theme--light.navigation-drawer .list__tile:not(.list__tile--active) .icon {
  color: rgba(0,0,0,0.54);
}
.theme--light .navigation-drawer .list__tile__sub-title,
.application .theme--light.navigation-drawer .list__tile__sub-title {
  color: rgba(0,0,0,0.54);
}
.theme--light .navigation-drawer .list--group__header--active .list__tile:after,
.application .theme--light.navigation-drawer .list--group__header--active .list__tile:after,
.theme--light .navigation-drawer .list--group__header--active + .list--group:after,
.application .theme--light.navigation-drawer .list--group__header--active + .list--group:after {
  background: rgba(0,0,0,0.12);
}
.theme--dark .navigation-drawer,
.application .theme--dark.navigation-drawer {
  background-color: #424242;
}
.theme--dark .navigation-drawer:not(.navigation-drawer--floating) .navigation-drawer__border,
.application .theme--dark.navigation-drawer:not(.navigation-drawer--floating) .navigation-drawer__border {
  background-color: rgba(255,255,255,0.12);
}
.theme--dark .navigation-drawer a:not(.list__tile--active),
.application .theme--dark.navigation-drawer a:not(.list__tile--active),
.theme--dark .navigation-drawer .list,
.application .theme--dark.navigation-drawer .list {
  color: #fff;
}
.theme--dark .navigation-drawer .divider,
.application .theme--dark.navigation-drawer .divider {
  background-color: rgba(255,255,255,0.12);
}
.theme--dark .navigation-drawer .list__tile:not(.list__tile--active),
.application .theme--dark.navigation-drawer .list__tile:not(.list__tile--active) {
  color: #fff;
}
.theme--dark .navigation-drawer .list__tile:not(.list__tile--active) .icon,
.application .theme--dark.navigation-drawer .list__tile:not(.list__tile--active) .icon {
  color: #fff;
}
.theme--dark .navigation-drawer .list__tile__sub-title,
.application .theme--dark.navigation-drawer .list__tile__sub-title {
  color: rgba(255,255,255,0.7);
}
.theme--dark .navigation-drawer .list--group__header--active .list__tile:after,
.application .theme--dark.navigation-drawer .list--group__header--active .list__tile:after,
.theme--dark .navigation-drawer .list--group__header--active + .list--group:after,
.application .theme--dark.navigation-drawer .list--group__header--active + .list--group:after {
  background: rgba(255,255,255,0.12);
}
.navigation-drawer {
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  padding: 0 0 100px;
  pointer-events: auto;
  -webkit-transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  top: 0;
  left: 0;
  will-change: transform;
  z-index: 3;
}
.navigation-drawer__border {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
}
.navigation-drawer.navigation-drawer--right:after {
  left: 0;
  right: initial;
}
.navigation-drawer--close.navigation-drawer:not(.navigation-drawer--right) {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.navigation-drawer--close.navigation-drawer--right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}
.navigation-drawer--right {
  left: auto;
  right: 0;
}
.navigation-drawer--right > .navigation-drawer__border {
  right: auto;
  left: 0;
}
.navigation-drawer--absolute {
  position: absolute;
}
.navigation-drawer--fixed {
  position: fixed;
}
.navigation-drawer--floating:after {
  display: none;
}
.navigation-drawer--mini-variant {
  overflow: hidden;
}
.navigation-drawer--mini-variant .list__tile__action,
.navigation-drawer--mini-variant .list__tile__avatar {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-width: 48px;
}
.navigation-drawer--mini-variant .list__tile__content,
.navigation-drawer--mini-variant .list__tile:after {
  opacity: 0;
}
.navigation-drawer--mini-variant .subheader,
.navigation-drawer--mini-variant .divider,
.navigation-drawer--mini-variant .list--group {
  display: none !important;
}
.navigation-drawer--temporary,
.navigation-drawer--is-mobile {
  z-index: 6;
}
.navigation-drawer--temporary:not(.navigation-drawer--close),
.navigation-drawer--is-mobile:not(.navigation-drawer--close) {
  -webkit-box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12);
          box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12);
}
.navigation-drawer .list {
  background: inherit;
}
.navigation-drawer > .list .list__tile {
  -webkit-transition: none;
  transition: none;
  font-weight: 500;
}
.navigation-drawer > .list .list__tile--active .list__tile__title {
  color: inherit;
}
.navigation-drawer > .list .list--group .list__tile {
  font-weight: 400;
}
.navigation-drawer > .list .list--group__header--active:after {
  background: transparent;
}
.navigation-drawer > .list:not(.list--dense) .list__tile {
  font-size: 14px;
}
.theme--light .pagination__item,
.application .theme--light.pagination__item {
  background: #fff;
  color: #000;
}
.theme--light .pagination__item--active,
.application .theme--light.pagination__item--active {
  color: #fff;
}
.theme--light .pagination__navigation,
.application .theme--light.pagination__navigation {
  background: #fff;
}
.theme--light .pagination__navigation .icon,
.application .theme--light.pagination__navigation .icon {
  color: rgba(0,0,0,0.54);
}
.theme--dark .pagination__item,
.application .theme--dark.pagination__item {
  background: #424242;
  color: #fff;
}
.theme--dark .pagination__item--active,
.application .theme--dark.pagination__item--active {
  color: #fff;
}
.theme--dark .pagination__navigation,
.application .theme--dark.pagination__navigation {
  background: #424242;
}
.theme--dark .pagination__navigation .icon,
.application .theme--dark.pagination__navigation .icon {
  color: #fff;
}
.pagination {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  list-style-type: none;
  margin: 0;
  overflow-x: auto;
  max-width: 100%;
  padding: 0;
}
.pagination > li {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.pagination--circle .pagination__item,
.pagination--circle .pagination__more,
.pagination--circle .pagination__navigation {
  border-radius: 50%;
}
.pagination--disabled {
  pointer-events: none;
  opacity: 0.6;
}
.pagination__item {
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  border-radius: 4px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  background: transparent;
  height: 34px;
  width: 34px;
  margin: 0.3rem;
  text-decoration: none;
  -webkit-transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.pagination__item--active {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}
.pagination__navigation {
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
  height: 2rem;
  border-radius: 4px;
  width: 2rem;
  margin: 0.3rem 10px;
}
.pagination__navigation .icon {
  font-size: 2rem;
  -webkit-transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  vertical-align: middle;
}
.pagination__navigation--disabled {
  opacity: 0.6;
  pointer-events: none;
}
.pagination__more {
  margin: 0.3rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 2rem;
  width: 2rem;
}
.parallax {
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.parallax__image-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  contain: strict;
}
.parallax__image {
  position: absolute;
  bottom: 0;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  display: none;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  will-change: transform;
  -webkit-transition: 0.3s opacity cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s opacity cubic-bezier(0.25, 0.8, 0.5, 1);
  z-index: 1;
}
.parallax__content {
  color: #fff;
  height: 100%;
  z-index: 2;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 1rem;
}
.progress-circular {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.progress-circular--indeterminate svg {
  -webkit-animation: progress-circular-rotate 1.4s linear infinite;
          animation: progress-circular-rotate 1.4s linear infinite;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: 0;
}
.progress-circular--indeterminate .progress-circular__overlay {
  -webkit-animation: progress-circular-dash 1.4s ease-in-out infinite;
          animation: progress-circular-dash 1.4s ease-in-out infinite;
  stroke-linecap: round;
  stroke-dasharray: 80, 200;
  stroke-dashoffset: 0px;
}
.progress-circular__underlay {
  stroke: rgba(0,0,0,0.1);
  z-index: 1;
}
.progress-circular__overlay {
  stroke: currentColor;
  z-index: 2;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}
.progress-circular__info {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
@-webkit-keyframes progress-circular-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -125px;
  }
}
@keyframes progress-circular-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -125px;
  }
}
@-webkit-keyframes progress-circular-rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes progress-circular-rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.radio-group .input-group__details:before,
.radio-group .input-group__details:after {
  display: none;
}
.radio-group .input-group {
  padding: 0;
}
.radio-group--column .input-group__input {
  display: block;
}
.radio-group--row .input-group__input {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.radio-group.input-group--error .radio label,
.radio-group.input-group--error .radio .icon--selection-control {
  color: inherit;
}
/* Theme */
.theme--light .input-group--slider label,
.application .theme--light.input-group--slider label {
  color: rgba(0,0,0,0.54);
}
.theme--light .input-group--slider .slider__track,
.application .theme--light.input-group--slider .slider__track,
.theme--light .input-group--slider .slider__track-fill,
.application .theme--light.input-group--slider .slider__track-fill {
  background: rgba(0,0,0,0.26);
}
.theme--light .input-group--slider .slider__track__container:after,
.application .theme--light.input-group--slider .slider__track__container:after {
  border: 1px solid rgba(0,0,0,0.87);
}
.theme--light .input-group--slider .slider__tick,
.application .theme--light.input-group--slider .slider__tick {
  border: 1px solid rgba(0,0,0,0.87);
}
.theme--light .input-group--slider:not(.input-group--dirty) .slider__thumb--label,
.application .theme--light.input-group--slider:not(.input-group--dirty) .slider__thumb--label {
  background: rgba(0,0,0,0.26);
}
.theme--light .input-group--slider:not(.input-group--dirty) .slider__thumb,
.application .theme--light.input-group--slider:not(.input-group--dirty) .slider__thumb {
  border: 3px solid rgba(0,0,0,0.26);
}
.theme--light .input-group--slider:not(.input-group--dirty):focus .slider__thumb,
.application .theme--light.input-group--slider:not(.input-group--dirty):focus .slider__thumb {
  border: 3px solid rgba(0,0,0,0.38);
}
.theme--light .input-group--slider.input-group--disabled .slider__thumb,
.application .theme--light.input-group--slider.input-group--disabled .slider__thumb {
  background: none;
  border: 3px solid rgba(0,0,0,0.26);
}
.theme--light .input-group--slider.input-group--disabled.input-group--dirty .slider__thumb,
.application .theme--light.input-group--slider.input-group--disabled.input-group--dirty .slider__thumb {
  background: rgba(0,0,0,0.26);
  border: 0px solid transparent;
}
.theme--light .input-group--slider:focus .slider__track,
.application .theme--light.input-group--slider:focus .slider__track {
  background: rgba(0,0,0,0.38);
}
.theme--dark .input-group--slider label,
.application .theme--dark.input-group--slider label {
  color: rgba(255,255,255,0.7);
}
.theme--dark .input-group--slider .slider__track,
.application .theme--dark.input-group--slider .slider__track,
.theme--dark .input-group--slider .slider__track-fill,
.application .theme--dark.input-group--slider .slider__track-fill {
  background: rgba(255,255,255,0.2);
}
.theme--dark .input-group--slider .slider__track__container:after,
.application .theme--dark.input-group--slider .slider__track__container:after {
  border: 1px solid #fff;
}
.theme--dark .input-group--slider .slider__tick,
.application .theme--dark.input-group--slider .slider__tick {
  border: 1px solid #fff;
}
.theme--dark .input-group--slider:not(.input-group--dirty) .slider__thumb--label,
.application .theme--dark.input-group--slider:not(.input-group--dirty) .slider__thumb--label {
  background: rgba(255,255,255,0.2);
}
.theme--dark .input-group--slider:not(.input-group--dirty) .slider__thumb,
.application .theme--dark.input-group--slider:not(.input-group--dirty) .slider__thumb {
  border: 3px solid rgba(255,255,255,0.2);
}
.theme--dark .input-group--slider:not(.input-group--dirty):focus .slider__thumb,
.application .theme--dark.input-group--slider:not(.input-group--dirty):focus .slider__thumb {
  border: 3px solid rgba(255,255,255,0.3);
}
.theme--dark .input-group--slider.input-group--disabled .slider__thumb,
.application .theme--dark.input-group--slider.input-group--disabled .slider__thumb {
  background: none;
  border: 3px solid rgba(255,255,255,0.2);
}
.theme--dark .input-group--slider.input-group--disabled.input-group--dirty .slider__thumb,
.application .theme--dark.input-group--slider.input-group--disabled.input-group--dirty .slider__thumb {
  background: rgba(255,255,255,0.2);
  border: 0px solid transparent;
}
.theme--dark .input-group--slider:focus .slider__track,
.application .theme--dark.input-group--slider:focus .slider__track {
  background: rgba(255,255,255,0.3);
}
/** Input Group */
.input-group.input-group--slider {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-right: 16px;
}
.input-group.input-group--slider .input-group__details:before,
.input-group.input-group--slider .input-group__details:after {
  display: none;
}
.input-group.input-group--slider .input-group__input {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
}
.input-group.input-group--slider label {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 18px;
  -webkit-transform: none;
          transform: none;
}
.input-group.input-group--slider label + .input-group__input {
  margin-left: 16px;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}
.input-group.input-group--slider.input-group--active .slider__thumb {
  -webkit-transform: translateY(-50%) scale(1.2);
          transform: translateY(-50%) scale(1.2);
}
.input-group.input-group--slider.input-group--active .slider__track {
  -webkit-transition: none;
  transition: none;
}
.input-group.input-group--slider.input-group--active .slider__thumb-container--label .slider__thumb {
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
}
.input-group.input-group--slider.input-group--active .slider__thumb-container--label .slider__thumb:hover {
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
}
.input-group.input-group--slider.input-group--active .slider__thumb-container,
.input-group.input-group--slider.input-group--active .slider__track-fill {
  -webkit-transition: none;
  transition: none;
}
.input-group.input-group--slider.input-group--active.input-group--ticks .slider__track__container:after,
.input-group.input-group--slider.input-group--active.input-group--ticks .slider__tick {
  opacity: 1;
}
.input-group.input-group--slider.input-group--disabled {
  pointer-events: none;
}
.input-group.input-group--slider.input-group--disabled .slider__thumb {
  -webkit-transform: translateY(-50%) scale(0.5);
          transform: translateY(-50%) scale(0.5);
  background: transparent;
}
.input-group.input-group--slider.input-group--disabled.input-group--dirty {
  border-color: transparent;
}
.input-group.input-group--slider.input-group--prepend-icon .slider {
  margin-left: 40px;
}
.input-group.input-group--slider.input-group--append-icon .slider {
  margin-right: 40px;
}
/** Slider */
.slider {
  cursor: default;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  height: 30px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
/** Thumb/Track/Ticks */
.slider__track__container {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 2px;
  width: 100%;
  overflow: hidden;
}
.slider__track__container:after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 2px;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 2px;
  opacity: 0;
}
.slider__track,
.slider__thumb,
.slider__tick {
  position: absolute;
  top: 0;
}
.slider__track {
  height: 2px;
  left: 0;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  -webkit-transform-origin: right;
          transform-origin: right;
  overflow: hidden;
  width: 100%;
}
.slider__track-fill {
  position: absolute;
  left: 0;
  height: 2px;
  -webkit-transform-origin: left;
          transform-origin: left;
  width: 100%;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.slider__ticks-container {
  position: absolute;
  left: 0;
  height: 2px;
  width: 100%;
  top: 50%;
  overflow: hidden;
}
.slider__tick {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  opacity: 0;
}
.slider__thumb-container {
  position: absolute;
  top: 50%;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.slider__thumb {
  width: 16px;
  height: 16px;
  left: -8px;
  top: 50%;
  border-radius: 50%;
  background: transparent;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  -webkit-transform: translateY(-50%) scale(0.8);
          transform: translateY(-50%) scale(0.8);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
/** Thumb Label */
.slider__thumb--label__container {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.slider__thumb--label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 12px;
  color: #fff;
  width: 28px;
  height: 28px;
  border-radius: 50% 50% 0;
  position: absolute;
  left: -14px;
  top: -40px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.slider__thumb--label span {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.slider__track,
.slider__track-fill {
  position: absolute;
}
.snack {
  background-color: #323232;
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 0;
  pointer-events: none;
  visibility: visible;
  z-index: 1000;
}
.snack--absolute {
  position: absolute;
}
.snack--top {
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}
.snack--bottom {
  bottom: 48px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}
.snack--left {
  left: 8px;
  right: initial;
  -webkit-transform: none;
          transform: none;
}
.snack--left.snack--top {
  top: 8px;
}
.snack--left.snack--bottom {
  bottom: 56px;
}
.snack--right {
  left: initial;
  right: 8px;
  -webkit-transform: none;
          transform: none;
}
.snack--right.snack--top {
  top: 8px;
}
.snack--right.snack--bottom {
  top: initial;
  bottom: 56px;
}
.snack__content {
  background-color: inherit;
  padding: 14px 24px;
  border-radius: 2px;
  pointer-events: auto;
  max-width: 568px;
  min-width: 288px;
  height: 48px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-transition: 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
  position: relative !important;
  -webkit-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
}
.snack__content .btn {
  margin: 0 0 0 48px;
}
.snack--multi-line .snack__content {
  height: 80px;
  padding: 24px;
}
.snack--bottom.snack--multi-line,
.snack--right.snack--multi-line {
  bottom: 80px;
}
.snack--vertical .snack__content {
  height: 112px;
  padding: 24px 24px 14px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: initial;
      -ms-flex-align: initial;
          align-items: initial;
}
.snack--vertical .snack__content .btn {
  -ms-flex-item-align: end;
      align-self: flex-end;
}
.snack--bottom.snack--vertical,
.snack--right.snack--vertical {
  bottom: 112px;
}
@media only screen and (max-width: 599px) {
  .snack {
    width: 100%;
    left: 0;
    right: initial;
    -webkit-transform: none;
    transform: none;
  }
  .snack--right.snack--top,
  .snack--left.snack--top {
    top: 0;
  }
  .snack--right.snack--bottom,
  .snack--left.snack--bottom {
    bottom: 48px;
  }
  .snack__content {
    border-radius: 0;
    max-width: 100%;
    width: 100%;
  }
  .snack__content .btn {
    margin: 0 0 0 24px;
  }
}
.speed-dial {
  position: relative;
}
.speed-dial--absolute {
  position: absolute;
}
.speed-dial--fixed {
  position: fixed;
}
.speed-dial--top:not(.speed-dial--absolute) {
  top: 16px;
}
.speed-dial--top.speed-dial--absolute {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.speed-dial--bottom:not(.speed-dial--absolute) {
  bottom: 16px;
}
.speed-dial--bottom.speed-dial--absolute {
  bottom: 50%;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}
.speed-dial--left {
  left: 16px;
}
.speed-dial--right {
  right: 16px;
}
.speed-dial--direction-left .speed-dial__list,
.speed-dial--direction-right .speed-dial__list {
  height: 100%;
  top: 0;
}
.speed-dial--direction-top .speed-dial__list,
.speed-dial--direction-bottom .speed-dial__list {
  left: 0;
  width: 100%;
}
.speed-dial--direction-top .speed-dial__list {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  bottom: 100%;
}
.speed-dial--direction-right .speed-dial__list {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  left: 100%;
}
.speed-dial--direction-bottom .speed-dial__list {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  top: 100%;
}
.speed-dial--direction-left .speed-dial__list {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  right: 100%;
}
/** Elements */
.speed-dial__list {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
}
.speed-dial__list .btn:nth-child(1) {
  -webkit-transition-delay: 0.05s;
          transition-delay: 0.05s;
}
.speed-dial__list .btn:nth-child(2) {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.speed-dial__list .btn:nth-child(3) {
  -webkit-transition-delay: 0.15s;
          transition-delay: 0.15s;
}
.speed-dial__list .btn:nth-child(4) {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}
.speed-dial__list .btn:nth-child(5) {
  -webkit-transition-delay: 0.25s;
          transition-delay: 0.25s;
}
.speed-dial__list .btn:nth-child(6) {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}
.speed-dial__list .btn:nth-child(7) {
  -webkit-transition-delay: 0.35s;
          transition-delay: 0.35s;
}
.theme--light .stepper,
.application .theme--light.stepper {
  background: #fff;
}
.theme--light .stepper .stepper__step:not(.stepper__step--active):not(.stepper__step--complete):not(.stepper__step--error) .stepper__step__step,
.application .theme--light.stepper .stepper__step:not(.stepper__step--active):not(.stepper__step--complete):not(.stepper__step--error) .stepper__step__step {
  background: rgba(0,0,0,0.38);
}
.theme--light .stepper .stepper__step__step,
.application .theme--light.stepper .stepper__step__step {
  color: #fff;
}
.theme--light .stepper .stepper__step__step .icon,
.application .theme--light.stepper .stepper__step__step .icon {
  color: #fff;
}
.theme--light .stepper .stepper__header .divider,
.application .theme--light.stepper .stepper__header .divider {
  background: rgba(0,0,0,0.12);
}
.theme--light .stepper .stepper__step--active .stepper__label,
.application .theme--light.stepper .stepper__step--active .stepper__label {
  text-shadow: 0px 0px 0px #000;
}
.theme--light .stepper .stepper__step--editable:hover,
.application .theme--light.stepper .stepper__step--editable:hover {
  background: rgba(0,0,0,0.06);
}
.theme--light .stepper .stepper__step--editable:hover .stepper__label,
.application .theme--light.stepper .stepper__step--editable:hover .stepper__label {
  text-shadow: 0px 0px 0px #000;
}
.theme--light .stepper .stepper__step--complete .stepper__label,
.application .theme--light.stepper .stepper__step--complete .stepper__label {
  color: rgba(0,0,0,0.87);
}
.theme--light .stepper .stepper__step--inactive.stepper__step--editable:not(.stepper__step--error):hover .stepper__step__step,
.application .theme--light.stepper .stepper__step--inactive.stepper__step--editable:not(.stepper__step--error):hover .stepper__step__step {
  background: rgba(0,0,0,0.54);
}
.theme--light .stepper .stepper__label,
.application .theme--light.stepper .stepper__label {
  color: rgba(0,0,0,0.38);
}
.theme--light .stepper .stepper__label small,
.application .theme--light.stepper .stepper__label small {
  color: rgba(0,0,0,0.54);
}
.theme--light .stepper--non-linear .stepper__step:not(.stepper__step--complete):not(.stepper__step--error) .stepper__label,
.application .theme--light.stepper--non-linear .stepper__step:not(.stepper__step--complete):not(.stepper__step--error) .stepper__label {
  color: rgba(0,0,0,0.54);
}
.theme--light .stepper--vertical .stepper__content:not(:last-child),
.application .theme--light.stepper--vertical .stepper__content:not(:last-child) {
  border-left: 1px solid rgba(0,0,0,0.12);
}
.theme--dark .stepper,
.application .theme--dark.stepper {
  background: #303030;
}
.theme--dark .stepper .stepper__step:not(.stepper__step--active):not(.stepper__step--complete):not(.stepper__step--error) .stepper__step__step,
.application .theme--dark.stepper .stepper__step:not(.stepper__step--active):not(.stepper__step--complete):not(.stepper__step--error) .stepper__step__step {
  background: rgba(255,255,255,0.5);
}
.theme--dark .stepper .stepper__step__step,
.application .theme--dark.stepper .stepper__step__step {
  color: #fff;
}
.theme--dark .stepper .stepper__step__step .icon,
.application .theme--dark.stepper .stepper__step__step .icon {
  color: #fff;
}
.theme--dark .stepper .stepper__header .divider,
.application .theme--dark.stepper .stepper__header .divider {
  background: rgba(255,255,255,0.12);
}
.theme--dark .stepper .stepper__step--active .stepper__label,
.application .theme--dark.stepper .stepper__step--active .stepper__label {
  text-shadow: 0px 0px 0px #fff;
}
.theme--dark .stepper .stepper__step--editable:hover,
.application .theme--dark.stepper .stepper__step--editable:hover {
  background: rgba(255,255,255,0.06);
}
.theme--dark .stepper .stepper__step--editable:hover .stepper__label,
.application .theme--dark.stepper .stepper__step--editable:hover .stepper__label {
  text-shadow: 0px 0px 0px #fff;
}
.theme--dark .stepper .stepper__step--complete .stepper__label,
.application .theme--dark.stepper .stepper__step--complete .stepper__label {
  color: rgba(255,255,255,0.87);
}
.theme--dark .stepper .stepper__step--inactive.stepper__step--editable:not(.stepper__step--error):hover .stepper__step__step,
.application .theme--dark.stepper .stepper__step--inactive.stepper__step--editable:not(.stepper__step--error):hover .stepper__step__step {
  background: rgba(255,255,255,0.75);
}
.theme--dark .stepper .stepper__label,
.application .theme--dark.stepper .stepper__label {
  color: rgba(255,255,255,0.5);
}
.theme--dark .stepper .stepper__label small,
.application .theme--dark.stepper .stepper__label small {
  color: rgba(255,255,255,0.7);
}
.theme--dark .stepper--non-linear .stepper__step:not(.stepper__step--complete):not(.stepper__step--error) .stepper__label,
.application .theme--dark.stepper--non-linear .stepper__step:not(.stepper__step--complete):not(.stepper__step--error) .stepper__label {
  color: rgba(255,255,255,0.7);
}
.theme--dark .stepper--vertical .stepper__content:not(:last-child),
.application .theme--dark.stepper--vertical .stepper__content:not(:last-child) {
  border-left: 1px solid rgba(255,255,255,0.12);
}
.stepper {
  overflow: hidden;
  position: relative;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.stepper__header {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.stepper__header .divider {
  -ms-flex-item-align: center;
      align-self: center;
  margin: 0 -16px;
}
.stepper__items {
  position: relative;
  overflow: hidden;
}
.stepper__step__step {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 12px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 24px;
  margin-right: 8px;
  min-width: 24px;
  width: 24px;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.stepper__step__step .icon {
  font-size: 18px;
}
.stepper__step {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 24px;
  position: relative;
}
.stepper__step--active .stepper__label {
  -webkit-transition: 0.3s cubic-bezier(0.4, 0, 0.6, 1);
  transition: 0.3s cubic-bezier(0.4, 0, 0.6, 1);
}
.stepper__step--editable {
  cursor: pointer;
}
.stepper__step.stepper__step--error .stepper__step__step {
  background: transparent;
  color: inherit;
}
.stepper__step.stepper__step--error .stepper__step__step .icon {
  font-size: 24px;
  color: inherit;
}
.stepper__step.stepper__step--error .stepper__label {
  color: inherit;
  text-shadow: none;
  font-weight: 500;
}
.stepper__step.stepper__step--error .stepper__label small {
  color: inherit;
}
.stepper__label {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: left;
}
.stepper__label small {
  font-size: 12px;
  font-weight: 300;
  text-shadow: none;
}
.stepper__wrapper {
  overflow: hidden;
  -webkit-transition: none;
  transition: none;
}
.stepper__content {
  top: initial;
  bottom: 0;
  padding: 16px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
}
.stepper__content .btn {
  margin-left: 0;
}
.stepper--is-booted .stepper__content,
.stepper--is-booted .stepper__wrapper {
  -webkit-transition: 0.4s cubic-bezier(0.4, 0, 0.6, 1);
  transition: 0.4s cubic-bezier(0.4, 0, 0.6, 1);
}
.stepper--vertical {
  padding-bottom: 36px;
}
.stepper--vertical .stepper__content {
  margin: -8px -36px -16px 36px;
  padding: 16px 60px 16px 23px;
  width: auto;
}
.stepper--vertical .stepper__step {
  padding: 24px 24px 16px;
}
.stepper--vertical .stepper__step__step {
  margin-right: 12px;
}
.stepper--alt-labels .stepper__header .divider {
  margin: 35px -67px 0;
  -ms-flex-item-align: start;
      align-self: flex-start;
}
.stepper--alt-labels .stepper__step {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-preferred-size: 175px;
      flex-basis: 175px;
}
.stepper--alt-labels .stepper__step small {
  -ms-flex-item-align: center;
      align-self: center;
}
.stepper--alt-labels .stepper__step__step {
  margin-right: 0;
  margin-bottom: 12px;
}
@media only screen and (max-width: 959px) {
  .stepper:not(.stepper--vertical) .stepper__label {
    display: none;
  }
  .stepper:not(.stepper--vertical) .stepper__step__step {
    margin-right: 0;
  }
}
.theme--light .subheader,
.application .theme--light.subheader {
  color: rgba(0,0,0,0.54);
}
.theme--dark .subheader,
.application .theme--dark.subheader {
  color: rgba(255,255,255,0.7);
}
.subheader {
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding: 0 16px 0 16px;
}
.subheader--inset {
  margin-left: 56px;
}
/** Theme */
.theme--light .switch:not(.input-group--dirty) .input-group--selection-controls__container,
.application .theme--light.switch:not(.input-group--dirty) .input-group--selection-controls__container {
  color: rgba(0,0,0,0.38) !important;
}
.theme--light .switch .input-group--selection-controls__ripple:after,
.application .theme--light.switch .input-group--selection-controls__ripple:after {
  background-color: #fafafa;
}
.theme--light .switch .input-group--selection-controls__ripple:not(.input-group--selection-controls__ripple--active),
.application .theme--light.switch .input-group--selection-controls__ripple:not(.input-group--selection-controls__ripple--active) {
  color: rgba(0,0,0,0.38);
}
.theme--light .switch .input-group--selection-controls__ripple--active:after,
.application .theme--light.switch .input-group--selection-controls__ripple--active:after {
  background-color: currentColor;
}
.theme--light .switch .input-group--selection-controls__toggle,
.application .theme--light.switch .input-group--selection-controls__toggle {
  color: rgba(0,0,0,0.38);
}
.theme--light .switch .input-group--selection-controls__toggle--active,
.application .theme--light.switch .input-group--selection-controls__toggle--active {
  color: inherit;
}
.theme--light .switch.input-group--disabled .input-group--selection-controls__ripple:after,
.application .theme--light.switch.input-group--disabled .input-group--selection-controls__ripple:after {
  background-color: #bdbdbd !important;
}
.theme--light .switch.input-group--disabled .input-group--selection-controls__toggle,
.application .theme--light.switch.input-group--disabled .input-group--selection-controls__toggle {
  color: rgba(0,0,0,0.12) !important;
}
.theme--dark .switch:not(.input-group--dirty) .input-group--selection-controls__container,
.application .theme--dark.switch:not(.input-group--dirty) .input-group--selection-controls__container {
  color: rgba(255,255,255,0.3) !important;
}
.theme--dark .switch .input-group--selection-controls__ripple:after,
.application .theme--dark.switch .input-group--selection-controls__ripple:after {
  background-color: #bdbdbd;
}
.theme--dark .switch .input-group--selection-controls__ripple:not(.input-group--selection-controls__ripple--active),
.application .theme--dark.switch .input-group--selection-controls__ripple:not(.input-group--selection-controls__ripple--active) {
  color: rgba(255,255,255,0.3);
}
.theme--dark .switch .input-group--selection-controls__ripple--active:after,
.application .theme--dark.switch .input-group--selection-controls__ripple--active:after {
  background-color: currentColor;
}
.theme--dark .switch .input-group--selection-controls__toggle,
.application .theme--dark.switch .input-group--selection-controls__toggle {
  color: rgba(255,255,255,0.3);
}
.theme--dark .switch .input-group--selection-controls__toggle--active,
.application .theme--dark.switch .input-group--selection-controls__toggle--active {
  color: inherit;
}
.theme--dark .switch.input-group--disabled .input-group--selection-controls__ripple:after,
.application .theme--dark.switch.input-group--disabled .input-group--selection-controls__ripple:after {
  background-color: #424242 !important;
}
.theme--dark .switch.input-group--disabled .input-group--selection-controls__toggle,
.application .theme--dark.switch.input-group--disabled .input-group--selection-controls__toggle {
  color: rgba(255,255,255,0.1) !important;
}
.input-group.input-group--selection-controls {
  z-index: 0;
}
.input-group.input-group--selection-controls.switch {
  min-width: 36px;
}
.input-group.input-group--selection-controls.switch .input-group--selection-controls__container {
  color: inherit;
  position: relative;
}
.input-group.input-group--selection-controls.switch .input-group--selection-controls__container[class*="--text"] .input-group--selection-controls__ripple--active:after {
  background-color: currentColor;
}
.input-group.input-group--selection-controls.switch .input-group--selection-controls__toggle {
  background-color: currentColor;
  color: inherit;
  position: absolute;
  height: 14px;
  top: 50%;
  left: 0;
  width: 34px;
  border-radius: 8px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.input-group.input-group--selection-controls.switch .input-group--selection-controls__toggle.input-group--selection-controls__toggle--active {
  opacity: 0.5;
}
.input-group.input-group--selection-controls.switch .input-group--selection-controls__ripple {
  -webkit-transform: translate(-15px, -24px);
          transform: translate(-15px, -24px);
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 1;
}
.input-group.input-group--selection-controls.switch .input-group--selection-controls__ripple:after {
  content: '';
  position: absolute;
  display: inline-block;
  cursor: pointer;
  width: 20px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 20px;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}
.input-group.input-group--selection-controls.switch .input-group--selection-controls__ripple--active {
  -webkit-transform: translate(2px, -24px);
          transform: translate(2px, -24px);
}
.input-group.input-group--selection-controls.switch label {
  padding-left: 14px;
}
/* Theme */
.theme--light .system-bar,
.application .theme--light.system-bar {
  background-color: #e0e0e0;
  color: rgba(0,0,0,0.54);
}
.theme--light .system-bar .icon,
.application .theme--light.system-bar .icon {
  color: rgba(0,0,0,0.54);
}
.theme--light .system-bar--lights-out,
.application .theme--light.system-bar--lights-out {
  background-color: rgba(255,255,255,0.7) !important;
}
.theme--dark .system-bar,
.application .theme--dark.system-bar {
  background-color: #000;
  color: rgba(255,255,255,0.7);
}
.theme--dark .system-bar .icon,
.application .theme--dark.system-bar .icon {
  color: rgba(255,255,255,0.7);
}
.theme--dark .system-bar--lights-out,
.application .theme--dark.system-bar--lights-out {
  background-color: rgba(0,0,0,0.2) !important;
}
.system-bar {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  padding: 0 8px;
}
.system-bar .icon {
  font-size: 16px;
}
.system-bar--fixed,
.system-bar--absolute {
  left: 0;
  top: 0;
  width: 100%;
  z-index: 3;
}
.system-bar--fixed {
  position: fixed;
}
.system-bar--absolute {
  position: absolute;
}
.system-bar--status .icon {
  margin-right: 4px;
}
.system-bar--window .icon {
  font-size: 20px;
  margin-right: 8px;
}
/* Theme */
.theme--light .tabs__bar .tabs__li,
.application .theme--light.tabs__bar .tabs__li {
  color: rgba(0,0,0,0.87);
}
.theme--light .tabs__bar .tabs__li.tabs__item--disabled,
.application .theme--light.tabs__bar .tabs__li.tabs__item--disabled {
  color: rgba(0,0,0,0.26);
}
.theme--light .tabs__bar .tabs__bar,
.application .theme--light.tabs__bar .tabs__bar {
  background-color: #fff;
}
.theme--light .tabs__bar .tabs__bar .icon--left,
.application .theme--light.tabs__bar .tabs__bar .icon--left,
.theme--light .tabs__bar .tabs__bar .icon--right,
.application .theme--light.tabs__bar .tabs__bar .icon--right {
  color: rgba(0,0,0,0.38);
}
.theme--dark .tabs__bar .tabs__li,
.application .theme--dark.tabs__bar .tabs__li {
  color: #fff;
}
.theme--dark .tabs__bar .tabs__li.tabs__item--disabled,
.application .theme--dark.tabs__bar .tabs__li.tabs__item--disabled {
  color: rgba(255,255,255,0.3);
}
.theme--dark .tabs__bar .tabs__bar,
.application .theme--dark.tabs__bar .tabs__bar {
  background-color: #424242;
}
.theme--dark .tabs__bar .tabs__bar .icon--left,
.application .theme--dark.tabs__bar .tabs__bar .icon--left,
.theme--dark .tabs__bar .tabs__bar .icon--right,
.application .theme--dark.tabs__bar .tabs__bar .icon--right {
  color: rgba(255,255,255,0.5);
}
.tabs {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.tabs--grow .tabs__bar .tabs__li {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.tabs--fixed > .tabs__bar .tabs__li {
  min-width: 160px;
  max-width: 264px;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 160px;
          flex: 0 1 160px;
}
.tabs--centered .tabs__bar .tabs__container {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.tabs--icons .tabs__bar {
  height: 72px;
}
.tabs--icons .tabs__item {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.tabs.tabs--mobile .tabs__bar .icon--left,
.tabs.tabs--mobile .tabs__bar .icon--right {
  display: none;
}
.tabs.tabs--mobile .tabs__bar .tabs__wrapper--scrollable.tabs__wrapper--overflow {
  overflow: hidden !important;
}
.tabs:not(.tabs--grow):not(.tabs--mobile) .tabs__item {
  padding: 0 24px;
}
.tabs:not(.tabs--centered):not(.tabs--grow):not(.tabs--mobile) .tabs__wrapper--scrollable {
  margin: 0 60px;
  overflow: hidden !important;
}
.tabs__bar {
  width: 100%;
  position: relative;
  height: 48px;
}
.tabs__bar .icon--left,
.tabs__bar .icon--right {
  position: absolute;
  top: 0;
  width: 32px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.tabs__bar .icon--left {
  left: 28px;
}
.tabs__bar .icon--right {
  right: 28px;
}
.tabs__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  padding: 0;
  top: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  list-style: none;
  will-change: transform;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.86, 0, 0.07, 1);
  transition: -webkit-transform 0.6s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.6s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.6s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.6s cubic-bezier(0.86, 0, 0.07, 1);
}
.tabs__container > li:not(.tabs__slider) {
  height: 100%;
}
.tabs__container-left {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.tabs__wrapper {
  position: relative;
  overflow-x: auto;
  height: inherit;
}
.tabs__item {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: inherit !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  padding: 0 12px;
  position: relative;
  opacity: 0.7;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tabs__item .icon {
  color: inherit;
  margin: 0 0 5px;
}
.tabs__item--active {
  opacity: 1;
}
.tabs__item--disabled {
  pointer-events: none;
}
.tabs__items {
  position: relative;
  overflow: hidden;
}
.tabs__content {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.tabs__content .card {
  border-radius: 0 0 2px 2px;
}
.tabs__slider {
  position: absolute;
  bottom: 0;
  height: 2px;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
@media only screen and (max-width: 599px) {
  .tabs--fixed > .tabs__bar .tabs__li {
    min-width: 72px;
  }
}
@media only screen and (max-width: 959px) {
  .tabs__bar {
    padding-left: 0;
  }
}
/** Themes */
.theme--light .picker--time .picker--time__clock,
.application .theme--light.picker--time .picker--time__clock {
  background: #e0e0e0;
}
.theme--light .picker--time .picker--time__clock > span.disabled,
.application .theme--light.picker--time .picker--time__clock > span.disabled {
  color: rgba(0,0,0,0.26);
}
.theme--dark .picker--time .picker--time__clock,
.application .theme--dark.picker--time .picker--time__clock {
  background: #616161;
}
.theme--dark .picker--time .picker--time__clock > span.disabled,
.application .theme--dark.picker--time .picker--time__clock > span.disabled {
  color: rgba(255,255,255,0.3);
}
.picker--time .card__row--actions {
  margin-top: -10px;
}
.picker--time.picker--landscape {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.picker--time.picker--landscape .picker__title {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.picker--time.picker--landscape .picker__title div:first-child {
  text-align: right;
}
.picker--time.picker--landscape .picker__title div:first-child span {
  height: 55px;
  font-size: 55px;
}
.picker--time.picker--landscape .picker__title div:last-child {
  margin: 16px 0 0;
  -ms-flex-item-align: initial;
      align-self: initial;
  text-align: center;
}
.picker--time.picker--landscape .picker--time__clock {
  height: 250px;
  width: 250px;
}
.picker--time.picker--landscape .picker--time__clock-hand {
  height: 97px;
}
/** Title */
.picker--time .picker__title {
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.picker--time .picker__title div:first-child {
  white-space: nowrap;
}
.picker--time .picker__title div:first-child span {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 70px;
  font-size: 70px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  opacity: 0.6;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.picker--time .picker__title div:first-child span.active {
  opacity: 1;
}
.picker--time .picker__title div:last-child {
  -ms-flex-item-align: end;
      align-self: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: 16px;
  margin: 8px 0 6px 8px;
}
.picker--time .picker__title div:last-child span {
  cursor: pointer;
  opacity: 0.6;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.picker--time .picker__title div:last-child span.active {
  opacity: 1;
}
.picker--time .picker__title div:only-child {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
/** Clock */
.picker--time__clock {
  height: 270px;
  width: 270px;
  border-radius: 100%;
  position: absolute;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  top: 50%;
  left: 50%;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.picker--time__clock-hand {
  height: 40%;
  width: 2px;
  bottom: 50%;
  left: calc(50% - 1px);
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  position: absolute;
  will-change: transform;
  z-index: 1;
}
.picker--time__clock-hand:before {
  background: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: inherit;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  content: '';
  position: absolute;
  top: -3%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.picker--time__clock-hand:after {
  content: '';
  position: absolute;
  height: 8px;
  width: 8px;
  top: 100%;
  left: 50%;
  border-radius: 100%;
  border-style: solid;
  border-color: inherit;
  background-color: inherit;
  -webkit-transform: translate(calc(-50%), -50%);
          transform: translate(calc(-50%), -50%);
}
.picker--time__clock > span {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 100%;
  cursor: default;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: calc(50% - 16px);
  height: 32px;
  position: absolute;
  text-align: center;
  top: calc(50% - 16px);
  width: 32px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.picker--time__clock > span > span {
  z-index: 1;
}
.picker--time__clock > span:before,
.picker--time__clock > span:after {
  content: '';
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 14px;
  width: 14px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.picker--time__clock > span:after,
.picker--time__clock > span:before {
  height: 40px;
  width: 40px;
}
.picker--time__clock > span.active {
  color: #fff;
  cursor: default;
  z-index: 2;
}
.picker--time__clock > span.disabled {
  pointer-events: none;
}
.picker--time .card__row--actions {
  border: none;
}
/* Theme */
.theme--light .toolbar,
.application .theme--light.toolbar {
  background-color: #f5f5f5;
  color: rgba(0,0,0,0.87);
}
.theme--dark .toolbar,
.application .theme--dark.toolbar {
  background-color: #212121;
  color: #fff;
}
.toolbar {
  position: relative;
  -webkit-transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  will-change: padding-left;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}
.toolbar .input-group--solo .input-group__details {
  display: none;
}
.toolbar .input-group--single-line:not(.input-group--solo) {
  padding: 0;
}
.toolbar .input-group--single-line:not(.input-group--solo) label {
  top: auto;
}
/** Children */
.toolbar__title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.02em;
  margin-left: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.toolbar__content,
.toolbar__extension {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.toolbar__content > .list,
.toolbar__extension > .list {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  margin: 0 !important;
  max-height: 100%;
}
.toolbar__content > .btn:last-child,
.toolbar__extension > .btn:last-child,
.toolbar__content > .menu:first-child,
.toolbar__extension > .menu:first-child {
  margin-right: 8px;
}
.toolbar__content > .btn:first-child,
.toolbar__extension > .btn:first-child,
.toolbar__content > .menu:first-child,
.toolbar__extension > .menu:first-child {
  margin-left: 8px;
}
.toolbar__content > *:not(.btn):not(.menu):first-child,
.toolbar__extension > *:not(.btn):not(.menu):first-child {
  margin-left: 16px;
}
.toolbar__content > *:not(.btn):not(.menu):last-child,
.toolbar__extension > *:not(.btn):not(.menu):last-child {
  margin-right: 16px;
}
.toolbar__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  max-width: 100%;
  padding: 0;
}
.toolbar__items .menu {
  height: 100%;
}
.toolbar__items > .menu .menu__activator {
  height: 100%;
}
.toolbar__items > .menu .menu__activator .btn {
  height: 100%;
  margin: 0;
}
.toolbar__items > .btn {
  height: 100%;
  margin: 0;
}
/** Types */
.toolbar--card {
  border-radius: 2px 2px 0 0;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
}
.toolbar--fixed {
  position: fixed;
  z-index: 2;
}
.toolbar--fixed,
.toolbar--absolute {
  top: 0;
  left: 0;
}
.toolbar--absolute {
  position: absolute;
  z-index: 2;
}
.toolbar--floating {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 16px;
  width: auto;
}
.toolbar--clipped {
  z-index: 3;
}
.toolbar__extension .toolbar__title {
  margin-left: 72px !important;
}
.toolbar__extension .tabs__bar {
  -ms-flex-item-align: end;
      align-self: flex-end;
  margin: 0;
}
@media only screen and (max-width: 599px) {
  .toolbar .toolbar__content > .btn:last-child,
  .toolbar .toolbar__extension > .btn:last-child {
    margin-right: 17px;
  }
  .toolbar .toolbar__content > .btn:first-child,
  .toolbar .toolbar__extension > .btn:first-child {
    margin-left: 17px;
  }
  .toolbar .toolbar__content > *:not(.btn):not(.menu):first-child,
  .toolbar .toolbar__extension > *:not(.btn):not(.menu):first-child {
    margin-left: 24px;
  }
  .toolbar .toolbar__content > *:not(.btn):not(.menu):last-child,
  .toolbar .toolbar__extension > *:not(.btn):not(.menu):last-child {
    margin-right: 24px;
  }
}
.tooltip {
  position: relative;
}
.tooltip__content {
  background: #616161;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  padding: 5px 8px;
  position: absolute;
  text-transform: initial;
  -webkit-transition: 0.15s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.15s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: auto;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.tooltip__content[class*="-active"] {
  pointer-events: none;
}
@media only screen and (max-width: 959px) {
  .tooltip .tooltip__content {
    padding: 10px 16px;
  }
}
/*# sourceMappingURL=vuetify.css.map*/body {
  background: #F4F4F4; }

img {
  max-width: 100%; }

@font-face {
    font-family: "Pass Dots";
    src: url(/static/fonts/PasswordDots.9ee8f4e.ttf) format("truetype");
}

#app {
  background: none;
  margin: 0 auto;
  max-width: 500px;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#codeInput {
    font-family: 'Pass Dots';
}

.card .description {
    position: absolute;
    letter-spacing: 0;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 13px;
    padding: 0 10px;
    color: #7b7b7b;
    background: #f3f3f3;
    border-radius: 99px;
    top: 0;
    left: 0;
}

.application--wrap {
  padding: 0 15px; }

nav.main {
  background: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-bottom: 30px; }

nav.main button.nav-back {
    color: #79CE86;
    font-size: 16px;
    margin: 0 !important; }

nav.main button.nav-back .icon {
      position: relative;
      top: -1px; }

nav.main button.nav-back:active {
      color: #43b755; }

nav.main button.nav-back:active, nav.main button.nav-back:focus {
      outline: none; }

.list {
  padding: 0; }

.card {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 7px; }

.input-group__input input {
  background: #fff;
  border-radius: 7px;
  border: none;
  padding: 15px;
  height: auto; }

.input-group__details {
  display: none; }

.input-group__details:before {
    display: none; }

.remove-profile {
  float: right;
  color: grey;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  font-size: 25px;
  margin: 1px; }

.remove-profile:hover {
    background: #eee; }

.subheader {
  font-size: 18px; }

.header {
  text-align: center;
  margin: 0 -15px;
  background: #1b181c;
  margin-bottom: 30px; }

.header img {
    width: 100%;
    max-width: 500px;
    margin-bottom: -6px; }

.btn {
  padding: 13px 13px;
  font-size: 18px;
  height: auto;
  margin: 0;
  border-radius: 7px;
  text-transform: none;
  -webkit-box-shadow: none !important;
          box-shadow: none !important; }

.application .theme--light.icon, .theme--light .icon {
  color: inherit; }

.expansion-panel.expansion-panel--popout .header__icon {
  display: none; }

.expansion-panel.expansion-panel--popout .expansion-panel__container.expansion-panel__container--active {
  -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  overflow: hidden;
  border: none; }

.application .theme--light.expansion-panel .expansion-panel__container, .theme--light .expansion-panel .expansion-panel__container {
  border-top: 2px solid #F4F4F4 !important; }

.expansion-panel--inset .expansion-panel__container, .expansion-panel--popout .expansion-panel__container {
  max-width: 100%; }

.stepper {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important; }

.stepper .stepper__header {
    -webkit-box-shadow: none;
            box-shadow: none;
    font-size: 18px; }

.stepper .stepper__header .stepper__step {
      padding: 14px 24px; }

.stepper .stepper__header .stepper__step__step {
      display: none; }

.stepper .stepper__content {
    padding: 0; }

.stepper .stepper__step--complete {
    opacity: 0.38; }

.inventory .card {
  -webkit-box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.04);
          box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.04); }

.inventory .palletDisplay {
  padding: 10px 0;
  font-size: 18px;
  background: #fff;
  border-radius: 7px;
  width: 100%; }

.inventory .counter {
  padding: 0 5px;
  background: #f9f9f9; }

.inventory .counter .btn {
    min-width: auto;
    width: 100%;
    font-size: 20px;
    padding: 10px;
    background: none !important; }

.inventory .counter .btn .btn__content {
      padding: 0;
      width: auto; }

.reminder {
  background: #333 !important;
  color: #fff !important;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 14px; }

.reminder .reminder-item {
    background: #fff;
    color: #333 !important;
    border-radius: 7px;
    padding: 5px; }

.reminder .reminder-item img {
      max-width: 60px;
      margin-bottom: -5px; }

.reminder .reminder-item input {
      text-align: center;
      width: 100%;
      font-size: 18px; }

.summary li {
  list-style: none;
  margin: 0;
  text-align: left;
  overflow: hidden; }

.summary li input {
    width: 25px;
    float: left; }

.summary .reciept {
  font-size: 16px;
  height: auto;
  padding: 30px;
  background: #ffffff;
  border-top: dashed #f4f4f4 5px;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 20px; }

.summary .input-group__selections {
  background: #f7f6f4;
  border-radius: 7px; }

.summary .input-group__selections .input-group--select__autocomplete {
    padding: 15px !important;
    height: auto !important;
    background: #f7f6f4;
    border-radius: 7px; }

.summary .input-group__selections .input-group__selections__comma {
    padding: 15px; }

.signature {
  max-width: 100%;
  background: #fff url(/static/img/signing.c491576.png);
  background-size: auto;
  border-radius: 7px; }

.mute {
    opacity: 0.5;
}
body {
  background: #F4F4F4; }

img {
  max-width: 100%; }

.application--wrap {
  padding: 0 15px; }

nav.main {
  background: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-bottom: 30px; }

nav.main button.nav-back {
    color: #79CE86;
    font-size: 1.3rem;
    margin: 0 !important; }

nav.main button.nav-back .icon {
      position: relative;
      top: -1px; }

nav.main button.nav-back:active {
      color: #43b755; }

nav.main button.nav-back:active, nav.main button.nav-back:focus {
      outline: none; }

.list {
  padding: 0; }

.card {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 7px; }

.input-group__input input {
  background: #fff;
  border-radius: 7px;
  border: none;
  padding: 15px;
  height: auto; }

.input-group__details {
  display: none; }

.input-group__details:before {
    display: none; }

.remove-profile {
  float: right;
  color: grey;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  font-size: 25px;
  margin: 1px; }

.remove-profile:hover {
    background: #eee; }

.subheader {
  font-size: 18px; }

.header {
  text-align: center;
  margin: 0 -15px;
  background: #1b181c;
  margin-bottom: 30px; }

.header img {
    width: 100%;
    max-width: 500px;
    margin-bottom: -6px; }

.btn {
  padding: 13px 13px;
  font-size: 18px;
  height: auto;
  margin: 0;
  border-radius: 7px;
  text-transform: none;
  -webkit-box-shadow: none !important;
          box-shadow: none !important; }

.application .theme--light.icon, .theme--light .icon {
  color: inherit; }

.expansion-panel.expansion-panel--popout .header__icon {
  display: none; }

.expansion-panel.expansion-panel--popout .expansion-panel__container.expansion-panel__container--active {
  -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  overflow: hidden;
  border: none; }

.application .theme--light.expansion-panel .expansion-panel__container, .theme--light .expansion-panel .expansion-panel__container {
  border-top: 2px solid #F4F4F4 !important; }

.expansion-panel--inset .expansion-panel__container, .expansion-panel--popout .expansion-panel__container {
  max-width: 100%; }

.stepper {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important; }

.stepper .stepper__header {
    -webkit-box-shadow: none;
            box-shadow: none;
    font-size: 18px; }

.stepper .stepper__header .stepper__step {
      padding: 14px 24px; }

.stepper .stepper__header .stepper__step__step {
      display: none; }

.stepper .stepper__content {
    padding: 0; }

.stepper .stepper__step--complete {
    opacity: 0.38; }

.inventory .card {
  -webkit-box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.04);
          box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.04); }

.inventory .palletDisplay {
  padding: 10px 0;
  font-size: 18px;
  background: #fff;
  border-radius: 7px;
  width: 100%; }

.inventory .counter {
  padding: 0 5px;
  background: #f9f9f9; }

.inventory .counter .btn {
    min-width: auto;
    width: 100%;
    font-size: 20px;
    padding: 10px;
    background: none !important; }

.inventory .counter .btn .btn__content {
      padding: 0;
      width: auto; }

.reminder {
  background: #333 !important;
  color: #fff !important;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 14px; }

.reminder .reminder-item {
    background: #fff;
    color: #333 !important;
    border-radius: 7px;
    padding: 5px; }

.reminder .reminder-item img {
      max-width: 60px;
      margin-bottom: -5px; }

.reminder .reminder-item input {
      text-align: center;
      width: 100%;
      font-size: 18px; }

.summary li {
  list-style: none;
  margin: 0;
  text-align: left;
  overflow: hidden; }

.summary li input {
    width: 25px;
    float: left; }

.summary .reciept {
  font-size: 16px;
  height: auto;
  padding: 30px;
  background: #ffffff;
  border-top: dashed #f4f4f4 5px;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 20px; }

.summary .input-group__selections {
  background: #f7f6f4;
  border-radius: 7px; }

.summary .input-group__selections .input-group--select__autocomplete {
    padding: 15px !important;
    height: auto !important;
    background: #f7f6f4;
    border-radius: 7px; }

.summary .input-group__selections .input-group__selections__comma {
    padding: 15px; }

.signature {
  max-width: 100%;
  background: #fff url(/static/img/signing.c491576.png);
  background-size: auto;
  border-radius: 7px; }
